import "../../../src/tooltip.css";
import { dismisAlert, generateAlert } from "../../utils/alertUtils";
import { contentTranslator } from "../../utils/translatorUtils";
import FormAddAction from "../actionplan/FormAddAction";
import ContentTranslator from "../translator/ContentTranslator";
import SkillProfileGapHighlight from "./SkillProfileGapHighlight";
import LorProfilesEditComment from "./TableLorProfilesEditComment";
import TableHeader from "./TableProfileHeader";
import { faCheckCircle, faComment, faEdit, faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { FormGroup, Button, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table, UncontrolledTooltip } from "reactstrap";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const tableBodyStyle = {
    fontSize: 11,
    fontFamily: "IBMPlexSans",
};

const tdEditStyle = {
    padding: "2px 10px",
};

const lorOrders = [
    { lor: "Autonomy", order: 1 },
    { lor: "Influence", order: 2 },
    { lor: "Complexity", order: 3 },
    { lor: "Business Skills", order: 4 },
    { lor: "Knowledge", order: 5 },
    { lor: "Responsibility and Autonomy", order: 1 },
    { lor: "Complexity and Scope", order: 2 },
    { lor: "Skills", order: 3 },
];

class TableProfileResponsibility extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lorProfiles: this.props.lorProfiles,
            lors: this.props.lors,
            loading: this.props.loading,
            showEditSkill: this.props.showEditSkill,
            editLorProfile: false,
            editedLorProfile: null,

            editComment: false,
            allowEditComment: false,
            editedLor: null,
            editedLorDescription: null,
            editedLorLevel: null,
            alerts: [],
            openAddActionDialog: false,
            openAddEvidenceDialog: false,
        };

        this.openEditLorProfile = this.openEditLorProfile.bind(this);
        this.togleEditLorProfile = this.togleEditLorProfile.bind(this);
        this.handleRadioClick = this.handleRadioClick.bind(this);
        this.handleSaveEditProfileClick = this.handleSaveEditProfileClick.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {}

    openEditLorProfile(editedLor, editedLorProfile) {
        this.setState({ editLorProfile: true, editedLor, editedLorProfile });
    }

    togleEditLorProfile() {
        const { editLorProfile } = this.state;
        this.setState({ editLorProfile: !editLorProfile });
    }

    handleRadioChange = () => {};

    handleRadioClick(value) {
        let editedLorProfile = this.state.editedLorProfile;

        if (editedLorProfile) {
            editedLorProfile.value = value;
        } else {
            editedLorProfile = {
                value,
            };
        }

        this.setState({ editedLorProfile });
    }

    handleSaveEditProfileClick() {
        const { lors, lorProfiles, editedLor, editedLorProfile } = this.state;
        const level = lors.find((it) => it.name == editedLor.name).levels.find((level) => editedLorProfile && level.levelNumber == editedLorProfile.value);

        let lorProfile = null;
        if (lorProfiles && editedLorProfile) {
            lorProfile = lorProfiles.find((it) => (editedLorProfile.id && it.id == editedLorProfile.id) || (!it.id && it.lor === editedLor.name));

            if (lorProfile) {
                if ((lorProfile.editedNumericVal && lorProfile.editedNumericVal != editedLorProfile.value) || (!lorProfile.editedNumericVal && lorProfile.numericVal != editedLorProfile.value)) {
                    lorProfile.editedLevel = level.levelNumber;
                    lorProfile.editedNumericVal = editedLorProfile.value;
                    lorProfile.editedStringVal = level && `${level.levelNumber}- ${level.levelName}`;
                    lorProfile.editedLabelVal = level && level.description;
                    lorProfile.edited = true;

                    lorProfile.source = "lorProfiles";

                    this.props.handleEditProfileSkill(lorProfile);
                    this.setState({ lorProfiles, editLorProfile: false });
                } else {
                    this.setState({ editLorProfile: false });
                }
            }
        }

        if (!lorProfile && level) {
            lorProfile = {
                lor: editedLor.name,
                lorCode: editedLor.lorCode,
                level: level.levelNumber,
                numericVal: editedLorProfile.value,
                stringVal: level && `${level.levelNumber}- ${level.levelName}`,
                labelVal: level && level.description,
                editedLevel: level.levelNumber,
                editedNumericVal: editedLorProfile.value,
                editedStringVal: level && `${level.levelNumber}- ${level.levelName}`,
                editedLabelVal: level && level.description,
                edited: true,
                source: "lorProfiles",
            };

            this.props.handleEditProfileSkill(lorProfile);
            this.setState({ lorProfiles: [...lorProfiles, lorProfile], editLorProfile: false });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading !== this.props.loading) {
            this.setState({ loading: this.props.loading });
        }

        if (prevProps.lors !== this.props.lors) {
            this.setState({ lors: this.props.lors });
        }

        if (prevProps.lorProfiles !== this.props.lorProfiles) {
            this.setState({ lorProfiles: this.props.lorProfiles });
        }
    }

    openEditLorComment = (editedLor, editedLorLevel, editedLorDescription, lorProfile, allowEditComment) => {
        this.setState({ editComment: true, editedLor, editedLorDescription, editedLorLevel, editedLorProfile: lorProfile, allowEditComment });
    };

    togleEditLorComment = () => {
        const { editComment } = this.state;
        this.setState({ editComment: !editComment });
    };

    updateLorComment = () => {
        this.setState({ editComment: false });
    };

    tableBodyResponsibility = (category, categoryColor, lorColor, showProposedAction) => {
        const { lors, lorProfiles } = this.state;
        const { loadingGap, highlightGap, selectedJobMatchType, account } = this.props;
        const proposeLors = showProposedAction ? (account && account.updateProfileEvidenceProposalSkills ? account.updateProfileEvidenceProposalSkills.filter((it) => it.type == "Lor") : []) : [];

        if (lors) {
            lors.forEach((lor) => {
                const lorOrder = lorOrders.find((it) => it.lor == lor.name);
                if (lorOrder) {
                    lor.order = lorOrder.order;
                } else {
                    lor.order = 999;
                }
            });
        }

        const rows = lors
            .filter((it) => (it.category ? it.category : "Generic Attributes") == (category ? category : "Generic Attributes"))
            //.sort((a, b) => (`${a.sequence}${a.order}${a.name}` > `${b.sequence}${b.order}${b.name}` ? 1 : -1))
            .sort((a, b) => {
                if (a.sequence > b.sequence) {
                    return 1;
                }
                if (a.sequence < b.sequence) {
                    return -1;
                }
                if (a.order > b.order) {
                    return 1;
                }
                if (a.order < b.order) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            })
            .map((lor, lorindex) => {
                const row = lorProfiles.find((x) => x.lor === lor.name);

                let jobLorProfile = null;

                if (selectedJobMatchType.value != "None") {
                    jobLorProfile = this.props.jobLorProfiles ? this.props.jobLorProfiles.find((it) => it.lor == lor.name) : null;
                }

                const levels = [1, 2, 3, 4, 5, 6, 7];

                let openCommentEditor = false;
                let openCommentAllowEdit = false;

                if (row) {
                    const currLevel = row.editedLevel ? row.editedLevel : row.level;

                    openCommentEditor = false;
                    openCommentAllowEdit = false;

                    if (!this.props.isShowJobProfile && !this.props.showEditSkill) {
                        if (
                            (this.props.selectedprofileType.value == "Self Assessed" && !this.props.managedPeople) ||
                            (this.props.selectedprofileType.value == "Self Assessed" && this.props.managedPeople && row.comment) ||
                            ((this.props.managedPeople || row.comment) && this.props.selectedprofileType.value == "Endorsed")
                        ) {
                            openCommentEditor = true;
                        }

                        if ((this.props.selectedprofileType.value == "Self Assessed" && !this.props.managedPeople) || (this.props.managedPeople && this.props.selectedprofileType.value == "Endorsed")) {
                            openCommentAllowEdit = true;
                        }
                    }

                    let showRow = false;

                    if (showProposedAction) {
                        if (showProposedAction && proposeLors.filter((s) => s.type == "Lor" && s.code == lor.lorCode).length > 0) {
                            const proposedLor = proposeLors.filter((s) => s.type == "Lor" && s.code == lor.lorCode).sort((b, a) => a.level - b.level)[0];
                            const lorProfile = lorProfiles.find((x) => x.lor === lor.name) ? lorProfiles.filter((x) => x.lor === lor.name).sort((b, a) => a.level - b.level)[0] : null;

                            if (lorProfile && proposedLor.level > lorProfile.level) {
                                showRow = true;
                            }
                        }
                    } else {
                        showRow = true;
                    }

                    return (
                        showRow && (
                            <tr key={lorindex}>
                                <td id={"Item" + (row.id ? row.id : row.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, ""))} className={"pt-1 pb-1 td-item"} valign="middle" style={{ verticalAlign: "middle" }}>
                                    <ContentTranslator page="Lors" name={row.lor} contentText={row.lor} />
                                </td>
                                <UncontrolledTooltip target={"Item" + (row.id ? row.id : row.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, ""))} placement="bottom">
                                    {lor && <ContentTranslator page="Lors" name={`${row.lor} Description`} contentText={lor.description} />}
                                </UncontrolledTooltip>
                                <td className={"pt-1 pb-1 td-item"}></td>
                                {levels.map((level, levelindex) => {
                                    const lorLevel = lor.levels.find((x) => x.levelNumber == level);
                                    const colProposed = `#f2aa84`;
                                    let showProposed = false;
                                    let proposedLor = null;

                                    if (showProposedAction && proposeLors.filter((s) => s.type == "Lor" && s.code == lor.lorCode && s.level == level).length > 0) {
                                        proposedLor = proposeLors.filter((s) => s.type == "Lor" && s.code == lor.lorCode).sort((b, a) => a.level - b.level)[0];
                                        const lorProfile = lorProfiles.find((x) => x.lor === lor.name) ? lorProfiles.filter((x) => x.lor === lor.name).sort((b, a) => a.level - b.level)[0] : null;

                                        if (lorProfile) {
                                            if (proposedLor.level > lorProfile.level && proposedLor.level == level) {
                                                showProposed = true;
                                            }
                                        } else {
                                            if (proposedLor.level == level) {
                                                showProposed = true;
                                            }
                                        }
                                    }

                                    return (
                                        <React.Fragment key={levelindex}>
                                            <td
                                                id={`lv${level}${lorindex}${levelindex}`}
                                                className={"pt-1 pb-1 td-value td-fixwidth"}
                                                style={{
                                                    borderRight: "1px solid #FFFFFF",
                                                    cursor: currLevel === level && openCommentEditor ? "pointer" : "default",
                                                    backgroundColor: showProposed ? colProposed : currLevel === level ? categoryColor : lorColor,
                                                }}
                                                onClick={() =>
                                                    currLevel === level &&
                                                    openCommentEditor &&
                                                    this.openEditLorComment(
                                                        this.contentTranslator(this.props.contentTranslations, "Lors", row.lor, row.lor),
                                                        level,
                                                        this.contentTranslator(this.props.contentTranslations, "LorLevels", `${lor.name} ${lorLevel.levelName} Description`, lorLevel.description),
                                                        row,
                                                        openCommentAllowEdit
                                                    )
                                                }
                                            >
                                                {currLevel === level && row.comment && <FontAwesomeIcon icon={faComment} style={{ color: "#000000" }} />}
                                            </td>
                                            <UncontrolledTooltip target={`lv${level}${lorindex}${levelindex}`} placement="bottom">
                                                {lorLevel && <ContentTranslator page="LorLevels" name={`${lor.name} ${lorLevel.levelName} Description`} contentText={lorLevel.description} />}
                                            </UncontrolledTooltip>
                                            {highlightGap && (
                                                <React.Fragment>
                                                    {jobLorProfile && jobLorProfile.level == level ? (
                                                        <React.Fragment>
                                                            <SkillProfileGapHighlight
                                                                id={`lvGap${level}-` + (row.id ? row.id : row.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, ""))}
                                                                colUnmatched="#ecf0f5"
                                                                isShow={jobLorProfile && row ? true : false}
                                                                isFully={jobLorProfile && row && currLevel >= jobLorProfile.level ? true : false}
                                                                jobMatchType={selectedJobMatchType.value}
                                                                fixWidthClass="td-fixwidth"
                                                                reqDesValue="Required"
                                                                rightBorderColor="#69849c"
                                                            ></SkillProfileGapHighlight>
                                                            <UncontrolledTooltip target={`lvGap${level}-` + (row.id ? row.id : row.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, ""))} placement="bottom">
                                                                {lorLevel && <ContentTranslator page="LorLevels" name={`${lor.name} ${lorLevel.levelName} Description`} contentText={lorLevel.description} />}
                                                            </UncontrolledTooltip>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <td
                                                                id={`lvGap${level}-` + (row.id ? row.id : row.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, ""))}
                                                                className={"pt-1 pb-1 td-value td-fixwidth td-fixwidth " + "col-respon-unmached"}
                                                                style={{ borderRight: "2px solid #69849c" }}
                                                            ></td>
                                                            <UncontrolledTooltip target={`lvGap${level}-` + (row.id ? row.id : row.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, ""))} placement="bottom">
                                                                {lorLevel && <ContentTranslator page="LorLevels" name={`${lor.name} ${lorLevel.levelName} Description`} contentText={lorLevel.description} />}
                                                            </UncontrolledTooltip>
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                                {this.props.showEditSkill && (
                                    <td align="center" id={"lvEdit" + (row.id ? row.id : row.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, ""))} style={tdEditStyle}>
                                        <button className={`btn btn-light ${row.edited ? " btn-warning-edit" : " btnEdit-Style"}`} onClick={() => this.openEditLorProfile(lor, { id: row.id, value: currLevel })}>
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                    </td>
                                )}
                                {highlightGap ? (
                                    <td id={"lvGap-" + (row.id ? row.id : row.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, ""))} style={{ textAlign: "center", verticalAlign: "middle", padding: "2px 10px" }}>
                                        {loadingGap && <Spinner size="sm" animation="border" role="status"></Spinner>}
                                        {!loadingGap && jobLorProfile ? (
                                            currLevel >= jobLorProfile.level ? (
                                                <FontAwesomeIcon id={`iconGap-${row.id ? row.id : row.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, "")}`} className="text-success" style={{ fontSize: "16px" }} icon={faCheckCircle} />
                                            ) : (
                                                <FontAwesomeIcon id={`iconGap-${row.id ? row.id : row.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, "")}`} style={{ fontSize: "16px", color: "#df5640" }} icon={faTimesCircle} />
                                            )
                                        ) : (
                                            <FontAwesomeIcon id={`iconGap-${row.id ? row.id : row.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, "")}`} style={{ display: "none" }} icon={faCheckCircle} />
                                        )}
                                        <UncontrolledTooltip autohide={false} target={`iconGap-${row.id ? row.id : row.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, "")}`} placement="bottom">
                                            {!loadingGap && jobLorProfile ? (
                                                currLevel >= jobLorProfile.level ? (
                                                    <ContentTranslator page="SfiaProfile" name="SfiaProfileGapStatusMatchHover" contentText="You DO have the Required skill as Competent at the Level(s) needed" />
                                                ) : (
                                                    <React.Fragment>
                                                        <ContentTranslator page="SfiaProfile" name="SfiaProfileGapStatusGapHover" contentText="You do NOT have a Required skill as Competent at the Level(s) needed" />
                                                        <br />
                                                        <br />
                                                        <button className={`btn btn-primary`} onClick={() => this.handleAddAction(lor)}>
                                                            <FontAwesomeIcon icon={faPlusCircle} /> Add Action
                                                        </button>
                                                    </React.Fragment>
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </UncontrolledTooltip>
                                    </td>
                                ) : (
                                    <td id={"lvGap-" + (row.id ? row.id : row.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, ""))} style={{ display: "none" }}>
                                        <FontAwesomeIcon id={`iconGap-${row.id ? row.id : row.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, "")}`} icon={faCheckCircle} />
                                        <UncontrolledTooltip target={`iconGap-${row.id ? row.id : row.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, "")}`} placement="bottom">
                                            <ContentTranslator page="SfiaProfile" name="SfiaProfileGapStatusGapHover" contentText="You do NOT have a Required skill as Competent at the Level(s) needed" />
                                        </UncontrolledTooltip>
                                    </td>
                                )}
                            </tr>
                        )
                    );
                } else {
                    let showRow = false;

                    if (showProposedAction) {
                        if (showProposedAction && proposeLors.filter((s) => s.type == "Lor" && s.code == lor.lorCode).length > 0) {
                            showRow = true;
                        }
                    } else {
                        showRow = true;
                    }

                    return (
                        showRow && (
                            <tr key={lorindex}>
                                <td id={"Item" + lor.name.replace(/ /g, "").replace(/,/g, "").replace(/&/g, "").replace(/\//g, "")} className={"pt-1 pb-1 td-item"} valign="middle" style={{ verticalAlign: "middle" }}>
                                    {lor.name}
                                </td>
                                <td className={"pt-1 pb-1 td-item"}></td>
                                {levels.map((level, levelindex) => {
                                    const lorLevel = lor.levels.find((x) => x.levelNumber == level);
                                    const colProposed = `#f2aa84`;
                                    let showProposed = false;
                                    let proposedLor = null;

                                    if (showProposedAction && proposeLors.filter((s) => s.type == "Lor" && s.code == lor.lorCode && s.level == level).length > 0) {
                                        proposedLor = proposeLors.filter((s) => s.type == "Lor" && s.code == lor.lorCode).sort((b, a) => a.level - b.level)[0];

                                        if (proposedLor.level == level) {
                                            showProposed = true;
                                        }
                                    }

                                    return (
                                        <React.Fragment key={levelindex}>
                                            <td
                                                id={`lv${level}${lorindex}${levelindex}`}
                                                className={"pt-1 pb-1 td-value td-fixwidth"}
                                                style={{
                                                    borderRight: "1px solid #FFFFFF",
                                                    backgroundColor: showProposed ? colProposed : lorColor,
                                                }}
                                            ></td>
                                            <UncontrolledTooltip target={`lv${level}${lorindex}${levelindex}`} placement="bottom">
                                                {lorLevel && <ContentTranslator page="LorLevels" name={`${lor.name} ${lorLevel.levelName} Description`} contentText={lorLevel.description} />}
                                            </UncontrolledTooltip>
                                            {highlightGap && (
                                                <React.Fragment>
                                                    {jobLorProfile && jobLorProfile.level == level ? (
                                                        <React.Fragment>
                                                            <SkillProfileGapHighlight
                                                                id={`lvGap${level}-` + lor.name.replace(/ /g, "").replace(/,/g, "").replace(/&/g, "").replace(/\//g, "")}
                                                                colUnmatched="#ecf0f5"
                                                                isShow={jobLorProfile ? true : false}
                                                                isFully={false}
                                                                jobMatchType={selectedJobMatchType.value}
                                                                fixWidthClass="td-fixwidth"
                                                                reqDesValue="Required"
                                                                rightBorderColor="#69849c"
                                                            ></SkillProfileGapHighlight>
                                                            <UncontrolledTooltip target={`lvGap${level}-` + lor.name.replace(/ /g, "").replace(/,/g, "").replace(/&/g, "").replace(/\//g, "")} placement="bottom">
                                                                {lorLevel && <ContentTranslator page="LorLevels" name={`${lor.name} ${lorLevel.levelName} Description`} contentText={lorLevel.description} />}
                                                            </UncontrolledTooltip>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <td id={`lvGap${level}-` + lor.name.replace(/ /g, "").replace(/,/g, "").replace(/&/g, "").replace(/\//g, "")} className={"pt-1 pb-1 td-value td-fixwidth"} style={{ borderRight: "2px solid #69849c", backgroundColor: lorColor }}></td>
                                                            <UncontrolledTooltip target={`lvGap${level}-` + lor.name.replace(/ /g, "").replace(/,/g, "").replace(/&/g, "").replace(/\//g, "")} placement="bottom">
                                                                {lorLevel && <ContentTranslator page="LorLevels" name={`${lor.name} ${lorLevel.levelName} Description`} contentText={lorLevel.description} />}
                                                            </UncontrolledTooltip>
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                                {this.props.showEditSkill && (
                                    <td align="center" id={"lvEdit" + lor.name} style={tdEditStyle}>
                                        <button className="btn btn-light btnEdit-Style" onClick={() => this.openEditLorProfile(lor, null)}>
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                    </td>
                                )}
                                {highlightGap && jobLorProfile ? (
                                    <td id={"lvGap-" + lorindex} style={{ textAlign: "center", verticalAlign: "middle", padding: "2px 10px" }}>
                                        <FontAwesomeIcon id={`iconGap-${lorindex}`} style={{ fontSize: "16px", color: "#df5640" }} icon={faTimesCircle} />
                                        <UncontrolledTooltip autohide={false} target={`iconGap-${lorindex}`} placement="bottom">
                                            <ContentTranslator page="SfiaProfile" name="SfiaProfileGapStatusGapHover" contentText="You do NOT have a Required skill as Competent at the Level(s) needed" />
                                            <br />
                                            <br />
                                            <button className={`btn btn-primary`} onClick={() => this.handleAddAction(lor)}>
                                                <FontAwesomeIcon icon={faPlusCircle} /> Add Action
                                            </button>
                                        </UncontrolledTooltip>
                                    </td>
                                ) : (
                                    <td id={"lvGap-" + lorindex} style={{ textAlign: "center", verticalAlign: "middle", padding: "2px 10px", display: "none" }}>
                                        <FontAwesomeIcon id={`iconGap-${lorindex}`} style={{ fontSize: "16px", color: "#df5640" }} icon={faTimesCircle} />
                                        <UncontrolledTooltip target={`iconGap-${lorindex}`} placement="bottom">
                                            <ContentTranslator page="SfiaProfile" name="SfiaProfileGapStatusGapHover" contentText="You do NOT have a Required skill as Competent at the Level(s) needed" />
                                        </UncontrolledTooltip>
                                    </td>
                                )}
                            </tr>
                        )
                    );
                }
            });

        return <tbody style={tableBodyStyle}>{rows}</tbody>;
    };

    handleAddAction = (lor) => {
        this.setState({ openAddActionDialog: true, actionedLor: lor });
    };

    handleCancelAddAction = () => {
        this.setState({ openAddActionDialog: false, actionedLor: null });
    };

    handleActionPlanAdded = (addedAction) => {
        this.setState({ openAddActionDialog: false, actionedLor: null });
        this.props.handleActionPlanAdded(addedAction);
    };

    renderModalAddAction = () => {
        const { selectedJobMatchType, actions, actionSkills, jobLorProfiles, jobSkillProfiles, lorProfiles, skillProfiles } = this.props;
        const { actionedLor, openAddActionDialog } = this.state;

        let selectedDefaultSkills;

        if (actionedLor && selectedJobMatchType && selectedJobMatchType.value != "None") {
            if (jobSkillProfiles) {
                jobSkillProfiles.forEach((jobSkillProfile) => {
                    const skillProfile = skillProfiles && skillProfiles.find((x) => x.skillCode == jobSkillProfile.skillCode && x.level == jobSkillProfile.level && (x.numericVal == 1 || x.stringVal == "M"));
                    const plan = actions ? actions.find((it) => it.skills.some((skill) => skill.code == jobSkillProfile.skillCode && skill.level == jobSkillProfile.level)) : null;

                    if (skillProfile || plan) {
                        jobSkillProfile.actioned = true;
                    } else {
                        jobSkillProfile.actioned = false;
                    }
                });
            }

            if (jobLorProfiles) {
                const gapJobLorProfiles = jobLorProfiles.filter((it) => it.lorCode == actionedLor.lorCode);

                if (gapJobLorProfiles.length > 0) {
                    selectedDefaultSkills = [
                        ...gapJobLorProfiles.map((skill) => {
                            return {
                                skillName: skill.lor,
                                skillCode: skill.lorCode,
                                skillLevel: skill.level,
                                skillType: "Lor",
                            };
                        }),
                    ];
                }

                jobLorProfiles.forEach((jobLorProfile) => {
                    const lorProfile = lorProfiles.find((it) => it.lorCode == jobLorProfile.lorCode && it.level >= jobLorProfile.level);
                    const plan = actions ? actions.find((it) => it.skills.some((skill) => skill.code == jobLorProfile.lorCode && skill.level >= jobLorProfile.level)) : null;
                    const gapJobLorProfile = gapJobLorProfiles.find((it) => it.lorCode == jobLorProfile.lorCode);

                    if (!gapJobLorProfile && (lorProfile || plan)) {
                        jobLorProfile.actioned = true;
                    } else {
                        jobLorProfile.actioned = false;
                    }
                });
            }
        }

        let optionsJob = [];

        const jobMatches = this.props.jobMatches;
        const currentJob = jobMatches ? jobMatches.find((it) => it.jobType === "Current") : null;
        const futureJob = jobMatches ? jobMatches.find((it) => it.jobType === "Future") : null;

        if (currentJob) {
            optionsJob.push({
                value: currentJob.jobType + " - " + currentJob.jobTitle,
                label: this.contentTranslator(this.props.contentTranslations, "SfiaAll", currentJob.jobType, currentJob.jobType) + " - " + currentJob.jobTitle,
                type: currentJob.jobType,
                id: currentJob.jobId,
            });
        }

        if (futureJob) {
            optionsJob.push({
                value: futureJob.jobType + " - " + futureJob.jobTitle,
                label: this.contentTranslator(this.props.contentTranslations, "SfiaAll", futureJob.jobType, futureJob.jobType) + " - " + futureJob.jobTitle,
                type: futureJob.jobType,
                id: futureJob.jobId,
            });
        }

        return (
            <Modal isOpen={openAddActionDialog} size="lg">
                <ModalBody>
                    <div style={{ fontSize: "11px", fontFamily: "IBMPlexSans" }}>
                        <FormAddAction
                            showHiddenSkills={this.state.showHiddenSkills}
                            optionsActionTypes={this.props.actionTypes}
                            optionsActionSkills={actionSkills}
                            categories={this.props.categories}
                            jobLorProfiles={jobLorProfiles}
                            jobSkillProfiles={jobSkillProfiles}
                            lorProfiles={lorProfiles}
                            skillProfiles={skillProfiles}
                            handleClose={this.handleCancelAddAction}
                            handleActionPlanAdded={this.handleActionPlanAdded}
                            providers={this.props.selectedProviders}
                            optionsJob={optionsJob}
                            selectedDefaultSkills={selectedDefaultSkills}
                            generateAlert={this.generateAlert}
                        ></FormAddAction>
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    renderModalEditProfile() {
        const { editedLor, editedLorProfile } = this.state;

        let lorProfileElement = null;

        if (editedLorProfile) {
            lorProfileElement = {
                id: editedLorProfile.id,
                value: editedLorProfile.value,
            };
        }
        return (
            <Modal isOpen={this.state.editLorProfile} toggle={this.togleEditLorProfile}>
                <ModalHeader toggle={this.togleEditLorProfile}>
                    <ContentTranslator page="SfiaProfile" name="EditLorTitle" contentText="Edit Responsibility" />
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "500px" }}>
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr valign="top">
                                        <td colSpan="3" style={{ width: "100%", backgroundColor: editedLor.categoryColor ? editedLor.categoryColor : "#69849c" }} className="td-edit-skill-header">
                                            <ContentTranslator page="Lors" name={editedLor.name} contentText={editedLor.name} />
                                        </td>
                                    </tr>
                                    <tr valign="top">
                                        <td colSpan="3" style={{ width: "100%", backgroundColor: editedLor.categoryColor ? editedLor.categoryColor : "#69849c" }} className="td-edit-skill-header">
                                            <ContentTranslator page="Lors" name={`${editedLor.name} Description`} contentText={editedLor.description} />
                                        </td>
                                    </tr>
                                    {editedLor.levels &&
                                        editedLor.levels.map((level, index) => {
                                            return (
                                                <tr key={index} valign="top">
                                                    <td style={{ width: "5%" }} className="td-edit-profile-skill">
                                                        {level.levelNumber}
                                                    </td>
                                                    <td style={{ width: "87%" }} className="td-edit-profile-skill">
                                                        <ContentTranslator page="LorLevels" name={`${editedLor.name} ${level.levelName} Description`} contentText={level.description} />
                                                    </td>
                                                    <td style={{ width: "8%" }} className="td-edit-profile-skill">
                                                        <FormGroup check>
                                                            <Input
                                                                type="radio"
                                                                value={level.levelNumber}
                                                                name={`rad${editedLor.id}`}
                                                                checked={lorProfileElement ? lorProfileElement.value === level.levelNumber : false}
                                                                onChange={() => this.handleRadioChange()}
                                                                onClick={() => this.handleRadioClick(level.levelNumber)}
                                                            />
                                                        </FormGroup>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.togleEditLorProfile}>
                        <ContentTranslator page="SfiaProfile" name="EditLorButtonClose" contentText="Close" />
                    </Button>{" "}
                    <Button color="primary" onClick={this.handleSaveEditProfileClick}>
                        <ContentTranslator page="SfiaProfile" name="EditLorButtonSave" contentText="Save changes" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        const { editedLor, lors, lorProfiles } = this.state;
        const { showProposedAction, account } = this.props;

        const lorCategories = [];

        if (lors.find((it) => !it.category)) {
            lorCategories.push({
                category: "Generic Attributes",
                categoryColor: "#69849c",
                lorColor: "#ecf0f5",
            });
        }

        lors.filter((it) => it.category).forEach((lor) => {
            if (!lorCategories.find((it) => it.category == lor.category)) {
                lorCategories.push({
                    category: lor.category,
                    categoryColor: lor.categoryColor,
                    lorColor: lor.lorColor,
                });
            }
        });

        return (
            <div>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {editedLor && this.renderModalEditProfile()}
                {this.state.loading ? (
                    <div style={{ textAlign: "center" }}>
                        <Spinner animation="border" role="status" style={{ marginLeft: "20px" }}></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        {this.renderModalAddAction()}
                        <LorProfilesEditComment
                            editComment={this.state.editComment}
                            editedLor={this.state.editedLor}
                            editedLorDescription={this.state.editedLorDescription}
                            editedLorLevel={this.state.editedLorLevel}
                            editedLorProfile={this.state.editedLorProfile}
                            togleEditLorComment={this.togleEditLorComment}
                            updateLorComment={this.updateLorComment}
                            generateAlert={this.generateAlert}
                            isEndorser={this.props.managedPeople && this.props.selectedprofileType && this.props.selectedprofileType.value == "Endorsed" ? true : false}
                            allowEditComment={this.state.allowEditComment}
                        ></LorProfilesEditComment>
                        {lorCategories.map((lorCategory, index) => {
                            let showCategory = false;

                            if (showProposedAction) {
                                const proposeLors = account && account.updateProfileEvidenceProposalSkills ? account.updateProfileEvidenceProposalSkills.filter((it) => it.category == (lorCategory.category ? lorCategory.category : "Generic Attributes")) : [];
                                const matchs = lorProfiles ? lorProfiles.filter((a) => proposeLors.some((b) => b.name == a.lor && b.level > a.level)) : [];
                                if (matchs.length > 0) {
                                    showCategory = true;
                                }
                            } else {
                                showCategory = true;
                            }

                            return (
                                <React.Fragment key={index}>
                                    {showCategory && (
                                        <Table>
                                            <TableHeader
                                                title={lorCategory.category == "Generic Attributes" ? this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "LorProfileTitle", "Generic Attributes") : lorCategory.category}
                                                backgroundColor={lorCategory.categoryColor}
                                                showTitle={true}
                                                showEdit={this.props.showEditSkill}
                                                highlightGap={this.props.highlightGap}
                                            />
                                            {this.tableBodyResponsibility(lorCategory.category, lorCategory.categoryColor, lorCategory.lorColor, this.props.showProposedAction)}
                                        </Table>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contentTranslations: state.content.contentTranslations,
        managedPeople: state.auth.managedPeople,
        account: state.person.account,
        actionTypes: state.actionPlan.actionTypes,
        actionSkills: state.actionPlan.actionSkills,
        actions: state.actionPlan.actions,
        selectedProviders: state.company.selectedProviders,
        jobMatches: state.job.jobMatches,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TableProfileResponsibility);
