import * as authActions from "../../../store/actions/authAction";
import * as jobActions from "../../../store/actions/jobAction";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { filterArray } from "../../../utils/filterUtils";
import { generateRandomString } from "../../../utils/stringUtils";
import { contentTranslator } from "../../../utils/translatorUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import ColumnDisplaySetting from "../ColumnDisplaySetting";
import JobBulkUpload from "./JobBulkUpload";
import JobCopyDialog from "./JobCopyDialog";
import JobDetail from "./JobDetail";
import ManageJobSample from "./ManageJobSample";
import { HubConnectionBuilder } from "@aspnet/signalr";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCog, faCopy, faDownload, faFileExcel, faInfoCircle, faListUl, faMinusCircle, faPenToSquare, faPlus, faSquareCheck, faSquareMinus, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
const messageHubHostname = process.env.NODE_ENV === "development" ? process.env.REACT_APP_MESSAGE_HUB_ENDPOINT : "/messagehub";

const rowStyle = {
    fontSize: "12px",
};

class ManageJob extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            jobs: [],
            sampleJobs: [],
            selectedJob: null,
            selectedJobUpdated: false,
            jobImportSessionId: this.props.jobImportSessionId,
            showDialogImportTs: null,
            jobImportQueue: [],
            showDeleteJobDialog: false,
            showJobCopyDialogTs: false,
            showSampleJobsDialogTs: false,
            printReportsMode: false,
            loadingPrint: false,
            selectedJobsPrint: [],
            showColumnSettings: false,
        };

        this.filterArray = filterArray.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
        this.generateRandomString = generateRandomString.bind(this);
    }

    componentDidMount() {
        const routeGoBack = localStorage.getItem("routeGoBack");

        let repopulate = true;
        if (routeGoBack == "TRUE") {
            repopulate = false;
        }

        this.props.onAuth.authCheckState().then(() => {
            this.connectJobSignalR();

            if (!this.state.jobImportSessionId) {
                const jobImportSessionId = this.generateRandomString(30);
                Promise.resolve(this.props.onJob.setJobImportSessionId(jobImportSessionId)).then(() => {
                    this.setState({ jobImportSessionId });
                    this.connectJobImportSignalR(jobImportSessionId);
                });
            } else {
                this.connectJobImportSignalR(this.state.jobImportSessionId);
            }

            if (repopulate || !this.props.jobs || (this.props.jobs && this.props.jobs.length == 0)) {
                Promise.all([this.props.onJob.getJobs(), this.props.onJob.getSampleJobs(), this.props.onJob.getJobImportQueue()]).then(() => {
                    this.setState({
                        jobs: this.reformJobs(this.props.jobs),
                        sampleJobs: this.props.sampleJobs,
                        jobImportQueue: this.props.jobImportQueue && this.props.jobImportQueue.sort((b, a) => new Date(a.importedOn) - new Date(b.importedOn)),
                    });

                    this.setState({ loading: this.props.loading || this.props.jobsLoading });
                });
            } else {
                this.setState({
                    jobs: this.reformJobs(this.props.jobs),
                    sampleJobs: this.props.sampleJobs,
                    jobImportQueue: this.props.jobImportQueue && this.props.jobImportQueue.sort((b, a) => new Date(a.importedOn) - new Date(b.importedOn)),
                });

                this.setState({ loading: false });
            }
        });
    }

    searchProperties = () => {
        const pageName = "Manage Requirements";
        return this.props.columnDisplaySettings ? this.props.columnDisplaySettings.filter((it) => it.pageName == pageName && it.visible == true && it.searchable == true).map((it) => it.columnName) : [];
    };

    componentWillUnmount() {
        localStorage.setItem("routeGoBack", "FALSE");
        this.disconnectJobSignalR();
        this.disconnectJobImportSignalR();
    }

    connectJobSignalR = () => {
        const personId = this.props.personId;
        this.connectionJob = new HubConnectionBuilder().withUrl(messageHubHostname).build();

        this.connectionJob.on(`UpdateRequirementNotification${personId}`, (response) => {
            if (response.action == "JobUpdate") {
                if (response.status == "Success") {
                    let updatedJob = response.data;
                    this.props.onJob.updateJobByNotification(updatedJob);
                    this.setState({ selectedJobUpdated: true });
                }
            }
        });

        this.connectionJob.start().catch((err) => console.error(err.toString()));
    };

    disconnectJobSignalR = () => {
        if (this.connectionJob) this.connectionJob.stop();
    };

    connectJobImportSignalR = (jobImportSessionId) => {
        this.connectionJobImport = new HubConnectionBuilder().withUrl(messageHubHostname).build();

        this.connectionJobImport.on(`ImportJobsDataNotification${jobImportSessionId}`, (response) => {
            if (response.action == "ImportJobsData") {
                if (response.status == "Success") {
                    let importJobResponse = response.data;
                    //console.log("importJobResponse", importJobResponse);
                    this.props.onJob.updateImportJobStatus(importJobResponse.item1, importJobResponse.item2);
                }
            }
        });

        this.connectionJobImport.start().catch((err) => console.error(err.toString()));
    };

    disconnectJobImportSignalR = () => {
        if (this.connectionJobImport) this.connectionJobImport.stop();
    };

    handleRefresh = () => {
        this.props.onJob.getJobs().then(() => {
            this.setState({
                jobs: this.reformJobs(this.props.jobs),
            });
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.jobs !== this.props.jobs) {
            this.setState({
                jobs: this.props.jobs ? this.reformJobs(this.props.jobs.sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1))) : [],
            });
        }

        if (prevProps.jobImportQueue !== this.props.jobImportQueue) {
            this.setState({
                jobImportQueue: this.props.jobImportQueue ? this.props.jobImportQueue.sort((b, a) => new Date(a.importedOn) - new Date(b.importedOn)) : [],
            });
        }
    }

    reformJobs = (jobs) => {
        return jobs.map((it) => {
            return {
                id: it.id,
                companyId: it.companyId,
                cartridgeId: it.cartridgeId,
                name: it.name,
                description: it.description,
                reference: it.reference,
                customerReference: it.customerReference,
                sequence: it.sequence,
                isRole: it.isRole,
                createdOn: it.createdOn,
                definedById: it.definedById,
                definedBy: it.definedBy,
                reportingTo: it.reportingTo,
                seniority: it.seniority,
                employmentStatus: it.employmentStatus,
                department: it.department,
                location: it.location,
                purpose: it.purpose,
                keyAccountibilities: it.keyAccountibilities,
                keyProcesses: it.keyProcesses,
                educationRequirements: it.educationRequirements,
                qualificationRequirements: it.qualificationRequirements,
                otherRequirements: it.otherRequirements,
                comment: it.comment,
                isHidden: it.isHidden,
                isCandidateJob: it.isCandidateJob,
                whoCanSee: it.isHidden && !it.isCandidateJob ? "Nobody" : !it.isHidden && !it.isCandidateJob ? "Employee Only" : it.isHidden && it.isCandidateJob ? "Candidate Only" : "Candidates and Employees",
                inActive: it.inActive,
                active: it.inActive ? "Inactive" : "Active",
                isSample: it.isSample,
                ownerId: it.ownerId,
                owner: it.owner,
                updatedOn: it.updatedOn,
                languageId: it.languageId,
                language: it.language,
                fte: it.fte,
                jobFamilyId: it.jobFamilyId,
                jobFamily: it.jobFamily,
                jobFamilySequence: it.jobFamilySequence,
                jobMatches: it.jobMatches,
                userCountCurrent: it.jobMatches ? it.jobMatches.filter((jm) => jm.jobType == "Current" && jm.personInactive != true).length : 0,
                userCountFuture: it.jobMatches ? it.jobMatches.filter((jm) => jm.jobType == "Future" && jm.personInactive != true).length : 0,
                showAsRoleBasedSurveyInSelfRegistration: it.showAsRoleBasedSurveyInSelfRegistration != null ? it.showAsRoleBasedSurveyInSelfRegistration : false,
                strShowAsRoleBasedSurveyInSelfRegistration: it.showAsRoleBasedSurveyInSelfRegistration != null ? (it.showAsRoleBasedSurveyInSelfRegistration ? "Yes" : "No") : "No",
            };
        });
    };

    openJobDetail = (job) => {
        this.setState({ showDialogJobDetailTs: new Date(), selectedJob: job, selectedJobUpdated: false });
    };

    handleDeleteJob = (job) => {
        this.setState({ deletedJob: job, showDeleteJobDialog: true });
    };

    togleDeleteJobModal = () => {
        const showDeleteJobDialog = this.state.showDeleteJobDialog;
        this.setState({ showDeleteJobDialog: !showDeleteJobDialog });
    };

    handleConfirmDeleteJob = () => {
        const job = this.state.deletedJob;
        if (job) {
            this.setState({ isUpdating: true });

            this.props.onJob.deleteJob(job.id).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.jobsError) {
                        this.generateAlert("success", "Delete requirement success.");
                        this.handleRefresh();
                        this.setState({ showDeleteJobDialog: false });
                    } else {
                        this.generateAlert("danger", this.props.jobsError.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    renderDeleteJobConfirmation = () => {
        return (
            <Modal isOpen={this.state.showDeleteJobDialog} toggle={this.togleDeleteJobModal}>
                <ModalHeader toggle={this.togleDeleteJobModal}>Delete Requirement Confirmation</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>You are about to PERMANENTLY delete this requirement. Are you sure?</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmDeleteJob();
                        }}
                    >
                        OK
                    </Button>
                    <Button color="secondary" onClick={this.togleDeleteJobModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleCopyJob = (job) => {
        this.setState({ selectedJob: job, copyJobSuccess: false, showJobCopyDialogTs: new Date() });
    };

    handleSaveCopyJob = (request) => {
        this.setState({ isUpdating: true });

        this.props.onJob.copyJob(request).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.jobsError) {
                    this.generateAlert("success", "Copy requirement success.");
                    this.setState({ copyJobSuccess: true });
                } else {
                    this.generateAlert("danger", this.props.jobsError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleAddNew = () => {
        this.props.history.push("requirements/create");
    };

    pad2 = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    handleExportJobs = () => {
        this.props.onJob.exportJobsDataExcel().then(() => {
            if (!this.props.loadingExport && !this.props.jobsError) {
                if (this.props.exportData) {
                    var today = new Date();
                    var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                    FileDownload(this.props.exportData, `ExportRequirements_${strToday}.xlsx`);
                }
            }
        });
    };

    handleImportJobs = () => {
        this.setState({ showDialogImportTs: new Date() });
    };

    handleAddedJobImports = (jobImports) => {
        const jobImportIds = jobImports.map((it) => {
            return it.id;
        });

        Promise.resolve(this.props.onJob.processJobImportQueue(jobImportIds)).then(() => {});
    };

    handleRefreshJobQueueu = () => {
        this.props.onJob.getJobImportQueue().then(() => {
            this.setState({
                jobImportQueue: this.props.jobImportQueue && this.props.jobImportQueue.sort((b, a) => new Date(a.importedOn) - new Date(b.importedOn)),
            });
        });
    };

    downloadImportJobExcel = (jobImport) => {
        this.props.onJob.downloadJobImportExcel(jobImport.id).then(() => {
            if (!this.props.loadingExport && !this.props.jobsError) {
                if (this.props.exportData) {
                    var today = new Date();
                    var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                    FileDownload(this.props.exportData, jobImport.fileName);
                }
            }
        });
    };

    renderJobImportQueue = () => {
        const { jobImportQueue, searchKeywordQueue } = this.state;
        const downloadImportJobExcel = this.downloadImportJobExcel;

        const columns = [
            {
                id: "fileName",
                name: "FileName",
                selector: (row) => row.fileName,
                sortable: true,
                width: "20%",
                format: function (row, index) {
                    return row.fileName;
                },
            },
            {
                id: "status",
                name: "Status",
                selector: (row) => row.status,
                sortable: true,
                width: "12%",
            },
            {
                id: "importedOn",
                name: "Imported On",
                selector: (row) => row.importedOn,
                sortable: true,
                width: "15%",
                format: function (row, index) {
                    return new Intl.DateTimeFormat("en-GB", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hourCycle: "h23",
                        hour: "2-digit",
                        minute: "2-digit",
                    }).format(new Date(row.importedOn));
                },
            },
            {
                id: "statusMessage",
                name: "Status Message",
                selector: (row) => row.statusMessage,
                sortable: true,
                width: "15%",
            },
            {
                id: "id",
                name: "Download",
                selector: (row) => row.id,
                sortable: true,
                width: "10%",
                format: function (row, index) {
                    return (
                        <Link to="#" onClick={() => downloadImportJobExcel(row)}>
                            Download
                        </Link>
                    );
                },
            },
            {
                id: "message",
                name: "Message",
                selector: (row) => row.message,
                sortable: true,
                wrap: true,
                width: "28%",
            },
        ];

        return (
            <React.Fragment>
                {jobImportQueue && jobImportQueue.length > 0 && (
                    <React.Fragment>
                        <br />
                        <p
                            className="mb-0"
                            style={{
                                color: "#2B2B2B",
                                fontSize: "16px",
                                fontFamily: "IBMPlexSans-Bold",
                                paddingBottom: "10px",
                            }}
                        >
                            Requirements Import Queue
                        </p>
                        <div>
                            <Row>
                                <Col md="6" sm="8">
                                    <SkillsTxTableSearchBar
                                        searchKeyword={this.state.searchKeywordQueue}
                                        onChange={(searchKeyword) => {
                                            this.setState({ searchKeywordQueue: searchKeyword });
                                        }}
                                    ></SkillsTxTableSearchBar>
                                </Col>
                                <Col md="6" sm="4">
                                    <SkillsTxTableClearSearch
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        color="primary"
                                        disabled={this.props.jobsLoading || this.state.isUpdating}
                                        onClear={() => {
                                            this.setState({ searchKeywordQueue: "" });
                                        }}
                                    ></SkillsTxTableClearSearch>
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        onClick={this.handleRefreshJobQueueu}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        disabled={this.props.jobsLoading || this.state.isUpdating}
                                    >
                                        <FontAwesomeIcon icon={faSync} /> Refresh
                                    </Button>
                                </Col>
                            </Row>
                            <SkillsTxTable columns={columns} data={jobImportQueue} pagination={true} searchKeyword={searchKeywordQueue}></SkillsTxTable>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    handlePerformCopyJobs = (copiedJobs) => {
        this.setState({ isUpdating: true, copyJobsSuccess: false });
        this.props.onJob.copyJobs(copiedJobs).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.jobsError) {
                    this.generateAlert("success", "Copy default requirements success.");
                    this.setState({ copyJobsSuccess: true });
                } else {
                    this.generateAlert("danger", this.props.jobsError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleShowSampleJobs = () => {
        this.setState({ showSampleJobsDialogTs: new Date() });
    };

    handleGenerateReport = () => {
        this.setState({ printReportsMode: true });
    };

    handleConfirmGenerateReport = () => {
        const selectedJobsPrint = this.state.selectedJobsPrint;
        this.setState({ loadingPrint: true, printReportsMode: false });
        this.handleClearSelection();
        this.props.onJob.printJobReports(selectedJobsPrint).then(() => {
            if (!this.props.loadingPrint) {
                if (this.props.printData) {
                    FileDownload(this.props.printData, `SkillsTx_Requirement_Profiles.zip`);
                    this.setState({ selectedJobsPrint: [] });
                }
            }

            this.setState({ loadingPrint: this.props.loadingPrint });
        });
    };

    handleCancelGenerateReport = () => {
        this.setState({ printReportsMode: false });
        this.handleClearSelection();
    };

    handleRowClick = (row) => {
        const jobs = this.state.jobs;
        const updatedData = jobs.find((it) => it.id == row.id);
        updatedData.toggleSelected = !updatedData.toggleSelected;

        this.setState({ jobs: [...jobs], selectedJobsPrint: jobs.filter((it) => it.toggleSelected).map((row) => row.id) });
    };

    handleSelectAll = () => {
        const { jobs, searchKeyword } = this.state;
        let selectAll = false;

        const filtered = this.filterArray(jobs, searchKeyword, this.searchProperties());

        if (filtered.filter((it) => it.toggleSelected).length == 0) {
            selectAll = true;
        } else if (filtered.filter((it) => !it.toggleSelected).length == 0) {
            selectAll = false;
        } else {
            selectAll = true;
        }

        jobs.forEach((it) => {
            it.toggleSelected = false;
        });

        if (selectAll) {
            jobs.forEach((it) => {
                if (filtered.find((f) => f.id == it.id)) {
                    it.toggleSelected = true;
                }
            });
        }

        this.setState({ jobs: [...jobs], selectedJobsPrint: jobs.filter((it) => it.toggleSelected).map((row) => row.id) });
    };

    handleClearSelection = () => {
        const jobs = this.state.jobs;

        jobs.forEach((it) => {
            it.toggleSelected = false;
        });

        this.setState({ jobs: [...jobs] });
    };

    onTableFilterChange = (e, searchType) => {
        let columnSearchKeywords = this.state.columnSearchKeywords;
        if (!columnSearchKeywords) {
            columnSearchKeywords = [];
        }

        if (columnSearchKeywords.find((it) => it.name == e.target.name)) {
            let columnSearchKeyword = columnSearchKeywords.find((it) => it.name == e.target.name);
            columnSearchKeyword.value = e.target.value;
        } else {
            columnSearchKeywords.push({
                name: e.target.name,
                value: e.target.value,
                searchType,
            });
        }

        this.setState({ columnSearchKeywords });
    };

    handleShowColumnSettings = () => {
        const showColumnSettings = this.state.showColumnSettings;

        this.setState({ showColumnSettings: !showColumnSettings });
    };

    render() {
        const { pageAssignments, loginWithTeams } = this.props;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Manage Requirements");

        const { authPersonId } = this.props;

        const openJobDetail = this.openJobDetail;
        const handleDeleteJob = this.handleDeleteJob;
        const handleCopyJob = this.handleCopyJob;
        const handleRowClick = this.handleRowClick;
        const handleSelectAll = this.handleSelectAll;

        const onTableFilterChange = this.onTableFilterChange;

        const { jobImportQueue, searchKeyword, jobs, columnSearchKeywords } = this.state;

        const filtered = this.filterArray(jobs, searchKeyword, this.searchProperties());

        const ownerSort = (rowA, rowB) => {
            let ownerA = rowA.owner ? `0${rowA.owner}` : "1";
            let ownerB = rowB.owner ? `0${rowB.owner}` : "1";

            if (ownerA > ownerB) {
                return 1;
            }

            if (ownerB > ownerA) {
                return -1;
            }

            return 0;
        };

        const whoCanSeeSort = (rowA, rowB) => {
            let whoCanSeeA = rowA.isHidden && !rowA.isCandidateJob ? "Nobody" : !rowA.isHidden && !rowA.isCandidateJob ? "Employee Only" : rowA.isHidden && rowA.isCandidateJob ? "Candidate Only" : "Candidates and Employees";
            let whoCanSeeB = rowB.isHidden && !rowB.isCandidateJob ? "Nobody" : !rowB.isHidden && !rowB.isCandidateJob ? "Employee Only" : rowB.isHidden && rowB.isCandidateJob ? "Candidate Only" : "Candidates and Employees";

            if (whoCanSeeA > whoCanSeeB) {
                return 1;
            }

            if (whoCanSeeB > whoCanSeeA) {
                return -1;
            }

            return 0;
        };

        const pageName = "Manage Requirements";
        const columnDisplaySettings = this.props.columnDisplaySettings ? this.props.columnDisplaySettings.filter((it) => it.pageName == pageName) : [];

        const columns = [
            {
                id: "id",
                name: (
                    <div style={{ textAlign: "center" }}>
                        <FontAwesomeIcon
                            id={`headerChkSelectAll`}
                            icon={filtered.filter((it) => it.toggleSelected).length == 0 ? faSquare : filtered.filter((it) => !it.toggleSelected).length == 0 ? faSquareCheck : faSquareMinus}
                            style={{ cursor: "pointer", color: filtered.filter((it) => it.toggleSelected).length == 0 ? "#8f8f9d" : "#007bff" }}
                            size="lg"
                            onClick={() => handleSelectAll()}
                        />
                    </div>
                ),
                selector: (row) => row.id,
                sortable: false,
                width: "80px",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Input type="checkbox" id={`chkRow${row.id}`} checked={row.toggleSelected} onClick={(e) => handleRowClick(row)} />
                        </div>
                    );
                },
                omit: !this.state.printReportsMode,
            },
            {
                id: "updatedOn",
                name: "Action",
                selector: (row) => row.updatedOn,
                sortable: false,
                width: "120px",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                            <FontAwesomeIcon id={`iconJobDetail${row.id}`} icon={faInfoCircle} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => openJobDetail(row)} />
                            <UncontrolledTooltip target={`iconJobDetail${row.id}`} placement="bottom">
                                Requirement Info
                            </UncontrolledTooltip>
                            &nbsp;&nbsp;&nbsp;
                            <Link id={`iconJobEdit${row.id}`} to={`requirements/${row.id}/profile`}>
                                <FontAwesomeIcon icon={faPenToSquare} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} />
                                <UncontrolledTooltip target={`iconJobEdit${row.id}`} placement="bottom">
                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) && (!row.ownerId || row.ownerId == authPersonId) ? "Edit Requirement Profile" : "Show Requirement Profile"}
                                </UncontrolledTooltip>
                            </Link>
                            &nbsp;&nbsp;&nbsp;
                            {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Copy Requirement" && op.allow == true) && (
                                <React.Fragment>
                                    <FontAwesomeIcon id={`iconUserCopy${row.id}`} icon={faCopy} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => handleCopyJob(row)} />
                                    <UncontrolledTooltip target={`iconUserCopy${row.id}`} placement="bottom">
                                        Copy Requirement
                                    </UncontrolledTooltip>
                                    &nbsp;&nbsp;&nbsp;
                                </React.Fragment>
                            )}
                            {pageAssignment &&
                                pageAssignment.operations &&
                                pageAssignment.operations.find((op) => op.operation == "Delete" && op.allow == true) &&
                                !(row.jobMatches && row.jobMatches.filter((it) => it.personInactive == false).length > 0) &&
                                (!row.ownerId || row.ownerId == authPersonId) && (
                                    <React.Fragment>
                                        <FontAwesomeIcon id={`iconUserDelete${row.id}`} icon={faMinusCircle} size="lg" style={{ cursor: "pointer", color: "#FF0000" }} onClick={() => handleDeleteJob(row)} />
                                        <UncontrolledTooltip target={`iconUserDelete${row.id}`} placement="bottom">
                                            Delete Requirement
                                        </UncontrolledTooltip>
                                    </React.Fragment>
                                )}
                        </div>
                    );
                },
            },
            {
                id: "name",
                name: (
                    <div style={{ width: "90%" }}>
                        Name
                        <br />
                        <input type="text" name="name" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "name") ? columnSearchKeywords.find((it) => it.name == "name").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.name,
                sortable: true,
                width: "300px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "name") ? !columnDisplaySettings.find((it) => it.columnName == "name").visible : true,
            },
            {
                id: "reference",
                name: (
                    <div style={{ width: "90%" }}>
                        Reference
                        <br />
                        <input type="text" name="reference" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "reference") ? columnSearchKeywords.find((it) => it.name == "reference").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.reference,
                sortable: true,
                width: "200px",
                wrap: true,
                format: function (row, index) {
                    return row.reference;
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "reference") ? !columnDisplaySettings.find((it) => it.columnName == "reference").visible : true,
            },
            {
                id: "customerReference",
                name: (
                    <div style={{ width: "90%" }}>
                        Customer Reference
                        <br />
                        <input
                            type="text"
                            name="customerReference"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "customerReference") ? columnSearchKeywords.find((it) => it.name == "customerReference").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.customerReference,
                sortable: true,
                width: "150px",
                wrap: true,
                format: function (row, index) {
                    return row.customerReference;
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "customerReference") ? !columnDisplaySettings.find((it) => it.columnName == "customerReference").visible : true,
            },
            {
                id: "owner",
                name: (
                    <div style={{ width: "90%" }}>
                        Owner
                        <br />
                        <input type="text" name="owner" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "owner") ? columnSearchKeywords.find((it) => it.name == "owner").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.owner,
                sortFunction: ownerSort,
                sortable: true,
                width: "150px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "owner") ? !columnDisplaySettings.find((it) => it.columnName == "owner").visible : true,
            },
            {
                id: "isHidden",
                name: (
                    <div style={{ width: "90%" }}>
                        Who can see
                        <br />
                        <input type="text" name="whoCanSee" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "whoCanSee") ? columnSearchKeywords.find((it) => it.name == "whoCanSee").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.isHidden,
                sortFunction: whoCanSeeSort,
                sortable: true,
                width: "200px",
                wrap: true,
                format: function (row, index) {
                    return row.isHidden && !row.isCandidateJob ? "Nobody" : !row.isHidden && !row.isCandidateJob ? "Employee Only" : row.isHidden && row.isCandidateJob ? "Candidate Only" : "Candidates and Employees";
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "whoCanSee") ? !columnDisplaySettings.find((it) => it.columnName == "whoCanSee").visible : true,
            },
            {
                id: "inActive",
                name: (
                    <div style={{ width: "90%" }}>
                        InActive
                        <br />
                        <input type="text" name="active" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "active") ? columnSearchKeywords.find((it) => it.name == "active").value : ""} onChange={(e) => onTableFilterChange(e, "startsWith")} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.inActive,
                sortable: true,
                width: "100px",
                format: function (row, index) {
                    return row.inActive ? "Inactive" : "Active";
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "inActive") ? !columnDisplaySettings.find((it) => it.columnName == "inActive").visible : true,
            },
            {
                id: "showAsRoleBasedSurveyInSelfRegistration",
                name: (
                    <div style={{ width: "90%" }}>
                        Include in Self Registration Role Based Survey
                        <br />
                        <input
                            type="text"
                            name="strShowAsRoleBasedSurveyInSelfRegistration"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "strShowAsRoleBasedSurveyInSelfRegistration") ? columnSearchKeywords.find((it) => it.name == "strShowAsRoleBasedSurveyInSelfRegistration").value : ""}
                            onChange={(e) => onTableFilterChange(e, "startsWith")}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.showAsRoleBasedSurveyInSelfRegistration,
                sortable: true,
                width: "150px",
                format: function (row, index) {
                    return row.strShowAsRoleBasedSurveyInSelfRegistration;
                },
                omit: columnDisplaySettings.find((it) => it.columnName == "showAsRoleBasedSurveyInSelfRegistration") ? !columnDisplaySettings.find((it) => it.columnName == "showAsRoleBasedSurveyInSelfRegistration").visible : true,
            },
            {
                id: "department",
                name: (
                    <div style={{ width: "90%" }}>
                        Department
                        <br />
                        <input type="text" name="department" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "department") ? columnSearchKeywords.find((it) => it.name == "department").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.department,
                sortable: true,
                width: "150px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "department") ? !columnDisplaySettings.find((it) => it.columnName == "department").visible : true,
            },
            {
                id: "reportingTo",
                name: (
                    <div style={{ width: "90%" }}>
                        Reporting To
                        <br />
                        <input type="text" name="reportingTo" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "reportingTo") ? columnSearchKeywords.find((it) => it.name == "reportingTo").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.reportingTo,
                sortable: true,
                width: "150px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "reportingTo") ? !columnDisplaySettings.find((it) => it.columnName == "reportingTo").visible : true,
            },
            {
                id: "location",
                name: (
                    <div style={{ width: "90%" }}>
                        Location
                        <br />
                        <input type="text" name="location" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "location") ? columnSearchKeywords.find((it) => it.name == "location").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.location,
                sortable: true,
                width: "150px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "location") ? !columnDisplaySettings.find((it) => it.columnName == "location").visible : true,
            },
            {
                id: "fte",
                name: (
                    <div style={{ width: "90%" }}>
                        FTE Count
                        <br />
                        <input type="text" name="fte" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "fte") ? columnSearchKeywords.find((it) => it.name == "fte").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.fte,
                sortable: true,
                width: "100px",
                wrap: true,
                center: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "fte") ? !columnDisplaySettings.find((it) => it.columnName == "fte").visible : true,
            },
            {
                id: "employmentStatus",
                name: (
                    <div style={{ width: "90%" }}>
                        Employment Status
                        <br />
                        <input
                            type="text"
                            name="employmentStatus"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "employmentStatus") ? columnSearchKeywords.find((it) => it.name == "employmentStatus").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.employmentStatus,
                sortable: true,
                width: "150px",
                wrap: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "employmentStatus") ? !columnDisplaySettings.find((it) => it.columnName == "employmentStatus").visible : true,
            },
            {
                id: "userCountCurrent",
                name: (
                    <div style={{ width: "90%" }}>
                        User count (Current Requirement)
                        <br />
                        <input
                            type="text"
                            name="userCountCurrent"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "userCountCurrent") ? columnSearchKeywords.find((it) => it.name == "userCountCurrent").value : ""}
                            onChange={(e) => onTableFilterChange(e, "startsWith")}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.userCountCurrent,
                sortable: true,
                width: "150px",
                center: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "userCountCurrent") ? !columnDisplaySettings.find((it) => it.columnName == "userCountCurrent").visible : true,
            },
            {
                id: "userCountFuture",
                name: (
                    <div style={{ width: "90%" }}>
                        User count (Future Requirement)
                        <br />
                        <input
                            type="text"
                            name="userCountFuture"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "userCountFuture") ? columnSearchKeywords.find((it) => it.name == "userCountFuture").value : ""}
                            onChange={(e) => onTableFilterChange(e, "startsWith")}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.userCountFuture,
                sortable: true,
                width: "150px",
                center: true,
                omit: columnDisplaySettings.find((it) => it.columnName == "userCountFuture") ? !columnDisplaySettings.find((it) => it.columnName == "userCountFuture").visible : true,
            },
        ];

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.renderDeleteJobConfirmation()}
                <JobBulkUpload handleAddedJobImports={this.handleAddedJobImports} jobImportSessionId={this.state.jobImportSessionId} showDialog={this.state.showDialogImportTs} generateAlert={this.generateAlert}></JobBulkUpload>
                <ManageJobSample jobs={this.state.sampleJobs} copyJobsSuccess={this.state.copyJobsSuccess} handlePerformCopyJobs={this.handlePerformCopyJobs} showDialog={this.state.showSampleJobsDialogTs} isUpdating={this.state.isUpdating}></ManageJobSample>
                <JobCopyDialog copyJobSuccess={this.state.copyJobSuccess} handleSaveCopyJob={this.handleSaveCopyJob} showDialog={this.state.showJobCopyDialogTs} selectedJob={this.state.selectedJob} isUpdating={this.state.isUpdating}></JobCopyDialog>
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <JobDetail
                            selectedJob={this.state.selectedJob}
                            selectedJobUpdated={this.state.selectedJobUpdated}
                            showDialog={this.state.showDialogJobDetailTs}
                            generateAlert={this.generateAlert}
                            allowEdit={pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? true : false}
                        ></JobDetail>
                        <div>
                            <Row>
                                <Col md="5" sm="6">
                                    <SkillsTxTableSearchBar
                                        searchKeyword={this.state.searchKeyword}
                                        onChange={(searchKeyword) => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword, selectedJobsPrint: [] });
                                        }}
                                    ></SkillsTxTableSearchBar>
                                </Col>
                                <Col md="7" sm="6">
                                    <SkillsTxTableClearSearch
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        color="primary"
                                        disabled={this.props.jobsLoading || this.state.isUpdating}
                                        onClear={() => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword: "", selectedJobsPrint: [] });
                                        }}
                                    ></SkillsTxTableClearSearch>
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        onClick={this.handleRefresh}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        disabled={this.props.jobsLoading || this.state.isUpdating}
                                    >
                                        <FontAwesomeIcon icon={faSync} /> Refresh
                                    </Button>
                                    &nbsp;&nbsp;
                                    {!this.state.printReportsMode && (
                                        <React.Fragment>
                                            {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Add" && op.allow == true) && (
                                                <React.Fragment>
                                                    <Button
                                                        color="primary"
                                                        onClick={this.handleAddNew}
                                                        style={{
                                                            fontSize: "16px",
                                                            height: "40px",
                                                            marginBottom: "10px",
                                                        }}
                                                        disabled={this.props.jobsLoading || this.state.isUpdating}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} /> Add New
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                </React.Fragment>
                                            )}
                                            {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Export" && op.allow == true) && (
                                                <React.Fragment>
                                                    <Button
                                                        color="primary"
                                                        onClick={this.handleExportJobs}
                                                        style={{
                                                            fontSize: "16px",
                                                            height: "40px",
                                                            marginBottom: "10px",
                                                        }}
                                                        disabled={this.props.jobsLoading || this.props.loadingExport}
                                                    >
                                                        <FontAwesomeIcon icon={faFileExcel} /> Export
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                </React.Fragment>
                                            )}
                                            {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Import" && op.allow == true) && (
                                                <React.Fragment>
                                                    <Button
                                                        id="btnImport"
                                                        color="primary"
                                                        onClick={this.handleImportJobs}
                                                        style={{
                                                            fontSize: "16px",
                                                            height: "40px",
                                                            marginBottom: "10px",
                                                        }}
                                                        disabled={this.props.jobsLoading || !(!jobImportQueue || (jobImportQueue && jobImportQueue.length == 0) || (jobImportQueue && jobImportQueue.filter((it) => it.status != "Completed").length == 0))}
                                                    >
                                                        <FontAwesomeIcon icon={faFileExcel} /> Import
                                                    </Button>
                                                    {!(!jobImportQueue || (jobImportQueue && jobImportQueue.length == 0) || (jobImportQueue && jobImportQueue.filter((it) => it.status != "Completed").length == 0)) && (
                                                        <UncontrolledTooltip target={`btnImport`} placement="bottom">
                                                            While the process is running the uploading of more requirements will be unavailable
                                                        </UncontrolledTooltip>
                                                    )}
                                                    &nbsp;&nbsp;
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                    <React.Fragment>
                                        <Button
                                            color={this.state.showColumnSettings ? "success" : "primary"}
                                            onClick={this.handleShowColumnSettings}
                                            style={{
                                                fontSize: "16px",
                                                height: "40px",
                                                marginBottom: "10px",
                                            }}
                                            disabled={this.props.jobsLoading}
                                        >
                                            <FontAwesomeIcon icon={faCog} /> Columns
                                        </Button>
                                        &nbsp;&nbsp;
                                    </React.Fragment>
                                    {!this.state.printReportsMode && (
                                        <React.Fragment>
                                            {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Copy Sample Requirement" && op.allow == true) && (
                                                <React.Fragment>
                                                    <Button
                                                        id="btnSampleJobs"
                                                        color="primary"
                                                        onClick={this.handleShowSampleJobs}
                                                        style={{
                                                            fontSize: "16px",
                                                            height: "40px",
                                                            marginBottom: "10px",
                                                        }}
                                                        disabled={this.props.jobsLoading}
                                                    >
                                                        <FontAwesomeIcon icon={faListUl} /> Sample Requirements
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Generate Reports" && op.allow == true) && (
                                        <Button
                                            id="btnGenerateReport"
                                            color="primary"
                                            onClick={this.handleGenerateReport}
                                            style={{
                                                fontSize: "16px",
                                                height: "40px",
                                                marginBottom: "10px",
                                            }}
                                            disabled={this.state.loadingPrint || this.state.printReportsMode}
                                        >
                                            <FontAwesomeIcon icon={faDownload} /> Generate Reports
                                        </Button>
                                    )}
                                    {this.state.printReportsMode && (
                                        <React.Fragment>
                                            &nbsp;&nbsp;
                                            <Button
                                                id="btnOKGenerateReport"
                                                color="primary"
                                                onClick={this.handleConfirmGenerateReport}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.loadingPrint || this.state.selectedJobsPrint.length == 0}
                                            >
                                                OK
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                id="btnCancelGenerateReport"
                                                color="primary"
                                                onClick={this.handleCancelGenerateReport}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.loadingPrint}
                                            >
                                                Cancel
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                            {this.state.showColumnSettings && (
                                <div style={{ marginBottom: "10px" }}>
                                    <ColumnDisplaySetting pageName={"Manage Requirements"}></ColumnDisplaySetting>
                                </div>
                            )}
                            <SkillsTxTable
                                columns={columns}
                                data={jobs}
                                pagination={true}
                                searchKeyword={searchKeyword}
                                searchProperties={this.searchProperties()}
                                columnSearchKeywords={columnSearchKeywords}
                                selectableRows={this.state.printReportsMode ? true : false}
                                selectableRowsHighlight={true}
                                handleRowClick={this.handleRowClick}
                                progressPending={this.props.jobsLoading}
                            ></SkillsTxTable>
                        </div>
                        {this.renderJobImportQueue()}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        personId: state.auth.personId,
        loading: state.auth.loading,
        error: state.auth.error,
        authPersonId: state.auth.personId,
        loginWithTeams: state.auth.loginWithTeams,

        jobsLoading: state.job.loading,
        jobsError: state.job.error,
        jobs: state.job.jobs,
        sampleJobs: state.job.sampleJobs,
        jobImportQueue: state.job.jobImportQueue,

        jobImportSessionId: state.job.jobImportSessionId,
        exportData: state.job.exportData,
        loadingExport: state.job.loadingExport,

        loadingPrint: state.job.loadingPrint,
        printData: state.job.printData,

        pageAssignments: state.adminPerson.pageAssignments,
        columnDisplaySettings: state.adminPerson.columnDisplaySettings,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageJob));
