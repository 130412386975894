import * as actionPlanActions from "../../../store/actions/actionPlanAction";
import * as authActions from "../../../store/actions/authAction";
import * as categoryActions from "../../../store/actions/categoryAction";
import * as companyActions from "../../../store/actions/companyAction";
import * as lorActions from "../../../store/actions/lorAction";
import * as interventionActions from "../../../store/admin/actions/adminInterventionActions";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { filterArray } from "../../../utils/filterUtils";
import { contentTranslator } from "../../../utils/translatorUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import ManageTrainingDetail from "./ManageTrainingDetail";
import TrainingBulkUpload from "./TrainingBulkUpload";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faPenToSquare, faTrash, faFileExcel, faMinusCircle, faPlus, faSync, faSquareMinus, faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, Col, Input, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const rowStyle = {
    fontSize: "12px",
};

class ManageTrainings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            interventions: [],
            isUpdating: false,
            selectedInterventions: [],
            selectedIntervention: null,
            showDialogInterventionDetailTs: null,
            providers: [],
            actionTypes: [],
            searchProperties: ["name", "actionType", "lorLevelsString", "levelsString", "link"],
            numberActiveActionPromotions: null,
        };

        this.filterArray = filterArray.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        const { containerConfig, pageAssignments } = this.props;
        const pageAssignment = pageAssignments ? pageAssignments.find((it) => it.pageName == "Manage Action List") : null;

        this.props.onAuth.authCheckState().then(() => {
            this.props.onActionPlan.getActionTypes().then(() => {
                let actionTypes = [...this.props.actionTypes];

                if (containerConfig && containerConfig.allowBadgesFromActions && pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Manage Badge Actions" && op.allow == true)) {
                    actionTypes = [
                        {
                            id: "Accredited Badge Issuing Action",
                            name: "Accredited Badge Issuing Action",
                        },
                        ...actionTypes,
                    ];
                }

                this.setState({
                    actionTypes: [...actionTypes],
                });
            });

            this.props.onIntervention.getNoOfActiveActionPromotions().then(() => {
                this.setState({
                    numberActiveActionPromotions: this.props.numberActiveActionPromotions,
                });
            });

            Promise.all([this.props.onCompany.getProviderCompanies(), this.props.onCategory.getCategories(), this.props.onLor.getLors()]).then(() => {
                if (!this.props.loading && !this.props.categoryLoading && !this.props.companyLoading && !this.props.lorLoading) {
                    this.setState({ providers: this.props.providers, lors: this.props.lors, categories: this.props.categories });
                    this.initData();
                }

                this.setState({
                    loading: this.props.loading || this.props.categoryLoading || this.props.companyLoading || this.props.lorLoading,
                });
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.interventions !== this.props.interventions) {
            //this.setState({ providers: this.props.providers.sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1)) });
            this.setState({ interventions: this.prepareInterventions(this.props.interventions).sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1)) });
        }
    }

    initData = () => {
        this.props.onIntervention.getInterventions(false, false, true).then(() => {
            if (!this.props.interventionLoading) {
                this.setState({ interventions: this.prepareInterventions(this.props.interventions).sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1)) });
            }

            this.setState({ loading: this.props.loading || this.props.interventionLoading });
        });
    };

    handleRefresh = () => {
        this.props.onIntervention.getNoOfActiveActionPromotions().then(() => {
            this.setState({
                numberActiveActionPromotions: this.props.numberActiveActionPromotions,
            });
        });

        this.initData();
    };

    prepareInterventions = (interventions) => {
        return interventions.map((intervention) => {
            return {
                id: intervention.id,
                companyId: intervention.companyId,
                company: intervention.company,
                actionType: intervention.actionType,
                name: intervention.name,
                link: intervention.link,
                duration: intervention.duration,
                durationHours: intervention.durationHours,
                description: intervention.description,
                isActive: intervention.isActive,
                active: intervention.isActive ? "True" : "False",
                isValidated: intervention.isValidated,
                publishedOn: intervention.publishedOn,
                reviewOn: intervention.reviewOn,
                retireOn: intervention.retireOn,
                sfiaVersion: intervention.sfiaVersion,
                versionNumber: intervention.versionNumber,
                levelsString: intervention.levels
                    .sort((a, b) => `${a.skillsCode}${a.level}` > `${b.skillsCode}${b.level}`)
                    .map((level) => {
                        return `${level.skillsCode}${level.level}`;
                    })
                    .join(", "),
                levels: intervention.levels,
                lorLevelsString: intervention.lorLevels
                    .sort((a, b) => `${a.lorCode}${a.level}` > `${b.lorCode}${b.level}`)
                    .map((level) => {
                        return `${level.lorCode}${level.level}`;
                    })
                    .join(", "),
                lorLevels: intervention.lorLevels,
                badgeIssuer: intervention.badgeIssuer,
                inUse: intervention.inUse,
            };
        });
    };

    openInterventionDetail = (intervention) => {
        this.setState({ selectedIntervention: intervention, showDialogInterventionDetailTs: new Date() });
    };

    handleAddNew = () => {
        this.setState({
            selectedIntervention: {
                id: null,
            },
            showDialogInterventionDetailTs: new Date(),
        });
    };

    handleSaveUpdateIntervention = (intervention) => {
        this.setState({ isUpdating: true, updateSuccess: false });
        this.props.onIntervention.updateIntervention(intervention.id, intervention).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.interventionError) {
                    this.generateAlert("success", "Update training success.");
                    this.setState({ updateSuccess: true });
                } else {
                    this.generateAlert("danger", this.props.interventionError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleSaveAddIntervention = (intervention) => {
        this.setState({ isUpdating: true, updateSuccess: false });
        this.props.onIntervention.addIntervention(intervention).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.interventionError) {
                    this.generateAlert("success", "Create training success.");
                    this.setState({ updateSuccess: true });
                    this.handleRefresh();
                } else {
                    this.generateAlert("danger", this.props.interventionError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleRowClick = (row) => {
        const interventions = this.state.interventions;
        const updatedData = interventions.find((it) => it.id == row.id);
        if (!updatedData.inUse) {
            updatedData.toggleSelected = !updatedData.toggleSelected;
        }

        this.setState({ interventions: [...interventions], selectedInterventions: interventions.filter((it) => it.toggleSelected).map((row) => row.id) });
    };

    handleSelectAll = () => {
        const { interventions, searchKeyword, searchProperties } = this.state;
        let selectAll = false;

        const filtered = this.filterArray(interventions, searchKeyword, searchProperties);

        if (filtered.filter((it) => !it.inUse && it.toggleSelected).length == 0) {
            selectAll = true;
        } else if (filtered.filter((it) => !it.inUse && !it.toggleSelected).length == 0) {
            selectAll = false;
        } else {
            selectAll = true;
        }

        interventions.forEach((it) => {
            it.toggleSelected = false;
        });

        if (selectAll) {
            interventions.forEach((it) => {
                if (!it.inUse) {
                    if (filtered.find((f) => f.id == it.id)) {
                        it.toggleSelected = true;
                    }
                }
            });
        }

        this.setState({ interventions: [...interventions], selectedInterventions: interventions.filter((it) => it.toggleSelected).map((row) => row.id) });
    };

    handleClearSelection = () => {
        const interventions = this.state.interventions;

        interventions.forEach((it) => {
            it.toggleSelected = false;
        });

        this.setState({ interventions: [...interventions] });
    };

    handleDeleteIntervention = (intervention) => {
        if (window.confirm("Are you sure want to delete this action?")) {
            this.setState({ isUpdating: true });

            this.props.onIntervention.deleteIntervention(intervention.id).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.interventionError) {
                        this.generateAlert("success", "Delete action success.");
                        this.handleRefresh();
                    } else {
                        this.generateAlert("danger", this.props.interventionError.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    handleDeleteInterventions = () => {
        if (window.confirm("Are you sure want to delete selected actions?")) {
            this.setState({ isUpdating: true });

            this.props.onIntervention.deleteInterventions(this.state.selectedInterventions).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.interventionError) {
                        this.setState({ selectedInterventions: [] });
                        this.generateAlert("success", "Delete actions success.");
                        this.handleRefresh();
                    } else {
                        this.generateAlert("danger", this.props.interventionError.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    handleAfterSearch = () => {
        this.setState({ selectedInterventions: [] });
    };

    pad2 = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    handleExportInterventions = () => {
        const timezoneOffset = (new Date().getTimezoneOffset() * -1) / 60;
        this.props.onIntervention.exportInterventionsDataExcel(timezoneOffset).then(() => {
            if (!this.props.loadingExport && !this.props.companyError) {
                if (this.props.exportData) {
                    var today = new Date();
                    var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                    FileDownload(this.props.exportData, `ExportActions_${strToday}.xlsx`);
                }
            }
        });
    };

    handleImportInterventions = () => {
        this.setState({ showDialogImportTs: new Date() });
    };

    handleImportSuccess = () => {
        this.handleRefresh();
    };

    handleManageActionPromotions = () => {
        this.props.history.push("/admin/actionpromotions");
    };

    onTableFilterChange = (e, searchType) => {
        let columnSearchKeywords = this.state.columnSearchKeywords;
        if (!columnSearchKeywords) {
            columnSearchKeywords = [];
        }

        if (columnSearchKeywords.find((it) => it.name == e.target.name)) {
            let columnSearchKeyword = columnSearchKeywords.find((it) => it.name == e.target.name);
            columnSearchKeyword.value = e.target.value;
        } else {
            columnSearchKeywords.push({
                name: e.target.name,
                value: e.target.value,
                searchType,
            });
        }

        this.setState({ columnSearchKeywords });
    };

    render() {
        const { interventions, searchKeyword, searchProperties, columnSearchKeywords } = this.state;
        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Manage Action List");

        const openInterventionDetail = this.openInterventionDetail;
        const handleDeleteIntervention = this.handleDeleteIntervention;
        const handleRowClick = this.handleRowClick;
        const handleSelectAll = this.handleSelectAll;
        const onTableFilterChange = this.onTableFilterChange;

        const filtered = this.filterArray(interventions, searchKeyword, searchProperties);

        const columns = [
            {
                id: "id",
                name: (
                    <div style={{ textAlign: "center" }}>
                        <FontAwesomeIcon
                            id={`headerChkSelectAll`}
                            icon={filtered.filter((it) => !it.inUse && it.toggleSelected).length == 0 ? faSquare : filtered.filter((it) => !it.inUse && !it.toggleSelected).length == 0 ? faSquareCheck : faSquareMinus}
                            style={{ cursor: "pointer", color: filtered.filter((it) => it.toggleSelected).length == 0 ? "#8f8f9d" : "#007bff" }}
                            size="lg"
                            onClick={() => handleSelectAll()}
                        />
                    </div>
                ),
                selector: (row) => row.id,
                sortable: false,
                width: "5%",
                center: true,
                format: function (row, index) {
                    return (
                        !row.inUse && (
                            <div style={{ textAlign: "center" }}>
                                <Input type="checkbox" id={`chkRow${row.id}`} checked={row.toggleSelected} onClick={(e) => handleRowClick(row)} />
                            </div>
                        )
                    );
                },
                omit: pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Delete" && op.allow == true) ? false : true,
            },
            {
                id: "action",
                name: "Action",
                selector: (row) => row.id,
                sortable: false,
                width: "7%",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                            {row.inUse ? (
                                "In Use"
                            ) : (
                                <React.Fragment>
                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) && (
                                        <React.Fragment>
                                            <FontAwesomeIcon id={`iconProviderDetail${row.id}`} icon={faPenToSquare} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => openInterventionDetail(row)} />
                                            <UncontrolledTooltip target={`iconProviderDetail${row.id}`} placement="bottom">
                                                Update Action
                                            </UncontrolledTooltip>
                                            &nbsp;&nbsp;&nbsp;
                                        </React.Fragment>
                                    )}
                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Delete" && op.allow == true) && (
                                        <React.Fragment>
                                            <FontAwesomeIcon id={`iconProviderDelete${row.id}`} icon={faMinusCircle} size="lg" style={{ cursor: "pointer", color: "#FF0000" }} onClick={() => handleDeleteIntervention(row)} />
                                            <UncontrolledTooltip target={`iconProviderDelete${row.id}`} placement="bottom">
                                                Delete Action
                                            </UncontrolledTooltip>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    );
                },
                omit: pageAssignment && pageAssignment.operations && (pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) || pageAssignment.operations.find((op) => op.operation == "Delete" && op.allow == true)) ? false : true,
            },
            {
                id: "name",
                name: (
                    <div style={{ width: "90%" }}>
                        Name
                        <br />
                        <input type="text" name="name" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "name") ? columnSearchKeywords.find((it) => it.name == "name").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.name,
                sortable: true,
                wrap: true,
                width: "13%",
            },
            {
                id: "actionType",
                name: (
                    <div style={{ width: "90%" }}>
                        Type
                        <br />
                        <input type="text" name="actionType" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "actionType") ? columnSearchKeywords.find((it) => it.name == "actionType").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.actionType,
                sortable: true,
                width: "9%",
                wrap: true,
            },
            {
                id: "lorLevelsString",
                name: (
                    <div style={{ width: "90%" }}>
                        Lor
                        <br />
                        <input
                            type="text"
                            name="lorLevelsString"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "lorLevelsString") ? columnSearchKeywords.find((it) => it.name == "lorLevelsString").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.lorLevelsString,
                sortable: true,
                width: "10%",
                format: function (row, index) {
                    return row.lorLevelsString;
                },
                wrap: true,
            },
            {
                id: "levelsString",
                name: (
                    <div style={{ width: "90%" }}>
                        Skills
                        <br />
                        <input type="text" name="levelsString" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "levelsString") ? columnSearchKeywords.find((it) => it.name == "levelsString").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.levelsString,
                sortable: true,
                width: "10%",
                format: function (row, index) {
                    return row.levelsString;
                },
                wrap: true,
            },
            {
                id: "isActive",
                name: (
                    <div style={{ width: "90%" }}>
                        Active
                        <br />
                        <input type="text" name="active" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "active") ? columnSearchKeywords.find((it) => it.name == "active").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.isActive,
                sortable: true,
                width: "7%",
                format: function (row, index) {
                    return row.isActive ? "True" : "False";
                },
                center: true,
            },
            {
                id: "duration",
                name: (
                    <div style={{ width: "90%" }}>
                        Duration (days)
                        <br />
                        <input type="text" name="duration" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "duration") ? columnSearchKeywords.find((it) => it.name == "duration").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.duration,
                sortable: false,
                center: true,
                width: "7%",
            },
            {
                id: "durationHours",
                name: (
                    <div style={{ width: "90%" }}>
                        Duration (hours)
                        <br />
                        <input
                            type="text"
                            name="durationHours"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "durationHours") ? columnSearchKeywords.find((it) => it.name == "durationHours").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.durationHours,
                sortable: false,
                center: true,
                width: "7%",
            },
            {
                id: "link",
                name: (
                    <div style={{ width: "90%" }}>
                        Website
                        <br />
                        <input type="text" name="link" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "link") ? columnSearchKeywords.find((it) => it.name == "link").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.link,
                sortable: true,
                width: "11%",
                wrap: true,
            },
            {
                id: "versionNumber",
                name: (
                    <div style={{ width: "90%" }}>
                        Version
                        <br />
                        <input
                            type="text"
                            name="versionNumber"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "versionNumber") ? columnSearchKeywords.find((it) => it.name == "versionNumber").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.versionNumber,
                sortable: false,
                width: "7%",
                center: true,
            },
            {
                id: "sfiaVersion",
                name: (
                    <div style={{ width: "90%" }}>
                        Sfia Version
                        <br />
                        <input type="text" name="sfiaVersion" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "sfiaVersion") ? columnSearchKeywords.find((it) => it.name == "sfiaVersion").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.sfiaVersion,
                sortable: false,
                center: true,
                width: "7%",
            },
        ];

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <ManageTrainingDetail
                            actionTypes={this.state.actionTypes}
                            providers={this.state.providers}
                            lors={this.state.lors}
                            categories={this.state.categories}
                            intervention={this.state.selectedIntervention}
                            showDialog={this.state.showDialogInterventionDetailTs}
                            generateAlert={this.generateAlert}
                            isUpdating={this.state.isUpdating}
                            handleSaveUpdateIntervention={this.handleSaveUpdateIntervention}
                            handleSaveAddIntervention={this.handleSaveAddIntervention}
                            updateSuccess={this.state.updateSuccess}
                            containerConfig={this.props.containerConfig}
                        ></ManageTrainingDetail>
                        <TrainingBulkUpload handleImportSuccess={this.handleImportSuccess} showDialog={this.state.showDialogImportTs} generateAlert={this.generateAlert}></TrainingBulkUpload>
                        <div style={{ marginBottom: "10px" }}>
                            <Row>
                                <Col md="4" sm="6">
                                    <SkillsTxTableSearchBar
                                        searchKeyword={this.state.searchKeyword}
                                        onChange={(searchKeyword) => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword, selectedInterventions: [] });
                                        }}
                                    ></SkillsTxTableSearchBar>
                                </Col>
                                <Col md="8" sm="6">
                                    <SkillsTxTableClearSearch
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        color="primary"
                                        disabled={this.props.interventionLoading || this.state.isUpdating}
                                        onClear={() => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword: "", selectedInterventions: [] });
                                        }}
                                    ></SkillsTxTableClearSearch>
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        onClick={this.handleRefresh}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        disabled={this.props.interventionLoading || this.state.isUpdating}
                                    >
                                        <FontAwesomeIcon icon={faSync} /> Refresh
                                    </Button>
                                    &nbsp;&nbsp;
                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Add" && op.allow == true) && (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={this.handleAddNew}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.interventionLoading}
                                            >
                                                <FontAwesomeIcon icon={faPlus} /> Add New
                                            </Button>
                                            &nbsp;&nbsp;
                                        </React.Fragment>
                                    )}
                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Delete" && op.allow == true) && (
                                        <React.Fragment>
                                            <Button
                                                color="danger"
                                                onClick={this.handleDeleteInterventions}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.interventionLoading || this.state.selectedInterventions.length == 0}
                                            >
                                                <FontAwesomeIcon icon={faTrash} /> Delete Selected
                                            </Button>
                                            &nbsp;&nbsp;
                                        </React.Fragment>
                                    )}
                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Export" && op.allow == true) && (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={this.handleExportInterventions}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.interventionLoading || this.props.loadingExport}
                                            >
                                                <FontAwesomeIcon icon={faFileExcel} /> Export
                                            </Button>
                                            &nbsp;&nbsp;
                                        </React.Fragment>
                                    )}
                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Import" && op.allow == true) && (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={this.handleImportInterventions}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.categoriesLoading}
                                            >
                                                <FontAwesomeIcon icon={faFileExcel} /> Import
                                            </Button>
                                            &nbsp;&nbsp;
                                        </React.Fragment>
                                    )}
                                    {this.props.containerConfig && this.props.containerConfig.enablePromoteAction == true && pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Manage Action Promotions" && op.allow == true) && (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={this.handleManageActionPromotions}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.interventionLoading || !(this.props.numberActiveActionPromotions && this.props.numberActiveActionPromotions > 0)}
                                            >
                                                {this.props.numberActiveActionPromotions && this.props.numberActiveActionPromotions > 0 ? `Promotion Requests (${this.props.numberActiveActionPromotions})` : "Promotion Requests"}
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </div>
                        <SkillsTxTable
                            columns={columns}
                            data={interventions}
                            pagination={true}
                            searchKeyword={searchKeyword}
                            searchProperties={searchProperties}
                            columnSearchKeywords={columnSearchKeywords}
                            selectableRows={true}
                            selectableRowsHighlight={true}
                            handleRowClick={this.handleRowClick}
                            progressPending={this.props.interventionLoading}
                            headCellsStyle={{
                                style: {
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    border: "1px solid #dee2e6",
                                    whiteSpace: "wrap",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                    paddingRight: "8px",
                                    paddingLeft: "8px",
                                },
                            }}
                        ></SkillsTxTable>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,

        interventionLoading: state.adminIntervention.loading,
        interventionError: state.adminIntervention.error,
        interventions: state.adminIntervention.interventions,
        isUpdating: state.adminIntervention.isUpdating,
        actionTypes: state.actionPlan.actionTypes,

        numberActiveActionPromotions: state.adminIntervention.numberActiveActionPromotions,

        companyLoading: state.company.loading,
        companyError: state.company.error,
        providers: state.company.providers,

        categories: state.category.categories,
        categoryLoading: state.category.loading,

        lorLoading: state.lor.loading,
        lors: state.lor.lors,

        exportData: state.adminIntervention.exportData,
        loadingExport: state.adminIntervention.loadingExport,
        pageAssignments: state.adminPerson.pageAssignments,

        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onIntervention: bindActionCreators(interventionActions, dispatch),
        onActionPlan: bindActionCreators(actionPlanActions, dispatch),
        onCategory: bindActionCreators(categoryActions, dispatch),
        onCompany: bindActionCreators(companyActions, dispatch),
        onLor: bindActionCreators(lorActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageTrainings));
