import * as authActions from "../../store/actions/authAction";
import ContentTranslator from "../translator/ContentTranslator";
import TableProfileResponsibility from "./TableProfileResponsibility";
import TableSkillProfiles from "./TableSkillProfiles";
import React from "react";
import { connect } from "react-redux";
import { Container, Table, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

class TableProfile extends React.Component {
    static displayName = TableProfile.name;

    constructor(props) {
        super(props);
        this.state = {
            lorProfiles: this.props.lorProfiles,
            skillProfiles: this.props.skillProfiles,
            lors: this.props.lors,
            lorLoading: this.props.lorLoading,
            isTopSkillProfileLegendInViewport: true,
        };
    }

    componentDidMount() {
        document.addEventListener("scroll", this.checkSkillProfileLegendInViewport);
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.checkSkillProfileLegendInViewport);
    }

    checkSkillProfileLegendInViewport = () => {
        let isTopSkillProfileLegendInViewport = this.state.isTopSkillProfileLegendInViewport;

        //get how much pixels left to scrolling our ReactElement
        const topLegendTop = this.topSkillProfileLegendElement.getBoundingClientRect().top;
        isTopSkillProfileLegendInViewport = topLegendTop <= 0 ? false : true;
        this.setState({ isTopSkillProfileLegendInViewport });

        if (this.props.updateFloatingLegendStatus) {
            this.props.updateFloatingLegendStatus(!isTopSkillProfileLegendInViewport ? true : false);
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.lorLoading !== this.props.lorLoading) {
            this.setState({ lorLoading: this.props.lorLoading });
        }

        if (prevProps.lors !== this.props.lors) {
            this.setState({ lors: this.props.lors });
        }

        if (prevProps.lorProfiles !== this.props.lorProfiles) {
            this.setState({ lorProfiles: this.props.lorProfiles });
        }

        if (prevProps.skillProfiles !== this.props.skillProfiles) {
            this.setState({ skillProfiles: this.props.skillProfiles });
        }
    }

    render() {
        let setRefTopLegend = (el) => {
            this.topSkillProfileLegendElement = el;
        };

        const { selectedJobMatchType, extraFrameworkCapabilities, extraFrameworkLevels, jobSkillProfiles, categories, account, badgeCertification, badgeRequest } = this.props;
        const { skillProfiles } = this.state;
        const extraFrameworkAvailable = this.props.categories.filter((it) => it.isExtraFramework && it.isExtraFramework == true).length > 0 ? true : false;

        let showProposedActionExtraFramework = false;
        if (this.props.showProposedAction && extraFrameworkAvailable && extraFrameworkCapabilities) {
            const proposeSkills = account && account.updateProfileEvidenceProposalSkills ? account.updateProfileEvidenceProposalSkills.filter((it) => it.type == "Skill") : [];

            categories
                .filter((it) => it.isExtraFramework && it.isExtraFramework == true)
                .forEach((category) => {
                    const categoryFound = proposeSkills.filter((x) => x.type == "Skill" && x.category == category.name).length > 0 ? true : false;

                    if (categoryFound) {
                        let categoryHasValue = false;
                        if (category && category.subCategories && category.subCategories.length > 0) {
                            category.subCategories.forEach((subCategory) => {
                                if (subCategory.skills && subCategory.skills.length > 0) {
                                    subCategory.skills.forEach((skill) => {
                                        const extraFrameworkSkillProfiles = skillProfiles.filter((x) => x.category == category.name && x.subCategory == subCategory.name && x.skill == skill.name && x.skillCode == skill.skillsCode && (x.stringVal !== "N" || x.editedStringVal));
                                        const extraFrameworkProposeSkills = proposeSkills
                                            .filter((x) => x.type == "Skill" && x.category == category.name && x.subCategory == subCategory.name && x.name == skill.name && x.code == skill.skillsCode)
                                            .map((it) => {
                                                return {
                                                    capability: it.capability,
                                                    numericVal: it.capability == "Competent" ? 1 : it.capability == "Proficient" ? 0.5 : it.capability == "Knowledge" ? 0.25 : 0,
                                                    category: it.category,
                                                    code: it.code,
                                                    id: it.id,
                                                    level: it.level,
                                                    name: it.name,
                                                    subCategory: it.subCategory,
                                                    type: it.type,
                                                };
                                            });

                                        if (skill.levelSelection == "Single") {
                                            if (extraFrameworkSkillProfiles.length == 0 && extraFrameworkProposeSkills.length > 0) {
                                                // No profile skills but have proposed skills
                                                categoryHasValue = true;
                                            } else if (extraFrameworkSkillProfiles.filter((x) => extraFrameworkProposeSkills.some((y) => x.skillCode == y.code && (y.level > x.level || (x.level == y.level && y.numericVal > x.numericVal)))).length > 0) {
                                                // Have proposed skills with better capability
                                                categoryHasValue = true;
                                            } else if (extraFrameworkProposeSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.code == y.skillCode)).length > 0) {
                                                // Have proposed skills which not exist in profile skills
                                                categoryHasValue = true;
                                            }
                                        } else {
                                            if (extraFrameworkSkillProfiles.length == 0 && extraFrameworkProposeSkills.length > 0) {
                                                // No profile skills but have proposed skills
                                                categoryHasValue = true;
                                            } else if (extraFrameworkSkillProfiles.filter((x) => extraFrameworkProposeSkills.some((y) => x.skillCode == y.code && x.level == y.level && y.numericVal > x.numericVal)).length > 0) {
                                                // Have proposed skills with better capability
                                                categoryHasValue = true;
                                            } else if (extraFrameworkProposeSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.code == y.skillCode && x.level == y.level)).length > 0) {
                                                // Have proposed skills which not exist in profile skills
                                                categoryHasValue = true;
                                            }
                                        }
                                    });
                                }
                            });
                        }

                        if (categoryHasValue) {
                            showProposedActionExtraFramework = true;
                        }
                    }
                });
        }

        let showSuggestedUpdateExtraFramework = false;
        if (this.props.showSuggestedUpdate && extraFrameworkAvailable && extraFrameworkCapabilities) {
            const suggestedSkills = account && account.updateProfileSuggestionSkills ? account.updateProfileSuggestionSkills : [];

            categories
                .filter((it) => it.isExtraFramework && it.isExtraFramework == true)
                .forEach((category) => {
                    const categoryFound = suggestedSkills.filter((x) => x.category == category.name).length > 0 ? true : false;

                    if (categoryFound) {
                        let categoryHasValue = false;
                        if (category && category.subCategories && category.subCategories.length > 0) {
                            category.subCategories.forEach((subCategory) => {
                                if (subCategory.skills && subCategory.skills.length > 0) {
                                    subCategory.skills.forEach((skill) => {
                                        const extraFrameworkSkillProfiles = skillProfiles.filter((x) => x.category == category.name && x.subCategory == subCategory.name && x.skill == skill.name && x.skillCode == skill.skillsCode && (x.stringVal !== "N" || x.editedStringVal));
                                        const extraFrameworkSuggestedSkills = suggestedSkills
                                            .filter((x) => x.category == category.name && x.subCategory == subCategory.name && x.skill == skill.name && x.skillCode == skill.skillsCode)
                                            .map((it) => {
                                                return {
                                                    numericVal: it.numericVal,
                                                    category: it.category,
                                                    subCategory: it.subCategory,
                                                    id: it.id,
                                                    skill: it.skill,
                                                    skillCode: it.skillCode,
                                                    level: it.level,
                                                };
                                            });

                                        if (skill.levelSelection == "Single") {
                                            if (extraFrameworkSkillProfiles.length == 0 && extraFrameworkSuggestedSkills.length > 0) {
                                                // No profile skills but have proposed skills
                                                categoryHasValue = true;
                                            } else if (extraFrameworkSkillProfiles.filter((x) => extraFrameworkSuggestedSkills.some((y) => x.skillCode == y.skillCode && (y.level > x.level || (x.level == y.level && y.numericVal > x.numericVal)))).length > 0) {
                                                // Have proposed skills with better capability
                                                categoryHasValue = true;
                                            } else if (extraFrameworkSuggestedSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.skillCode == y.skillCode)).length > 0) {
                                                // Have proposed skills which not exist in profile skills
                                                categoryHasValue = true;
                                            }
                                        } else {
                                            if (extraFrameworkSkillProfiles.length == 0 && extraFrameworkSuggestedSkills.length > 0) {
                                                // No profile skills but have proposed skills
                                                categoryHasValue = true;
                                            } else if (extraFrameworkSkillProfiles.filter((x) => extraFrameworkSuggestedSkills.some((y) => x.skillCode == y.skillCode && x.level == y.level && y.numericVal > x.numericVal)).length > 0) {
                                                // Have proposed skills with better capability
                                                categoryHasValue = true;
                                            } else if (extraFrameworkSuggestedSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.skillCode == y.skillCode && x.level == y.level)).length > 0) {
                                                // Have proposed skills which not exist in profile skills
                                                categoryHasValue = true;
                                            }
                                        }
                                    });
                                }
                            });
                        }

                        if (categoryHasValue) {
                            showSuggestedUpdateExtraFramework = true;
                        }
                    }
                });
        }

        const extraFrameworkSkills = categories
            ? categories
                  .filter((it) => it.isExtraFramework == true)
                  .flatMap((category, index) => {
                      return category.subCategories.flatMap((subCategory, index) => {
                          return subCategory.skills.flatMap((skill, index) => {
                              return { skillsCode: skill.skillsCode, isHidden: skill.isHidden };
                          });
                      });
                  })
            : [];

        let extraFrameworkSkillProfilesCodeWithValue = [];
        if (!badgeCertification) {
            extraFrameworkSkillProfilesCodeWithValue = skillProfiles.filter((it) => it.isExtraFramework && it.isExtraFramework == true && (it.stringVal !== "N" || it.editedStringVal)).map((skill) => skill.skillCode);
        } else if (badgeCertification && badgeRequest && badgeRequest.requestCertificationEntries && badgeRequest.requestCertificationEntries.length > 0) {
            extraFrameworkSkillProfilesCodeWithValue = badgeRequest.requestCertificationEntries.map((skill) => skill.skillsCode);
        } else if (badgeCertification && badgeRequest && badgeRequest.requestEntries && badgeRequest.requestEntries.length > 0) {
            extraFrameworkSkillProfilesCodeWithValue = badgeRequest.requestEntries.map((skill) => skill.skillsCode);
        }

        const checkedExtraFrameworkSkills = this.props.showDetail || this.props.showEdit ? extraFrameworkSkills : extraFrameworkSkills.filter((it) => extraFrameworkSkillProfilesCodeWithValue.includes(it.skillsCode));
        const extraFrameworkHidden = !checkedExtraFrameworkSkills.some((skill) => !skill.isHidden) && !this.props.showHidden;

        return (
            <Container className="p-0 mt-4" style={{ fontSize: "11px" }}>
                {!this.props.badgeCertification && this.props.selectedprofileType && !this.props.showSuggestedUpdate && (
                    <TableProfileResponsibility
                        isShowJobProfile={this.props.isShowJobProfile}
                        loading={this.state.lorLoading}
                        lors={this.state.lors}
                        categories={this.props.categories}
                        lorProfiles={this.state.lorProfiles}
                        skillProfiles={this.state.skillProfiles}
                        showEditSkill={this.props.showEdit}
                        showProposedAction={this.props.showProposedAction}
                        handleEditProfileSkill={this.props.handleEditProfileSkill}
                        selectedJobMatchType={this.props.selectedJobMatchType}
                        selectedprofileType={this.props.selectedprofileType}
                        loadingGap={this.props.loadingGap}
                        highlightGap={this.props.highlightGap}
                        jobGaps={this.props.jobGaps}
                        jobLorProfiles={this.props.jobLorProfiles}
                        jobSkillProfiles={this.props.jobSkillProfiles}
                        handleActionPlanAdded={this.props.handleActionPlanAdded}
                        handleEvidenceAdded={this.props.handleEvidenceAdded}
                    ></TableProfileResponsibility>
                )}
                <Table>
                    <tbody ref={setRefTopLegend}>
                        <tr>
                            <td className="pl-0 pb-1" style={{ border: "0px", fontFamily: "IBMPlexSans-Bold" }} colSpan="5">
                                <ContentTranslator page="SfiaProfile" name="SkillProfileLegendKey" contentText="Skills Profile Key" />
                            </td>
                            <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                <span id="profileLegendNotSelected">
                                    <ContentTranslator page="SfiaProfile" name="SkillProfileLegendNotSelected" contentText="Not Selected" />
                                </span>
                                <UncontrolledTooltip target={`profileLegendNotSelected`} placement="bottom">
                                    <ContentTranslator page="SfiaProfile" name="SkillProfileLegendNotSelectedHover" contentText="This indicates no capability was selected for this skill" />
                                </UncontrolledTooltip>
                            </td>
                            {this.props.isShowJobProfile ? (
                                <React.Fragment>
                                    <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                        <ContentTranslator page="SfiaAll" name="JobRequired" contentText="Required" />
                                    </td>
                                    <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                        <ContentTranslator page="SfiaAll" name="JobDesirable" contentText="Desirable" />
                                    </td>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                        <span id="profileLegendKnowledge">
                                            <ContentTranslator page="SfiaProfile" name="SkillProfileLegendKnowledge" contentText="Knowledge" />
                                        </span>
                                        <UncontrolledTooltip target={`profileLegendKnowledge`} placement="bottom">
                                            <ContentTranslator
                                                page="SfiaProfile"
                                                name="SkillProfileKeyKnowledgeHover"
                                                contentText="This indicates relevant KNOWLEDGE to explain how this would be applied in a working environment but have not yet had the opportunity to develop full proficiency or competency. Or it can indicate previous experience of applying this skill, but it is no longer current."
                                            />
                                        </UncontrolledTooltip>
                                    </td>
                                    <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                        <span id="profileLegendProficient">
                                            <ContentTranslator page="SfiaProfile" name="SkillProfileLegendProficient" contentText="Proficient" />
                                        </span>
                                        <UncontrolledTooltip target={`profileLegendProficient`} placement="bottom">
                                            <ContentTranslator
                                                page="SfiaProfile"
                                                name="SkillProfileKeyProficientHover"
                                                contentText="This indicates that 50% to 85% of the activities described have been recently and regularly performed, with consistently successful results. However, there is still room for improvement."
                                            />
                                        </UncontrolledTooltip>
                                    </td>
                                    <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                        <span id="profileLegendCompetent">
                                            <ContentTranslator page="SfiaProfile" name="SkillProfileLegendCompetent" contentText="Competent" />
                                        </span>
                                        <UncontrolledTooltip target={`profileLegendCompetent`} placement="bottom">
                                            <ContentTranslator
                                                page="SfiaProfile"
                                                name="SkillProfileKeyCompetentHover"
                                                contentText="This indicates significant professional experience of performing at least 85% of the activities described and is considered a current and fully developed COMPETENCY with no further development required."
                                            />
                                        </UncontrolledTooltip>
                                    </td>
                                    {selectedJobMatchType && selectedJobMatchType.value != "None" && (
                                        <React.Fragment>
                                            <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                                <ContentTranslator page="SfiaAll" name="JobRequired" contentText="Required" />
                                            </td>
                                            <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                                <ContentTranslator page="SfiaAll" name="JobDesirable" contentText="Desirable" />
                                            </td>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </tr>
                        <tr>
                            <td colSpan="5" style={{ background: "#F5F5F5", border: "0px" }}></td>
                            <td id="tdProfileLegendNotSelected" style={{ background: "#E6E6E6", border: "0px" }}></td>
                            <UncontrolledTooltip target={`tdProfileLegendNotSelected`} placement="bottom">
                                <ContentTranslator page="SfiaProfile" name="SkillProfileLegendNotSelectedHover" contentText="This indicates no capability was selected for this skill" />
                            </UncontrolledTooltip>
                            {this.props.isShowJobProfile ? (
                                <React.Fragment>
                                    <td style={{ background: "#AAACAC", border: "0px" }}></td>
                                    <td className="col-legend-desirable border-0"></td>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <td id="tdProfileLegendKnowledge" className="col-legend-previous border-0"></td>
                                    <UncontrolledTooltip target={`tdProfileLegendKnowledge`} placement="bottom">
                                        <ContentTranslator
                                            page="SfiaProfile"
                                            name="SkillProfileKeyKnowledgeHover"
                                            contentText="This indicates relevant KNOWLEDGE to explain how this would be applied in a working environment but have not yet had the opportunity to develop full proficiency or competency. Or it can indicate previous experience of applying this skill, but it is no longer current."
                                        />
                                    </UncontrolledTooltip>
                                    <td id="tdProfileLegendProficient" className="col-legend-largely border-0"></td>
                                    <UncontrolledTooltip target={`tdProfileLegendProficient`} placement="bottom">
                                        <ContentTranslator
                                            page="SfiaProfile"
                                            name="SkillProfileKeyProficientHover"
                                            contentText="This indicates that 50% to 85% of the activities described have been recently and regularly performed, with consistently successful results. However, there is still room for improvement."
                                        />
                                    </UncontrolledTooltip>
                                    <td id="tdProfileLegendCompetent" style={{ background: "#AAACAC", border: "0px" }}></td>
                                    <UncontrolledTooltip target={`tdProfileLegendCompetent`} placement="bottom">
                                        <ContentTranslator
                                            page="SfiaProfile"
                                            name="SkillProfileKeyCompetentHover"
                                            contentText="This indicates significant professional experience of performing at least 85% of the activities described and is considered a current and fully developed COMPETENCY with no further development required."
                                        />
                                    </UncontrolledTooltip>
                                    {selectedJobMatchType && selectedJobMatchType.value == "Current" && (
                                        <React.Fragment>
                                            <td style={{ background: "#488ac7", border: "0px" }}></td>
                                            <td className="col-legend-desirable-current border-0"></td>
                                        </React.Fragment>
                                    )}
                                    {selectedJobMatchType && selectedJobMatchType.value == "Future" && (
                                        <React.Fragment>
                                            <td style={{ background: "#6cbb3c", border: "0px" }}></td>
                                            <td className="col-legend-desirable-future border-0"></td>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </tr>
                    </tbody>
                </Table>
                {this.props.selectedprofileType && (
                    <TableSkillProfiles
                        isShowJobProfile={this.props.isShowJobProfile}
                        showAllSkill={this.props.showDetail}
                        showEditSkill={this.props.showEdit}
                        showHiddenSkill={this.props.showHidden}
                        showProposedAction={this.props.showProposedAction}
                        showSuggestedUpdate={this.props.showSuggestedUpdate}
                        loading={this.props.skillLoading}
                        categories={this.props.categories}
                        lors={this.props.lors}
                        extraFrameworkCapabilities={extraFrameworkCapabilities}
                        extraFrameworkLevels={extraFrameworkLevels}
                        selectedJobMatchType={this.props.selectedJobMatchType}
                        selectedprofileType={this.props.selectedprofileType}
                        loadingGap={this.props.loadingGap}
                        highlightGap={this.props.highlightGap}
                        jobLorProfiles={this.props.jobLorProfiles}
                        jobSkillProfiles={this.props.jobSkillProfiles}
                        jobSkillAttributes={this.props.jobSkillAttributes}
                        lorProfiles={this.state.lorProfiles}
                        skillProfiles={this.state.skillProfiles}
                        handleEditProfileSkills={this.props.handleEditProfileSkills}
                        badgeCertification={this.props.badgeCertification}
                        badgeRequest={this.props.badgeRequest}
                        maxNumberOfBadgeRequestSkill={this.props.maxNumberOfBadgeRequestSkill}
                        badgeRequestUpdated={this.props.badgeRequestUpdated}
                        showEditCertify={this.props.showEditCertify}
                        handleSuggestEditLowerLevelProfile={this.props.handleSuggestEditLowerLevelProfile}
                        handleManagerSuggestEditLowerLevelProfile={this.props.handleManagerSuggestEditLowerLevelProfile}
                        handleActionPlanAdded={this.props.handleActionPlanAdded}
                        handleEvidenceAdded={this.props.handleEvidenceAdded}
                    ></TableSkillProfiles>
                )}
                {this.props.selectedprofileType &&
                    (!this.props.showProposedAction || showProposedActionExtraFramework) &&
                    (!this.props.showSuggestedUpdate || showSuggestedUpdateExtraFramework) &&
                    extraFrameworkAvailable &&
                    extraFrameworkCapabilities &&
                    extraFrameworkCapabilities.length > 0 &&
                    ((skillProfiles && skillProfiles.filter((it) => it.isExtraFramework && it.isExtraFramework == true && it.numericVal > 0).length > 0) ||
                        (jobSkillProfiles && jobSkillProfiles.filter((it) => it.isExtraFramework && it.isExtraFramework == true && it.stringVal != "N").length > 0) ||
                        this.props.showDetail ||
                        this.props.showEdit) && (
                        <React.Fragment>
                            <Table style={{ display: !extraFrameworkHidden ? "" : "none" }}>
                                <tbody>
                                    <tr>
                                        <td className="pl-0 pb-1" style={{ border: "0px", fontFamily: "IBMPlexSans-Bold" }} colSpan={5 + 4 - extraFrameworkCapabilities.length}>
                                            <ContentTranslator page="SfiaProfile" name="SkillProfileLegendKey" contentText="Skills Profile Key" />
                                        </td>
                                        {extraFrameworkCapabilities.map((extraFrameworkCapability, index) => {
                                            return (
                                                <td key={index} className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                                    {extraFrameworkCapability.capability}
                                                </td>
                                            );
                                        })}
                                        {selectedJobMatchType && selectedJobMatchType.value != "None" && (
                                            <React.Fragment>
                                                <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                                    <ContentTranslator page="SfiaAll" name="JobRequired" contentText="Required" />
                                                </td>
                                                <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                                    <ContentTranslator page="SfiaAll" name="JobDesirable" contentText="Desirable" />
                                                </td>
                                            </React.Fragment>
                                        )}
                                    </tr>
                                    <tr>
                                        <td colSpan={5 + 4 - extraFrameworkCapabilities.length} style={{ background: "#F5F5F5", border: "0px" }}></td>
                                        {extraFrameworkCapabilities.map((extraFrameworkCapability, index) => {
                                            return index == 0 ? (
                                                <td key={index} style={{ background: "#E6E6E6", border: "0px" }}></td>
                                            ) : index == 1 ? (
                                                <td key={index} style={{ background: "#AAACAC", border: "0px" }}></td>
                                            ) : index == 2 ? (
                                                <td key={index} className="col-legend-largely border-0"></td>
                                            ) : index == 3 ? (
                                                <td key={index} className="col-legend-previous border-0"></td>
                                            ) : (
                                                ""
                                            );
                                        })}
                                        {selectedJobMatchType && selectedJobMatchType.value == "Current" && (
                                            <React.Fragment>
                                                <td style={{ background: "#488ac7", border: "0px" }}></td>
                                                <td className="col-legend-desirable-current border-0"></td>
                                            </React.Fragment>
                                        )}
                                        {selectedJobMatchType && selectedJobMatchType.value == "Future" && (
                                            <React.Fragment>
                                                <td style={{ background: "#6cbb3c", border: "0px" }}></td>
                                                <td className="col-legend-desirable-future border-0"></td>
                                            </React.Fragment>
                                        )}
                                    </tr>
                                </tbody>
                            </Table>
                            <TableSkillProfiles
                                showExtraFramework={true}
                                isShowJobProfile={this.props.isShowJobProfile}
                                showAllSkill={this.props.showDetail}
                                showEditSkill={this.props.showEdit}
                                showHiddenSkill={this.props.showHidden}
                                showProposedAction={this.props.showProposedAction}
                                showSuggestedUpdate={this.props.showSuggestedUpdate}
                                loading={this.props.skillLoading}
                                categories={this.props.categories}
                                lors={this.props.lors}
                                extraFrameworkCapabilities={extraFrameworkCapabilities}
                                extraFrameworkLevels={extraFrameworkLevels}
                                selectedJobMatchType={this.props.selectedJobMatchType}
                                selectedprofileType={this.props.selectedprofileType}
                                loadingGap={this.props.loadingGap}
                                highlightGap={this.props.highlightGap}
                                jobLorProfiles={this.props.jobLorProfiles}
                                jobSkillProfiles={this.props.jobSkillProfiles}
                                jobSkillAttributes={this.props.jobSkillAttributes}
                                lorProfiles={this.state.lorProfiles}
                                skillProfiles={this.state.skillProfiles}
                                handleEditProfileSkills={this.props.handleEditProfileSkills}
                                badgeCertification={this.props.badgeCertification}
                                badgeRequest={this.props.badgeRequest}
                                maxNumberOfBadgeRequestSkill={this.props.maxNumberOfBadgeRequestSkill}
                                badgeRequestUpdated={this.props.badgeRequestUpdated}
                                showEditCertify={this.props.showEditCertify}
                                handleSuggestEditLowerLevelProfile={this.props.handleSuggestEditLowerLevelProfile}
                                handleManagerSuggestEditLowerLevelProfile={this.props.handleManagerSuggestEditLowerLevelProfile}
                                handleActionPlanAdded={this.props.handleActionPlanAdded}
                                handleEvidenceAdded={this.props.handleEvidenceAdded}
                            ></TableSkillProfiles>
                        </React.Fragment>
                    )}
                <div style={{ marginBottom: "30px" }}>&nbsp;</div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contentTranslations: state.content.contentTranslations,
        account: state.person.account,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableProfile);
