import * as personActions from "../../store/actions/personAction";
import { dismisAlert, generateAlert } from "../../utils/alertUtils";
import { contentTranslator } from "../../utils/translatorUtils";
import FormAddAction from "../actionplan/FormAddAction";
import AddNewEvidenceForm from "../evidence/AddNewEvidenceForm";
import ContentTranslator from "../translator/ContentTranslator";
import SkillProfileGapHighlight from "./SkillProfileGapHighlight";
import TableSkillProfilesEditAttribute from "./TableSkillProfilesEditAtrribute";
import TableSkillProfilesEditComment from "./TableSkillProfilesEditComment";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle, faCircle, faComment, faEdit, faList, faPlusCircle, faTimesCircle, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Button, Col, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
function truncate(input) {
    // if (input.length > 400) {
    //     return input.substring(0, 400) + '...';
    // }
    return input;
}

class TableSkillProfiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lorProfiles: this.props.lorProfiles,
            skillProfiles: this.props.skillProfiles,
            loading: false,
            categories: this.props.categories,
            extraFrameworkCapabilities: this.props.extraFrameworkCapabilities,
            extraFrameworkLevels: this.props.extraFrameworkLevels,

            editSkill: false,
            editedCategory: null,
            editedSubCategory: null,
            editedSkill: null,
            editedSkillProfiles: null,
            skillValueOptions: [],
            account: null,
            editAttribute: false,
            alerts: [],
            editComment: false,
            skillLevelDescription: null,
            allowEditComment: false,
            badgeRequest: this.props.badgeRequest,
            openSuggestEditLowerLevelProfile: false,
            openAddActionDialog: false,
            openAddEvidenceDialog: false,
            jobLorProfiles: this.props.jobLorProfiles ? [...this.props.jobLorProfiles] : [],
            jobSkillProfiles: this.props.jobSkillProfiles ? [...this.props.jobSkillProfiles] : [],
        };

        this.openEditSkillProfile = this.openEditSkillProfile.bind(this);
        this.togleEditSkillProfile = this.togleEditSkillProfile.bind(this);
        this.handleChangeSkillLevel = this.handleChangeSkillLevel.bind(this);
        this.handleSaveEditProfileClick = this.handleSaveEditProfileClick.bind(this);

        this.openEditSkillAttribute = this.openEditSkillAttribute.bind(this);
        this.togleEditSkillAttribute = this.togleEditSkillAttribute.bind(this);

        this.initData = this.initData.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        const skillValueOptions = [
            {
                value: "N",
                label: this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "EditSkillLevelNone", "None"),
                numericVal: 0,
                labelVal: "My skills (Current and Past) are NOT a good match (<50%) for this description",
            },
            {
                value: "NA",
                label: this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "EditSkillLevelKnowledge", "Knowledge"), //"Previous",
                numericVal: 0.25,
                labelVal: "My CURRENT skills are NOT a good match to this description. <br>HOWEVER, it is a good match for skills from earlier in my career",
            },
            {
                value: "P",
                label: this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "EditSkillLevelProficient", "Proficient"), //"Largely",
                numericVal: 0.5,
                labelVal: "I have LARGELY (50%-85% match) 'performed' the skill as described above<br>And I consider it a current and practiced skill",
            },
            {
                value: "M",
                label: this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "EditSkillLevelCompetent", "Competent"), //"Fully",
                numericVal: 1,
                labelVal: "I have FULLY (>85% match) 'mastered' the skill as described above<br>And I consider it a current and practiced skill",
            },
        ];

        this.setState({ skillValueOptions });

        this.initData();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    initData() {
        Promise.all([this.props.managedPeople ? this.props.onPerson.getManagedPeopleLatestAccount(this.props.managedPeople.personB.email) : this.props.onPerson.getPersonLatestAccount()]).then(() => {
            if (!this.props.personLoading && this.props.account) {
                const account = this.props.account;
                this.setState({ account });
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading !== this.props.loading) {
            this.setState({ loading: this.props.loading });
        }

        if (prevProps.badgeRequest !== this.props.badgeRequest) {
            this.setState({ badgeRequest: this.props.badgeRequest });
        }

        if (prevProps.categories !== this.props.categories) {
            this.setState({ categories: this.props.categories });
        }

        if (prevProps.lorProfiles !== this.props.lorProfiles) {
            this.setState({ lorProfiles: this.props.lorProfiles });
        }

        if (prevProps.skillProfiles !== this.props.skillProfiles) {
            this.setState({ skillProfiles: this.props.skillProfiles });
        }

        if (prevProps.jobLorProfiles !== this.props.jobLorProfiles) {
            this.setState({ jobLorProfiles: this.props.jobLorProfiles ? [...this.props.jobLorProfiles] : [] });
        }

        if (prevProps.jobSkillProfiles !== this.props.jobSkillProfiles) {
            this.setState({ jobSkillProfiles: this.props.jobSkillProfiles ? [...this.props.jobSkillProfiles] : [] });
        }
    }

    openEditSkillProfile(editedCategory, editedSubCategory, editedSkill, editedSkillProfilesSubmit, isExtraFramework) {
        const { skillValueOptions } = this.state;
        const { extraFrameworkCapabilities } = this.props;

        let lowerOption = null;
        if (isExtraFramework) {
            const leastOption = extraFrameworkCapabilities ? [...extraFrameworkCapabilities].sort((a, b) => a.weight - b.weight)[0] : null;
            lowerOption = {
                numericVal: leastOption.weight,
                stringVal: leastOption.capability,
                labelVal: null,
                isExtraFramework,
            };
        } else {
            const leastOption = skillValueOptions ? [...skillValueOptions].sort((a, b) => a.numericVal - b.numericVal)[0] : null;
            lowerOption = {
                numericVal: leastOption.numericVal,
                stringVal: leastOption.value,
                labelVal: leastOption.labelVal,
                isExtraFramework,
            };
        }

        let editedSkillProfiles = editedSkillProfilesSubmit ? JSON.parse(JSON.stringify(editedSkillProfilesSubmit)) : [];

        if (editedSkillProfiles && editedSkill && editedSkill.levelSelection && editedSkill.levelSelection == "Single") {
            let singleLevelSelectionValue = false;
            editedSkillProfiles
                .sort((a, b) => a.level - b.level)
                .map((editedSkillProfile) => {
                    if (!singleLevelSelectionValue && ((editedSkillProfile.numericVal && editedSkillProfile.numericVal > 0) || (editedSkillProfile.editedNumericVal && editedSkillProfile.editedNumericVal > 0))) {
                        editedSkillProfile.singleLevelSelectionChecked = true;
                        singleLevelSelectionValue = true;
                    } else {
                        editedSkillProfile.singleLevelSelectionChecked = false;
                        editedSkillProfile.editedStringVal = lowerOption.stringVal;
                        editedSkillProfile.edited = true;
                        editedSkillProfile.source = "skillProfiles";

                        editedSkillProfile.editedNumericVal = lowerOption.numericVal;
                        editedSkillProfile.editedLabelVal = lowerOption.labelVal;
                    }
                });
        }

        this.setState({ editSkill: true, editedCategory, editedSubCategory, editedSkill, editedSkillProfiles, editExtraFrameworkSkill: isExtraFramework });
    }

    handleRadioChange = () => {};

    handleRadioSingleSelectionClick = (levelNumber) => {
        const { editedCategory, editedSubCategory, editedSkill, skillValueOptions, editExtraFrameworkSkill } = this.state;
        const { extraFrameworkCapabilities } = this.props;

        let lowerOption = null;
        if (editExtraFrameworkSkill) {
            const leastOption = extraFrameworkCapabilities ? [...extraFrameworkCapabilities].sort((a, b) => a.weight - b.weight)[0] : null;
            lowerOption = {
                numericVal: leastOption.weight,
                stringVal: leastOption.capability,
                labelVal: null,
                isExtraFramework: editExtraFrameworkSkill,
            };
        } else {
            const leastOption = skillValueOptions ? [...skillValueOptions].sort((a, b) => a.numericVal - b.numericVal)[0] : null;
            lowerOption = {
                numericVal: leastOption.numericVal,
                stringVal: leastOption.value,
                labelVal: leastOption.labelVal,
                isExtraFramework: editExtraFrameworkSkill,
            };
        }

        let editedSkillProfiles = this.state.editedSkillProfiles;

        if (editedSkillProfiles) {
            editedSkillProfiles.map((editedSkillProfile) => {
                editedSkillProfile.singleLevelSelectionChecked = false;
                if (editedSkillProfile.level != levelNumber) {
                    editedSkillProfile.editedStringVal = lowerOption.stringVal;
                    editedSkillProfile.editedLabelVal = lowerOption.labelVal;
                    editedSkillProfile.editedNumericVal = lowerOption.numericVal;
                }
            });

            if (editedSkillProfiles.find((it) => it.level == levelNumber)) {
                let editedSkillProfile = editedSkillProfiles.find((it) => it.level == levelNumber);
                editedSkillProfile.singleLevelSelectionChecked = true;
            } else {
                var level = editedSkill.levels.find((it) => it.level == levelNumber);
                editedSkillProfiles.push({
                    category: editedCategory.name,
                    subCategory: editedSubCategory.name,
                    skill: editedSkill.name,
                    skillCode: editedSkill.skillsCode,
                    level: levelNumber,
                    editedStringVal: lowerOption.stringVal,
                    editedLabelVal: lowerOption.labelVal,
                    editedNumericVal: lowerOption.numericVal,
                    isExtraFramework: level && level.extraFrameworkLevelId ? true : false,
                    extraFrameworkLevel: level && level.extraFrameworkLevelId ? level.extraFrameworkLevel : null,
                    extraFrameworkLevelId: level && level.extraFrameworkLevelId ? level.extraFrameworkLevelId : null,
                    singleLevelSelectionChecked: true,
                });
            }
        }

        this.setState({ editedSkillProfiles: [...editedSkillProfiles] });
    };

    togleEditSkillProfile() {
        const badgeCertification = this.props.badgeCertification;
        const { editSkill } = this.state;
        this.setState({ editSkill: !editSkill });

        if (badgeCertification) {
            let badgeRequest = this.state.badgeRequest;
            let requestCertificationEntries = badgeRequest.requestCertificationEntries ? [...badgeRequest.requestCertificationEntries] : [];

            requestCertificationEntries.forEach((requestCertificationEntry) => {
                requestCertificationEntry.storedStringVal = null;
                requestCertificationEntry.storedNumericVal = null;
                requestCertificationEntry.storedLabelVal = null;
            });

            this.setState({ badgeRequest });
        }
    }

    handleSaveEditProfileClick() {
        let { skillProfiles, editedSkillProfiles, skillValueOptions, editExtraFrameworkSkill } = this.state;
        const { extraFrameworkCapabilities } = this.props;

        let lowerOption = null;
        if (editExtraFrameworkSkill) {
            const leastOption = extraFrameworkCapabilities ? [...extraFrameworkCapabilities].sort((a, b) => a.weight - b.weight)[0] : null;
            lowerOption = {
                numericVal: leastOption.weight,
                stringVal: leastOption.capability,
                labelVal: null,
                isExtraFramework: editExtraFrameworkSkill,
            };
        } else {
            const leastOption = skillValueOptions ? [...skillValueOptions].sort((a, b) => a.numericVal - b.numericVal)[0] : null;
            lowerOption = {
                numericVal: leastOption.numericVal,
                stringVal: leastOption.value,
                labelVal: leastOption.labelVal,
                isExtraFramework: editExtraFrameworkSkill,
            };
        }

        const badgeCertification = this.props.badgeCertification;
        let editedProfiles = [];

        if (!badgeCertification && editedSkillProfiles.some((it) => it.editedStringVal && it.editedStringVal != it.stringVal)) {
            editedSkillProfiles
                .filter((editedSkillProfile) => editedSkillProfile.editedStringVal && editedSkillProfile.editedStringVal != editedSkillProfile.stringVal)
                .forEach((editedSkillProfile) => {
                    if (editedSkillProfile.id) {
                        let skillProfile = skillProfiles.find((skillProfile) => skillProfile.id == editedSkillProfile.id);
                        if (skillProfile) {
                            skillProfile.editedStringVal = editedSkillProfile && (editedSkillProfile.singleLevelSelectionChecked || editedSkillProfile.singleLevelSelectionChecked == null) ? editedSkillProfile.editedStringVal : lowerOption.stringVal;
                            skillProfile.edited = true;
                            skillProfile.source = "skillProfiles";

                            skillProfile.editedNumericVal = editedSkillProfile && (editedSkillProfile.singleLevelSelectionChecked || editedSkillProfile.singleLevelSelectionChecked == null) ? editedSkillProfile.editedNumericVal : lowerOption.numericVal;
                            skillProfile.editedLabelVal = editedSkillProfile && (editedSkillProfile.singleLevelSelectionChecked || editedSkillProfile.singleLevelSelectionChecked == null) ? editedSkillProfile.editedLabelVal : lowerOption.labelVal;

                            skillProfile.isExtraFramework = editedSkillProfile.isExtraFramework;
                            skillProfile.extraFrameworkLevel = editedSkillProfile.extraFrameworkLevel;
                            skillProfile.extraFrameworkLevelId = editedSkillProfile.extraFrameworkLevelId;

                            editedProfiles.push(skillProfile);
                        }
                    } else {
                        if (skillProfiles.find((skillProfile) => skillProfile.skillCode == editedSkillProfile.skillCode && skillProfile.level == editedSkillProfile.level)) {
                            let skillProfile = skillProfiles.find((skillProfile) => skillProfile.skillCode == editedSkillProfile.skillCode && skillProfile.level == editedSkillProfile.level);
                            if (skillProfile) {
                                skillProfile.editedStringVal = editedSkillProfile && (editedSkillProfile.singleLevelSelectionChecked || editedSkillProfile.singleLevelSelectionChecked == null) ? editedSkillProfile.editedStringVal : lowerOption.stringVal;
                                skillProfile.edited = editedSkillProfile.editedNumericVal != 0 ? true : false;
                                skillProfile.source = "skillProfiles";

                                skillProfile.editedNumericVal = editedSkillProfile && (editedSkillProfile.singleLevelSelectionChecked || editedSkillProfile.singleLevelSelectionChecked == null) ? editedSkillProfile.editedNumericVal : lowerOption.numericVal;
                                skillProfile.editedLabelVal = editedSkillProfile && (editedSkillProfile.singleLevelSelectionChecked || editedSkillProfile.singleLevelSelectionChecked == null) ? editedSkillProfile.editedLabelVal : lowerOption.labelVal;

                                skillProfile.isExtraFramework = editedSkillProfile.isExtraFramework;
                                skillProfile.extraFrameworkLevel = editedSkillProfile.extraFrameworkLevel;
                                skillProfile.extraFrameworkLevelId = editedSkillProfile.extraFrameworkLevelId;

                                editedProfiles.push(skillProfile);
                            }
                        } else {
                            const skillProfile = {
                                category: editedSkillProfile.category,
                                subCategory: editedSkillProfile.subCategory,
                                skill: editedSkillProfile.skill,
                                skillCode: editedSkillProfile.skillCode,
                                level: editedSkillProfile.level,
                                //stringVal: editedSkillProfile.editedStringVal,
                                //numericVal: editedSkillProfile.editedNumericVal,
                                //labelVal: editedSkillProfile.editedLabelVal,
                                editedStringVal: editedSkillProfile && (editedSkillProfile.singleLevelSelectionChecked || editedSkillProfile.singleLevelSelectionChecked == null) ? editedSkillProfile.editedStringVal : lowerOption.stringVal,
                                editedNumericVal: editedSkillProfile && (editedSkillProfile.singleLevelSelectionChecked || editedSkillProfile.singleLevelSelectionChecked == null) ? editedSkillProfile.editedNumericVal : lowerOption.numericVal,
                                editedLabelVal: editedSkillProfile && (editedSkillProfile.singleLevelSelectionChecked || editedSkillProfile.singleLevelSelectionChecked == null) ? editedSkillProfile.editedLabelVal : lowerOption.labelVal,
                                edited: editedSkillProfile.editedNumericVal != 0 ? true : false,
                                source: "skillProfiles",
                                isExtraFramework: editedSkillProfile.isExtraFramework,
                                extraFrameworkLevel: editedSkillProfile.extraFrameworkLevel,
                                extraFrameworkLevelId: editedSkillProfile.extraFrameworkLevelId,
                            };

                            skillProfiles.push(skillProfile);
                            editedProfiles.push(skillProfile);
                        }
                    }
                });

            this.props.handleEditProfileSkills(editedProfiles);

            this.setState({ editSkill: false, skillProfiles: [...skillProfiles] });
        } else if (badgeCertification) {
            let badgeRequest = this.state.badgeRequest;
            let requestCertificationEntries = badgeRequest.requestCertificationEntries ? [...badgeRequest.requestCertificationEntries] : [];

            requestCertificationEntries.forEach((requestCertificationEntry) => {
                requestCertificationEntry.edited = true;
                requestCertificationEntry.editedStringVal = requestCertificationEntry.storedStringVal;
                requestCertificationEntry.editedNumericVal = requestCertificationEntry.storedNumericVal;
                requestCertificationEntry.editedLabelVal = requestCertificationEntry.storedLabelVal;
            });

            this.setState(
                {
                    badgeRequest,
                    editSkill: false,
                },
                () => {
                    setTimeout(() => {
                        this.props.badgeRequestUpdated(badgeRequest);
                    }, 100);
                }
            );
        } else {
            this.setState({ editSkill: false });
        }
    }

    handleSuggestUpdateLowerLevelProfile = (editedCategory, editedSubCategory, editedSkill) => {
        const { skillProfiles } = this.state;

        const skillCodeProfiles = skillProfiles ? [...skillProfiles.filter((x) => x.skillCode === editedSkill.skillsCode)] : [];
        let jobSkillProfiles = this.props.jobSkillProfiles ? this.props.jobSkillProfiles.filter((it) => it.skillCode == editedSkill.skillsCode) : [];

        jobSkillProfiles.forEach((jobSkillProfile) => {
            jobSkillProfile.isProvidedUpper = false;
        });

        jobSkillProfiles.forEach((jobSkillProfile) => {
            if (!editedCategory.isExtraFramework == true) {
                if (skillCodeProfiles.filter((it) => !it.editedStringVal).filter((it) => it.level == jobSkillProfile.level).length == 0 && skillCodeProfiles.filter((it) => !it.editedStringVal).filter((it) => it.stringVal != "N" && it.level > jobSkillProfile.level).length > 0) {
                    jobSkillProfile.isProvidedUpper = true;
                }
            } else {
                if (skillCodeProfiles.filter((it) => !it.editedStringVal).filter((it) => it.level == jobSkillProfile.level).length == 0 && skillCodeProfiles.filter((it) => !it.editedStringVal).filter((it) => it.level > jobSkillProfile.level && it.numericVal != 0).length > 0) {
                    jobSkillProfile.isProvidedUpper = true;
                }
            }
        });

        this.setState({ openSuggestEditLowerLevelProfile: true, editedCategory, editedSubCategory, editedSkill });
    };

    toggleModalSuggestEditLowerLevelProfileConfirmation = () => {
        const openSuggestEditLowerLevelProfile = this.state.openSuggestEditLowerLevelProfile;

        this.setState({ openSuggestEditLowerLevelProfile: !openSuggestEditLowerLevelProfile });
    };

    renderModalSuggestEditLowerLevelProfileConfirmation = () => {
        const { openSuggestEditLowerLevelProfile, editedCategory, editedSubCategory, editedSkill, skillProfiles } = this.state;

        const skillCodeProfiles = skillProfiles && editedSkill ? [...skillProfiles.filter((x) => x.skillCode === editedSkill.skillsCode)] : [];
        let jobSkillProfiles = this.props.jobSkillProfiles && editedSkill ? this.props.jobSkillProfiles.filter((it) => it.skillCode == editedSkill.skillsCode) : [];

        jobSkillProfiles.forEach((jobSkillProfile) => {
            jobSkillProfile.isProvidedUpper = false;
        });

        if (editedSkill) {
            jobSkillProfiles.forEach((jobSkillProfile) => {
                if (!editedCategory.isExtraFramework == true) {
                    if (
                        skillCodeProfiles.filter((it) => !it.editedStringVal).filter((it) => it.stringVal != "N" && it.level == jobSkillProfile.level).length == 0 &&
                        skillCodeProfiles.filter((it) => !it.editedStringVal).filter((it) => it.stringVal != "N" && it.level > jobSkillProfile.level).length > 0
                    ) {
                        jobSkillProfile.isProvidedUpper = true;
                    }
                } else {
                    if (
                        skillCodeProfiles.filter((it) => !it.editedStringVal).filter((it) => it.numericVal != 0 && it.level == jobSkillProfile.level).length == 0 &&
                        skillCodeProfiles.filter((it) => !it.editedStringVal).filter((it) => it.level > jobSkillProfile.level && it.numericVal != 0).length > 0
                    ) {
                        jobSkillProfile.isProvidedUpper = true;
                    }
                }
            });
        }

        const suggestedSkills =
            jobSkillProfiles.filter((it) => it.isProvidedUpper).length > 0
                ? jobSkillProfiles
                      .filter((it) => it.isProvidedUpper)
                      .map((it) => {
                          return `${it.skillCode}${it.level}`;
                      })
                      .join(", ")
                : "";

        return (
            <Modal isOpen={openSuggestEditLowerLevelProfile} toggle={this.toggleModalSuggestEditLowerLevelProfileConfirmation}>
                <ModalBody>
                    <Form>
                        <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "500px" }}>
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr valign="top">
                                        {this.props.managedPeople ? `${this.props.managedPeople.name} has gaps against required skills at lower levels as follows` : "You have gaps against required skills at lower levels as follows:"}
                                        <br />
                                        {suggestedSkills}
                                        <br />
                                        {this.props.managedPeople ? "Do you want to propose profile changes for these skill levels?" : "Do you want to add these skill levels to your profile?"}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleAcceptSuggestEditLowerLevelProfile();
                        }}
                    >
                        <ContentTranslator page="SfiaAll" name="OK" contentText="OK" />
                    </Button>
                    <Button color="secondary" onClick={this.toggleModalSuggestEditLowerLevelProfileConfirmation}>
                        <ContentTranslator page="SfiaAll" name="Cancel" contentText="Cancel" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleAcceptSuggestEditLowerLevelProfile = () => {
        const { editedCategory, editedSubCategory, editedSkill, skillValueOptions, editExtraFrameworkSkill } = this.state;
        let skillProfiles = this.state.skillProfiles;
        const { extraFrameworkCapabilities } = this.props;

        let topOption = null;
        if (editedCategory.isExtraFramework) {
            const leastOption = extraFrameworkCapabilities ? [...extraFrameworkCapabilities].sort((b, a) => a.weight - b.weight)[0] : null;
            topOption = {
                numericVal: leastOption.weight,
                stringVal: leastOption.capability,
                labelVal: null,
                isExtraFramework: editedCategory.isExtraFramework,
            };
        } else {
            const leastOption = skillValueOptions ? [...skillValueOptions].sort((b, a) => a.numericVal - b.numericVal)[0] : null;
            topOption = {
                numericVal: leastOption.numericVal,
                stringVal: leastOption.value,
                labelVal: leastOption.labelVal,
                isExtraFramework: editedCategory.isExtraFramework,
            };
        }

        const skillCodeProfiles = skillProfiles && editedSkill ? [...skillProfiles.filter((x) => x.skillCode === editedSkill.skillsCode)] : [];
        let jobSkillProfiles = this.props.jobSkillProfiles && editedSkill ? this.props.jobSkillProfiles.filter((it) => it.skillCode == editedSkill.skillsCode) : [];

        jobSkillProfiles.forEach((jobSkillProfile) => {
            jobSkillProfile.isProvidedUpper = false;
        });

        if (editedSkill) {
            jobSkillProfiles.forEach((jobSkillProfile) => {
                if (!editedCategory.isExtraFramework == true) {
                    if (
                        skillCodeProfiles.filter((it) => !it.editedStringVal).filter((it) => it.stringVal != "N" && it.level == jobSkillProfile.level).length == 0 &&
                        skillCodeProfiles.filter((it) => !it.editedStringVal).filter((it) => it.stringVal != "N" && it.level > jobSkillProfile.level).length > 0
                    ) {
                        jobSkillProfile.isProvidedUpper = true;
                    }
                } else {
                    if (
                        skillCodeProfiles.filter((it) => !it.editedStringVal).filter((it) => it.numericVal != 0 && it.level == jobSkillProfile.level).length == 0 &&
                        skillCodeProfiles.filter((it) => !it.editedStringVal).filter((it) => it.level > jobSkillProfile.level && it.numericVal != 0).length > 0
                    ) {
                        jobSkillProfile.isProvidedUpper = true;
                    }
                }
            });
        }

        let editedProfiles = [];
        jobSkillProfiles
            .filter((it) => it.isProvidedUpper)
            .forEach((jobSkillProfile) => {
                const skillLevel = editedSkill ? editedSkill.levels.find((it) => it.level == jobSkillProfile.level) : null;

                if (skillProfiles.find((skillProfile) => skillProfile.skillCode == jobSkillProfile.skillCode && skillProfile.level == jobSkillProfile.level)) {
                    let skillProfile = skillProfiles.find((skillProfile) => skillProfile.skillCode == jobSkillProfile.skillCode && skillProfile.level == jobSkillProfile.level);
                    if (skillProfile) {
                        skillProfile.editedStringVal = topOption.stringVal;
                        skillProfile.edited = true;
                        skillProfile.source = "skillProfiles";

                        skillProfile.editedNumericVal = topOption.numericVal;
                        skillProfile.editedLabelVal = topOption.labelVal;

                        skillProfile.isExtraFramework = editedCategory.isExtraFramework;
                        skillProfile.extraFrameworkLevel = skillLevel ? skillLevel.extraFrameworkLevel : null;
                        skillProfile.extraFrameworkLevelId = skillLevel ? skillLevel.extraFrameworkLevelId : null;

                        editedProfiles.push(skillProfile);
                    }
                } else {
                    const skillProfile = {
                        category: editedCategory.name,
                        subCategory: editedSubCategory.name,
                        skill: editedSkill.name,
                        skillCode: jobSkillProfile.skillCode,
                        level: jobSkillProfile.level,
                        editedStringVal: topOption.stringVal,
                        editedNumericVal: topOption.numericVal,
                        editedLabelVal: topOption.labelVal,
                        edited: true,
                        source: "skillProfiles",
                        isExtraFramework: editedCategory.isExtraFramework,
                        extraFrameworkLevel: skillLevel ? skillLevel.extraFrameworkLevel : null,
                        extraFrameworkLevelId: skillLevel ? skillLevel.extraFrameworkLevelId : null,
                    };

                    skillProfiles.push(skillProfile);
                    editedProfiles.push(skillProfile);
                }
            });

        if (this.props.managedPeople) {
            this.props.handleManagerSuggestEditLowerLevelProfile(editedProfiles);
        } else {
            this.props.handleSuggestEditLowerLevelProfile(editedProfiles);
        }

        this.setState({ openSuggestEditLowerLevelProfile: false, skillProfiles: [...skillProfiles] });
    };

    elementSkill(skillsCode, level, levels, subCategorySkillProfiles, catColour, colour, skillColour, category, subCategory, showProposedAction, proposeSkills, showSuggestedUpdate, suggestedSkills) {
        const { badgeRequest } = this.state;
        const { showEditSkill, isShowJobProfile, badgeCertification, showEditCertify } = this.props;

        const skillBadgeRequest =
            badgeRequest && badgeRequest.requestCertificationEntries && badgeRequest.requestCertificationEntries.length > 0
                ? badgeRequest.requestCertificationEntries.find((it) => it.category == category.name && it.subCategory == subCategory.name && it.skillsCode == skillsCode && it.level == level)
                : null;

        const colFully = `${colour}`;
        const colLargely = `repeating-linear-gradient(45deg, ${colour}, ${colour} 5px, ${skillColour} 5px, ${skillColour} 10px)`;
        const colPrevious = `repeating-linear-gradient(${colour}, ${colour} 5px, ${skillColour} 5px, ${skillColour} 10px)`;
        const colUnmatched = `${skillColour}`;

        let colProposed = `#f2aa84`;
        let proposedCapabilityNumericVal = 0;
        const colProposedCompetent = `#f2aa84`;
        const colProposedProficient = `repeating-linear-gradient(45deg, #f2aa84, #f2aa84 5px, #ffffff 5px, #ffffff 10px)`;
        const colProposedKnowledge = `repeating-linear-gradient(#f2aa84, #f2aa84 5px, #ffffff 5px, #ffffff 10px)`;

        if (showProposedAction && proposeSkills && proposeSkills.find((s) => s.code == skillsCode && s.level == level)) {
            if (proposeSkills.find((s) => s.code == skillsCode && s.level == level).capability == "Competent") {
                colProposed = colProposedCompetent;
                proposedCapabilityNumericVal = 1;
            } else if (proposeSkills.find((s) => s.code == skillsCode && s.level == level).capability == "Proficient") {
                colProposed = colProposedProficient;
                proposedCapabilityNumericVal = 0.5;
            } else if (proposeSkills.find((s) => s.code == skillsCode && s.level == level).capability == "Knowledge") {
                colProposed = colProposedKnowledge;
                proposedCapabilityNumericVal = 0.25;
            }
        }

        let colSuggested = `#f2aa84`;
        const colSuggestedCompetent = `#f2aa84`;
        const colSuggestedProficient = `repeating-linear-gradient(45deg, #f2aa84, #f2aa84 5px, #ffffff 5px, #ffffff 10px)`;
        const colSuggestedKnowledge = `repeating-linear-gradient(#f2aa84, #f2aa84 5px, #ffffff 5px, #ffffff 10px)`;

        if (showSuggestedUpdate && suggestedSkills && suggestedSkills.find((s) => s.skillCode == skillsCode && s.level == level)) {
            if (suggestedSkills.find((s) => s.skillCode == skillsCode && s.level == level).stringVal == "M") {
                colSuggested = colSuggestedCompetent;
            } else if (suggestedSkills.find((s) => s.skillCode == skillsCode && s.level == level).stringVal == "P") {
                colSuggested = colSuggestedProficient;
            } else if (suggestedSkills.find((s) => s.skillCode == skillsCode && s.level == level).stringVal == "NA") {
                colSuggested = colSuggestedKnowledge;
            }
        }

        const colDesirable = `repeating-linear-gradient(90deg, ${colour}, ${colour} 5px, ${skillColour} 5px, ${skillColour} 10px)`;

        const { highlightGap, selectedJobMatchType } = this.props;

        const jobSkillProfiles = this.props.jobSkillProfiles;

        const jobSkillProfile = jobSkillProfiles && jobSkillProfiles.find((it) => it.skillCode == skillsCode && it.level == level);

        const skillLevel = levels.filter((x) => x.level == level)[0];
        let skillLevelDescription = skillLevel ? skillLevel.description : "";
        skillLevelDescription = this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${skillsCode} ${level} Description`, skillLevelDescription);

        let openCommentEditor = false;
        let openCommentAllowEdit = false;

        if (!badgeCertification && subCategorySkillProfiles.filter((x) => x.level == level).length > 0) {
            const skillProfile = subCategorySkillProfiles.find((x) => x.level == level);
            const skillVal = skillProfile && (skillProfile.editedStringVal ? skillProfile.editedStringVal : skillProfile.stringVal);
            const skillNumericVal = skillProfile && (skillProfile.editedNumericVal ? skillProfile.editedNumericVal : skillProfile.numericVal);

            openCommentEditor = false;
            openCommentAllowEdit = false;

            if (!isShowJobProfile && !showEditSkill && !showEditCertify && ((skillProfile.edited && skillProfile.editedNumericVal != 0) || (!skillProfile.edited && skillProfile.numericVal != 0))) {
                if (
                    (this.props.selectedprofileType.value == "Self Assessed" && !this.props.managedPeople) ||
                    (this.props.selectedprofileType.value == "Self Assessed" && this.props.managedPeople && skillProfile.comment) ||
                    ((this.props.managedPeople || skillProfile.comment) && this.props.selectedprofileType.value == "Endorsed")
                ) {
                    openCommentEditor = true;
                }

                if ((this.props.selectedprofileType.value == "Self Assessed" && !this.props.managedPeople) || (this.props.managedPeople && this.props.selectedprofileType.value == "Endorsed")) {
                    openCommentAllowEdit = true;
                }
            }

            return (
                <React.Fragment>
                    <td
                        id={`lv${level}-${skillProfile && skillProfile.id}`}
                        className={`pt-1 pb-1 td-value ${highlightGap ? "td-fixwidth-39" : showEditSkill || showEditCertify ? "td-fixwidth-85" : "td-fixwidth"}`}
                        style={{
                            background:
                                showProposedAction && proposeSkills && proposeSkills.find((s) => s.type == "Skill" && s.code == skillsCode && s.level == level) && proposedCapabilityNumericVal > skillNumericVal
                                    ? colProposed
                                    : showSuggestedUpdate && suggestedSkills && suggestedSkills.find((s) => s.skillCode == skillsCode && s.level == level) && suggestedSkills.find((s) => s.skillCode == skillsCode && s.level == level).numericVal > skillNumericVal
                                    ? colSuggested
                                    : skillVal === "M"
                                    ? colFully
                                    : isShowJobProfile
                                    ? colDesirable
                                    : skillVal === "P"
                                    ? colLargely
                                    : skillVal === "NA"
                                    ? colPrevious
                                    : colUnmatched,
                            borderRight: "1px solid #FFFFFF",
                            borderBottom: `1px solid #dee2e6`,
                            cursor: openCommentEditor ? "pointer" : "default",
                        }}
                        onClick={() =>
                            openCommentEditor &&
                            this.openEditSkillComment(
                                {
                                    id: category.id,
                                    name: this.contentTranslator(this.props.contentTranslations, "Categories", category.name, category.name),
                                    colour: category.colour,
                                },
                                {
                                    id: subCategory.id,
                                    name: this.contentTranslator(this.props.contentTranslations, "SubCategories", subCategory.name, subCategory.name),
                                    colour: subCategory.colour,
                                    skillColour: subCategory.skillColour,
                                },
                                this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${skillProfile.skillCode} ${skillProfile.level}`, skillLevel.description),
                                skillProfile,
                                openCommentAllowEdit
                            )
                        }
                    >
                        {skillProfile.comment && ((skillProfile.edited && skillProfile.editedNumericVal != 0) || (!skillProfile.edited && skillProfile.numericVal != 0)) && <FontAwesomeIcon icon={faComment} style={{ color: "#000000" }} />}
                    </td>
                    <UncontrolledTooltip
                        target={`lv${level}-${skillProfile && skillProfile.id}`}
                        placement="bottom"
                        dangerouslySetInnerHTML={{
                            __html: skillLevelDescription,
                        }}
                    ></UncontrolledTooltip>
                    {highlightGap && (
                        <React.Fragment>
                            <SkillProfileGapHighlight
                                id={`lvGap${level}-${skillProfile && skillProfile.id}`}
                                colUnmatched={colUnmatched}
                                isShow={jobSkillProfile && skillProfile ? true : false}
                                isFully={jobSkillProfile && skillProfile && (skillProfile.numericVal == 1 || skillProfile.stringVal == "M") ? true : false}
                                jobMatchType={selectedJobMatchType.value}
                                reqDesValue={jobSkillProfile && skillProfile && jobSkillProfile.stringVal == "M" ? "Required" : "Desirable"}
                                rightBorderColor={catColour}
                                fixWidthClass="td-fixwidth-39"
                            ></SkillProfileGapHighlight>
                            <UncontrolledTooltip
                                target={`lvGap${level}-${skillProfile && skillProfile.id}`}
                                placement="bottom"
                                dangerouslySetInnerHTML={{
                                    __html: skillLevelDescription,
                                }}
                            ></UncontrolledTooltip>
                        </React.Fragment>
                    )}
                </React.Fragment>
            );
        } else if (badgeCertification && skillBadgeRequest) {
            const skillProfile = skillBadgeRequest;
            const skillVal = skillBadgeRequest.editedStringVal ? skillBadgeRequest.editedStringVal : skillBadgeRequest.stringVal;

            openCommentEditor = false;
            openCommentAllowEdit = false;

            return (
                <React.Fragment>
                    <td
                        id={`lv${level}-${skillProfile && skillProfile.id}`}
                        className={`pt-1 pb-1 td-value ${highlightGap ? "td-fixwidth-39" : showEditSkill || showEditCertify ? "td-fixwidth-85" : "td-fixwidth"}`}
                        style={{
                            background: skillVal === "M" ? colFully : isShowJobProfile ? colDesirable : skillVal === "P" ? colLargely : skillVal === "NA" ? colPrevious : colUnmatched,
                            borderRight: "1px solid #FFFFFF",
                            borderBottom: `1px solid #dee2e6`,
                            cursor: openCommentEditor ? "pointer" : "default",
                        }}
                        onClick={() =>
                            openCommentEditor &&
                            this.openEditSkillComment(
                                {
                                    id: category.id,
                                    name: this.contentTranslator(this.props.contentTranslations, "Categories", category.name, category.name),
                                    colour: category.colour,
                                },
                                {
                                    id: subCategory.id,
                                    name: this.contentTranslator(this.props.contentTranslations, "SubCategories", subCategory.name, subCategory.name),
                                    colour: subCategory.colour,
                                    skillColour: subCategory.skillColour,
                                },
                                this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${skillProfile.skillsCode} ${skillProfile.level}`, skillLevel.description),
                                skillProfile,
                                openCommentAllowEdit
                            )
                        }
                    ></td>
                    <UncontrolledTooltip
                        target={`lv${level}-${skillProfile && skillProfile.id}`}
                        placement="bottom"
                        dangerouslySetInnerHTML={{
                            __html: skillLevelDescription,
                        }}
                    ></UncontrolledTooltip>
                    {highlightGap && (
                        <React.Fragment>
                            <SkillProfileGapHighlight
                                id={`lvGap${level}-${skillProfile && skillProfile.id}`}
                                colUnmatched={colUnmatched}
                                isShow={jobSkillProfile && skillProfile ? true : false}
                                isFully={jobSkillProfile && skillProfile && (skillProfile.numericVal == 1 || skillProfile.stringVal == "M") ? true : false}
                                jobMatchType={selectedJobMatchType.value}
                                reqDesValue={jobSkillProfile && skillProfile && jobSkillProfile.stringVal == "M" ? "Required" : "Desirable"}
                                rightBorderColor={catColour}
                                fixWidthClass="td-fixwidth-39"
                            ></SkillProfileGapHighlight>
                            <UncontrolledTooltip
                                target={`lvGap${level}-${skillProfile && skillProfile.id}`}
                                placement="bottom"
                                dangerouslySetInnerHTML={{
                                    __html: skillLevelDescription,
                                }}
                            ></UncontrolledTooltip>
                        </React.Fragment>
                    )}
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <td
                        id={`lv${skillsCode}${level}`}
                        className={`pt-1 pb-1 td-value ${highlightGap ? "td-fixwidth-39" : showEditSkill || showEditCertify ? "td-fixwidth-85" : "td-fixwidth"}`}
                        style={{
                            background: skillLevel
                                ? showProposedAction && proposeSkills && proposeSkills.find((s) => s.code == skillsCode && s.level == level)
                                    ? colProposed
                                    : showSuggestedUpdate && suggestedSkills && suggestedSkills.find((s) => s.skillCode == skillsCode && s.level == level)
                                    ? colSuggested
                                    : colUnmatched
                                : "#FFFFFF",
                            borderRight: "1px solid #FFFFFF",
                            borderBottom: `1px solid #dee2e6`,
                        }}
                    ></td>
                    {skillLevel && (
                        <UncontrolledTooltip
                            target={`lv${skillsCode}${level}`}
                            placement="bottom"
                            dangerouslySetInnerHTML={{
                                __html: skillLevelDescription,
                            }}
                        ></UncontrolledTooltip>
                    )}
                    {highlightGap &&
                        (jobSkillProfile && skillLevel ? (
                            <React.Fragment>
                                <SkillProfileGapHighlight
                                    id={`lvGap${skillsCode}${level}`}
                                    colUnmatched={colUnmatched}
                                    isShow={true}
                                    isFully={false}
                                    jobMatchType={selectedJobMatchType.value}
                                    reqDesValue={jobSkillProfile && jobSkillProfile.stringVal == "M" ? "Required" : "Desirable"}
                                    rightBorderColor={catColour}
                                    fixWidthClass="td-fixwidth-39"
                                ></SkillProfileGapHighlight>
                                {skillLevel && (
                                    <UncontrolledTooltip
                                        target={`lvGap${skillsCode}${level}`}
                                        placement="bottom"
                                        dangerouslySetInnerHTML={{
                                            __html: skillLevelDescription,
                                        }}
                                    ></UncontrolledTooltip>
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <td
                                    id={`lvGap${skillsCode}${level}`}
                                    className={`pt-1 pb-1 td-value td-fixwidth-39`}
                                    style={{
                                        background: skillLevel ? colUnmatched : "#FFFFFF",
                                        borderRight: `2px solid ${catColour}`,
                                        borderBottom: `1px solid #dee2e6`,
                                    }}
                                ></td>
                                {skillLevel && (
                                    <UncontrolledTooltip
                                        target={`lvGap${skillsCode}${level}`}
                                        placement="bottom"
                                        dangerouslySetInnerHTML={{
                                            __html: skillLevelDescription,
                                        }}
                                    ></UncontrolledTooltip>
                                )}
                            </React.Fragment>
                        ))}
                </React.Fragment>
            );
        }
    }

    elementSkillExtraFramework(skillsCode, skillLevelSelection, extraFrameworkLevelId, levels, subCategorySkillProfiles, catColour, colour, skillColour, category, subCategory, showProposedAction, proposeSkills, showSuggestedUpdate, suggestedSkills) {
        const { badgeRequest } = this.state;
        const { showEditSkill, isShowJobProfile, extraFrameworkCapabilities, badgeCertification, showEditCertify } = this.props;

        const colFully = `${colour}`;
        const colLargely = `repeating-linear-gradient(45deg, ${colour}, ${colour} 5px, ${skillColour} 5px, ${skillColour} 10px)`;
        const colPrevious = `repeating-linear-gradient(${colour}, ${colour} 5px, ${skillColour} 5px, ${skillColour} 10px)`;
        const colUnmatched = `${skillColour}`;

        const colDesirable = `repeating-linear-gradient(90deg, ${colour}, ${colour} 5px, ${skillColour} 5px, ${skillColour} 10px)`;

        const { highlightGap, selectedJobMatchType } = this.props;

        const jobSkillProfiles = this.props.jobSkillProfiles;

        const jobSkillProfile = jobSkillProfiles && jobSkillProfiles.find((it) => it.skillCode == skillsCode && it.extraFrameworkLevelId == extraFrameworkLevelId);

        const skillLevel = levels.filter((x) => x.extraFrameworkLevelId == extraFrameworkLevelId)[0];
        let skillLevelDescription = skillLevel ? skillLevel.description : "";
        skillLevelDescription = this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${skillsCode} ${extraFrameworkLevelId} Description`, skillLevelDescription);

        const skillBadgeRequest =
            badgeRequest && badgeRequest.requestCertificationEntries && badgeRequest.requestCertificationEntries.length > 0
                ? badgeRequest.requestCertificationEntries.find((it) => it.category == category.name && it.subCategory == subCategory.name && it.skillsCode == skillsCode && it.level == (skillLevel != null ? skillLevel.level : null))
                : null;

        let openCommentEditor = false;
        let openCommentAllowEdit = false;

        let colProposed = `#f2aa84`;
        let proposedCapability = "";
        const colProposedCompetent = `#f2aa84`;
        const colProposedProficient = `repeating-linear-gradient(45deg, #f2aa84, #f2aa84 5px, #ffffff 5px, #ffffff 10px)`;
        const colProposedKnowledge = `repeating-linear-gradient(#f2aa84, #f2aa84 5px, #ffffff 5px, #ffffff 10px)`;

        if (showProposedAction) {
            showProposedAction = false;

            if (skillLevelSelection == "Single") {
                const extraFrameworkSkillProfiles = subCategorySkillProfiles.filter((x) => x.category == category.name && x.subCategory == subCategory.name && x.skillCode == skillsCode && (x.stringVal !== "N" || x.editedStringVal));
                const extraFrameworkProposeSkills = proposeSkills
                    .filter((x) => x.type == "Skill" && x.category == category.name && x.subCategory == subCategory.name && x.code == skillsCode)
                    .map((it) => {
                        return {
                            capability: it.capability,
                            numericVal: it.capability == "Competent" ? 1 : it.capability == "Proficient" ? 0.5 : it.capability == "Knowledge" ? 0.25 : 0,
                            category: it.category,
                            code: it.code,
                            id: it.id,
                            level: it.level,
                            name: it.name,
                            subCategory: it.subCategory,
                            type: it.type,
                        };
                    });

                let maxProposeSkill = null;
                if (extraFrameworkProposeSkills.length > 0) {
                    maxProposeSkill = extraFrameworkProposeSkills.sort((a, b) => b.level - a.level)[0];
                }

                if (skillLevel && maxProposeSkill && maxProposeSkill.level == skillLevel.level && extraFrameworkSkillProfiles.length == 0 && extraFrameworkProposeSkills.length > 0) {
                    // No profile skills but have proposed skills
                    showProposedAction = true;
                } else if (
                    skillLevel &&
                    maxProposeSkill &&
                    maxProposeSkill.level == skillLevel.level &&
                    extraFrameworkSkillProfiles.filter((x) => extraFrameworkProposeSkills.some((y) => x.skillCode == y.code && (y.level > x.level || (x.level == y.level && y.numericVal > x.numericVal)))).length > 0
                ) {
                    // Have proposed skills with better capability
                    showProposedAction = true;
                } else if (skillLevel && maxProposeSkill && maxProposeSkill.level == skillLevel.level && extraFrameworkProposeSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.code == y.skillCode)).length > 0) {
                    // Have proposed skills which not exist in profile skills
                    showProposedAction = true;
                }

                if (showProposedAction && maxProposeSkill) {
                    if (maxProposeSkill.capability == "Competent") {
                        colProposed = colProposedCompetent;
                    } else if (maxProposeSkill.capability == "Proficient") {
                        colProposed = colProposedProficient;
                    } else if (maxProposeSkill.capability == "Knowledge") {
                        colProposed = colProposedKnowledge;
                    }
                }
            } else {
                if (proposeSkills && skillLevel && proposeSkills.find((s) => s.code == skillsCode && s.level == skillLevel.level)) {
                    showProposedAction = true;
                    proposedCapability = proposeSkills.find((s) => s.code == skillsCode && s.level == skillLevel.level).capability;
                    if (proposedCapability == "Competent") {
                        colProposed = colProposedCompetent;
                    } else if (proposedCapability == "Proficient") {
                        colProposed = colProposedProficient;
                    } else if (proposedCapability == "Knowledge") {
                        colProposed = colProposedKnowledge;
                    }
                }
            }
        }

        let colSuggested = `#f2aa84`;
        const colSuggestedCompetent = `#f2aa84`;
        const colSuggestedProficient = `repeating-linear-gradient(45deg, #f2aa84, #f2aa84 5px, #ffffff 5px, #ffffff 10px)`;
        const colSuggestedKnowledge = `repeating-linear-gradient(#f2aa84, #f2aa84 5px, #ffffff 5px, #ffffff 10px)`;

        if (showSuggestedUpdate) {
            showSuggestedUpdate = false;

            if (skillLevelSelection == "Single") {
                const extraFrameworkSkillProfiles = subCategorySkillProfiles.filter((x) => x.category == category.name && x.subCategory == subCategory.name && x.skillCode == skillsCode && (x.stringVal !== "N" || x.editedStringVal));
                const extraFrameworkSuggestedSkills = suggestedSkills
                    .filter((x) => x.category == category.name && x.subCategory == subCategory.name && x.skillCode == skillsCode)
                    .map((it) => {
                        return {
                            numericVal: it.numericVal,
                            category: it.category,
                            subCategory: it.subCategory,
                            id: it.id,
                            skill: it.skill,
                            skillCode: it.skillCode,
                            level: it.level,
                        };
                    });

                let maxSuggestedSkill = null;
                if (extraFrameworkSuggestedSkills.length > 0) {
                    maxSuggestedSkill = extraFrameworkSuggestedSkills.sort((a, b) => b.level - a.level)[0];
                }

                if (skillLevel && maxSuggestedSkill && maxSuggestedSkill.level == skillLevel.level && extraFrameworkSkillProfiles.length == 0 && extraFrameworkSuggestedSkills.length > 0) {
                    // No profile skills but have suggested skills
                    showSuggestedUpdate = true;
                } else if (
                    skillLevel &&
                    maxSuggestedSkill &&
                    maxSuggestedSkill.level == skillLevel.level &&
                    extraFrameworkSkillProfiles.filter((x) => extraFrameworkSuggestedSkills.some((y) => x.skillCode == y.skillCode && (y.level > x.level || (x.level == y.level && y.numericVal > x.numericVal)))).length > 0
                ) {
                    // Have suggested skills with better capability
                    showSuggestedUpdate = true;
                } else if (skillLevel && maxSuggestedSkill && maxSuggestedSkill.level == skillLevel.level && extraFrameworkSuggestedSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.skillCode == y.skillCode)).length > 0) {
                    // Have suggested skills which not exist in profile skills
                    showSuggestedUpdate = true;
                }

                if (showSuggestedUpdate && maxSuggestedSkill) {
                    const skillVal = maxSuggestedSkill && (maxSuggestedSkill.editedStringVal ? maxSuggestedSkill.editedStringVal : maxSuggestedSkill.stringVal);

                    if (extraFrameworkCapabilities && extraFrameworkCapabilities.length > 0) {
                        if (extraFrameworkCapabilities.find((it) => it.capability == skillVal)) {
                            extraFrameworkCapability = extraFrameworkCapabilities.find((it) => it.capability == skillVal);
                            const index = extraFrameworkCapabilities.indexOf(extraFrameworkCapability);

                            if (index == 1) {
                                colSuggested = colSuggestedCompetent;
                            } else if (index == 2) {
                                colSuggested = colSuggestedProficient;
                            } else if (index == 3) {
                                colSuggested = colSuggestedKnowledge;
                            }
                        }
                    }
                }
            } else {
                if (suggestedSkills && skillLevel && suggestedSkills.find((s) => s.skillCode == skillsCode && s.level == skillLevel.level)) {
                    showSuggestedUpdate = true;
                    const suggestedSkill = suggestedSkills.find((s) => s.skillCode == skillsCode && s.level == skillLevel.level);
                    const skillVal = suggestedSkill && (suggestedSkill.editedStringVal ? suggestedSkill.editedStringVal : suggestedSkill.stringVal);

                    if (extraFrameworkCapabilities && extraFrameworkCapabilities.length > 0) {
                        if (extraFrameworkCapabilities.find((it) => it.capability == skillVal)) {
                            extraFrameworkCapability = extraFrameworkCapabilities.find((it) => it.capability == skillVal);
                            const index = extraFrameworkCapabilities.indexOf(extraFrameworkCapability);

                            if (index == 1) {
                                colSuggested = colSuggestedCompetent;
                            } else if (index == 2) {
                                colSuggested = colSuggestedProficient;
                            } else if (index == 3) {
                                colSuggested = colSuggestedKnowledge;
                            }
                        }
                    }
                }
            }
        }

        if (!badgeCertification && subCategorySkillProfiles.filter((x) => x.extraFrameworkLevelId == extraFrameworkLevelId).length > 0) {
            const skillProfile = subCategorySkillProfiles.find((x) => x.extraFrameworkLevelId == extraFrameworkLevelId);
            const skillVal = skillProfile && (skillProfile.editedStringVal ? skillProfile.editedStringVal : skillProfile.stringVal);

            let colBackground = colUnmatched;
            let extraFrameworkCapability;

            if (!isShowJobProfile) {
                if (extraFrameworkCapabilities && extraFrameworkCapabilities.length > 0) {
                    if (extraFrameworkCapabilities.find((it) => it.capability == skillVal)) {
                        extraFrameworkCapability = extraFrameworkCapabilities.find((it) => it.capability == skillVal);
                        const index = extraFrameworkCapabilities.indexOf(extraFrameworkCapability);

                        if (index == 0) {
                            colBackground = colUnmatched;
                        } else if (index == 1) {
                            colBackground = colFully;
                        } else if (index == 2) {
                            colBackground = colLargely;
                        } else if (index == 3) {
                            colBackground = colPrevious;
                        }
                    }
                }
            } else {
                if (skillVal == "M") {
                    colBackground = colFully;
                } else {
                    colBackground = colDesirable;
                }
            }

            openCommentEditor = false;
            openCommentAllowEdit = false;

            if (!isShowJobProfile && !showEditSkill && !showEditCertify && ((skillProfile.edited && skillProfile.editedNumericVal != 0) || (!skillProfile.edited && skillProfile.numericVal != 0))) {
                if (
                    (this.props.selectedprofileType.value == "Self Assessed" && !this.props.managedPeople) ||
                    (this.props.selectedprofileType.value == "Self Assessed" && this.props.managedPeople && skillProfile.comment) ||
                    ((this.props.managedPeople || skillProfile.comment) && this.props.selectedprofileType.value == "Endorsed")
                ) {
                    openCommentEditor = true;
                }

                if ((this.props.selectedprofileType.value == "Self Assessed" && !this.props.managedPeople) || (this.props.managedPeople && this.props.selectedprofileType.value == "Endorsed")) {
                    openCommentAllowEdit = true;
                }
            }

            return (
                <React.Fragment key={`frlv${extraFrameworkLevelId}-${skillProfile && skillProfile.id}`}>
                    <td
                        id={`lv${extraFrameworkLevelId}-${skillProfile && skillProfile.id}`}
                        className={`pt-1 pb-1 td-value ${highlightGap ? "td-fixwidth-39" : showEditSkill || showEditCertify ? "td-fixwidth-85" : "td-fixwidth"}`}
                        style={{
                            background: showProposedAction ? colProposed : showSuggestedUpdate ? colSuggested : colBackground,
                            borderRight: "1px solid #FFFFFF",
                            borderBottom: `1px solid #dee2e6`,
                            cursor: openCommentEditor ? "pointer" : "default",
                        }}
                        onClick={() =>
                            openCommentEditor &&
                            this.openEditSkillComment(
                                {
                                    id: category.id,
                                    name: this.contentTranslator(this.props.contentTranslations, "Categories", category.name, category.name),
                                    colour: category.colour,
                                },
                                {
                                    id: subCategory.id,
                                    name: this.contentTranslator(this.props.contentTranslations, "SubCategories", subCategory.name, subCategory.name),
                                    colour: subCategory.colour,
                                    skillColour: subCategory.skillColour,
                                },
                                this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${skillProfile.skillCode} ${skillProfile.level}`, skillLevel.description),
                                skillProfile,
                                openCommentAllowEdit
                            )
                        }
                    >
                        {skillProfile.comment && ((skillProfile.edited && skillProfile.editedNumericVal != 0) || (!skillProfile.edited && skillProfile.numericVal != 0)) && <FontAwesomeIcon icon={faComment} style={{ color: "#000000" }} />}
                    </td>
                    <UncontrolledTooltip
                        target={`lv${extraFrameworkLevelId}-${skillProfile && skillProfile.id}`}
                        placement="bottom"
                        dangerouslySetInnerHTML={{
                            __html: skillLevelDescription,
                        }}
                    ></UncontrolledTooltip>
                    {highlightGap && (
                        <React.Fragment>
                            <SkillProfileGapHighlight
                                id={`lvGap${extraFrameworkLevelId}-${skillProfile && skillProfile.id}`}
                                colUnmatched={colUnmatched}
                                isShow={jobSkillProfile && skillProfile ? true : false}
                                isFully={jobSkillProfile && skillProfile && skillProfile.numericVal == (extraFrameworkCapability && extraFrameworkCapability.weight ? extraFrameworkCapability.weight : 1) ? true : false}
                                jobMatchType={selectedJobMatchType.value}
                                reqDesValue={jobSkillProfile && skillProfile && jobSkillProfile.stringVal == "M" ? "Required" : "Desirable"}
                                rightBorderColor={catColour}
                                fixWidthClass="td-fixwidth-39"
                            ></SkillProfileGapHighlight>
                            <UncontrolledTooltip
                                target={`lvGap${extraFrameworkLevelId}-${skillProfile && skillProfile.id}`}
                                placement="bottom"
                                dangerouslySetInnerHTML={{
                                    __html: skillLevelDescription,
                                }}
                            ></UncontrolledTooltip>
                        </React.Fragment>
                    )}
                </React.Fragment>
            );
        } else if (badgeCertification && skillBadgeRequest) {
            const skillProfile = skillBadgeRequest;
            const skillVal = skillBadgeRequest.editedStringVal ? skillBadgeRequest.editedStringVal : skillBadgeRequest.stringVal;

            openCommentEditor = false;
            openCommentAllowEdit = false;

            return (
                <React.Fragment key={`frlv${extraFrameworkLevelId}-${skillProfile && skillProfile.id}`}>
                    <td
                        id={`lv${extraFrameworkLevelId}-${skillProfile && skillProfile.id}`}
                        className={`pt-1 pb-1 td-value ${highlightGap ? "td-fixwidth-39" : showEditSkill || showEditCertify ? "td-fixwidth-85" : "td-fixwidth"}`}
                        style={{
                            background: colBackground,
                            borderRight: "1px solid #FFFFFF",
                            borderBottom: `1px solid #dee2e6`,
                            cursor: openCommentEditor ? "pointer" : "default",
                        }}
                        onClick={() =>
                            openCommentEditor &&
                            this.openEditSkillComment(
                                {
                                    id: category.id,
                                    name: this.contentTranslator(this.props.contentTranslations, "Categories", category.name, category.name),
                                    colour: category.colour,
                                },
                                {
                                    id: subCategory.id,
                                    name: this.contentTranslator(this.props.contentTranslations, "SubCategories", subCategory.name, subCategory.name),
                                    colour: subCategory.colour,
                                    skillColour: subCategory.skillColour,
                                },
                                this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${skillProfile.skillsCode} ${skillProfile.level}`, skillLevel.description),
                                skillProfile,
                                openCommentAllowEdit
                            )
                        }
                    ></td>
                    <UncontrolledTooltip
                        target={`lv${extraFrameworkLevelId}-${skillProfile && skillProfile.id}`}
                        placement="bottom"
                        dangerouslySetInnerHTML={{
                            __html: skillLevelDescription,
                        }}
                    ></UncontrolledTooltip>
                    {highlightGap && (
                        <React.Fragment>
                            <SkillProfileGapHighlight
                                id={`lvGap${extraFrameworkLevelId}-${skillProfile && skillProfile.id}`}
                                colUnmatched={colUnmatched}
                                isShow={jobSkillProfile && skillProfile ? true : false}
                                isFully={jobSkillProfile && skillProfile && skillProfile.numericVal == (extraFrameworkCapability && extraFrameworkCapability.weight ? extraFrameworkCapability.weight : 1) ? true : false}
                                jobMatchType={selectedJobMatchType.value}
                                reqDesValue={jobSkillProfile && skillProfile && jobSkillProfile.stringVal == "M" ? "Required" : "Desirable"}
                                rightBorderColor={catColour}
                                fixWidthClass="td-fixwidth-39"
                            ></SkillProfileGapHighlight>
                            <UncontrolledTooltip
                                target={`lvGap${extraFrameworkLevelId}-${skillProfile && skillProfile.id}`}
                                placement="bottom"
                                dangerouslySetInnerHTML={{
                                    __html: skillLevelDescription,
                                }}
                            ></UncontrolledTooltip>
                        </React.Fragment>
                    )}
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment key={`frlv${skillsCode}${extraFrameworkLevelId}`}>
                    <td
                        id={`lv${skillsCode}${extraFrameworkLevelId}`}
                        className={`pt-1 pb-1 td-value ${highlightGap ? "td-fixwidth-39" : showEditSkill || showEditCertify ? "td-fixwidth-85" : "td-fixwidth"}`}
                        style={{ background: showProposedAction ? colProposed : showSuggestedUpdate ? colSuggested : skillLevel ? colUnmatched : "#FFFFFF", borderRight: "1px solid #FFFFFF", borderBottom: `1px solid #dee2e6` }}
                    ></td>
                    {skillLevel && (
                        <UncontrolledTooltip
                            target={`lv${skillsCode}${extraFrameworkLevelId}`}
                            placement="bottom"
                            dangerouslySetInnerHTML={{
                                __html: skillLevelDescription,
                            }}
                        ></UncontrolledTooltip>
                    )}
                    {highlightGap &&
                        (jobSkillProfile && skillLevel ? (
                            <React.Fragment>
                                <SkillProfileGapHighlight
                                    id={`lvGap${skillsCode}${extraFrameworkLevelId}`}
                                    colUnmatched={colUnmatched}
                                    isShow={true}
                                    isFully={false}
                                    jobMatchType={selectedJobMatchType.value}
                                    reqDesValue={jobSkillProfile && jobSkillProfile.stringVal == "M" ? "Required" : "Desirable"}
                                    rightBorderColor={catColour}
                                    fixWidthClass="td-fixwidth-39"
                                ></SkillProfileGapHighlight>
                                {skillLevel && (
                                    <UncontrolledTooltip
                                        target={`lvGap${skillsCode}${extraFrameworkLevelId}`}
                                        placement="bottom"
                                        dangerouslySetInnerHTML={{
                                            __html: skillLevelDescription,
                                        }}
                                    ></UncontrolledTooltip>
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <td
                                    id={`lvGap${skillsCode}${extraFrameworkLevelId}`}
                                    className={`pt-1 pb-1 td-value td-fixwidth-39`}
                                    style={{
                                        background: skillLevel ? colUnmatched : "#FFFFFF",
                                        borderRight: `2px solid ${catColour}`,
                                        borderBottom: `1px solid #dee2e6`,
                                    }}
                                ></td>
                                {skillLevel && (
                                    <UncontrolledTooltip
                                        target={`lvGap${skillsCode}${extraFrameworkLevelId}`}
                                        placement="bottom"
                                        dangerouslySetInnerHTML={{
                                            __html: skillLevelDescription,
                                        }}
                                    ></UncontrolledTooltip>
                                )}
                            </React.Fragment>
                        ))}
                </React.Fragment>
            );
        }
    }

    renderModalEditSkill = () => {
        const { editedCategory, editedSubCategory, editedSkill, editedSkillProfiles, skillValueOptions, editExtraFrameworkSkill, badgeRequest } = this.state;
        const { extraFrameworkCapabilities, badgeCertification, maxNumberOfBadgeRequestSkill } = this.props;

        let capabilityValueOptions = [];

        if (editExtraFrameworkSkill) {
            capabilityValueOptions = extraFrameworkCapabilities.map((extraFrameworkCapability) => {
                return {
                    value: extraFrameworkCapability.capability,
                    numericVal: extraFrameworkCapability.weight,
                    labelVal: extraFrameworkCapability.capability,
                    label: extraFrameworkCapability.capability,
                };
            });
        }

        let maxNumberExceeded = false;
        let numberBadgeRequested = 0;

        if (badgeCertification) {
            let requestCertificationEntries = badgeRequest && badgeRequest.requestCertificationEntries ? [...badgeRequest.requestCertificationEntries] : [];
            requestCertificationEntries.forEach((requestCertificationEntry) => {
                let stringVal = requestCertificationEntry.storedStringVal ? requestCertificationEntry.storedStringVal : requestCertificationEntry.editedStringVal ? requestCertificationEntry.editedStringVal : requestCertificationEntry.stringVal ? requestCertificationEntry.stringVal : "N";

                if (stringVal != "N") {
                    numberBadgeRequested += 1;
                }
            });

            maxNumberExceeded = numberBadgeRequested >= maxNumberOfBadgeRequestSkill;
        }

        if (this.state.editSkill) {
            return (
                <Modal isOpen={this.state.editSkill} toggle={this.togleEditSkillProfile}>
                    <ModalHeader toggle={this.togleEditSkillProfile}>
                        <ContentTranslator page="SfiaProfile" name="EditSkillTitle" contentText="Edit Skill" />
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "500px" }}>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr valign="top">
                                            <td colSpan={editedSkill.levelSelection && editedSkill.levelSelection == "Single" ? 4 : 3} style={{ width: "100%", backgroundColor: editedCategory.colour }} className="td-edit-skill-header">
                                                <ContentTranslator page="Categories" name={editedCategory.name} contentText={editedCategory.name} />
                                            </td>
                                        </tr>
                                        <tr valign="top">
                                            <td colSpan={editedSkill.levelSelection && editedSkill.levelSelection == "Single" ? 4 : 3} style={{ width: "100%", backgroundColor: editedSubCategory.colour }} className="td-edit-skill-subCategory-header">
                                                <ContentTranslator page="SubCategories" name={editedSubCategory.name} contentText={editedSubCategory.name} />
                                            </td>
                                        </tr>
                                        <tr valign="top">
                                            <td colSpan={editedSkill.levelSelection && editedSkill.levelSelection == "Single" ? 4 : 3} style={{ width: "100%", backgroundColor: editedSubCategory.skillColour }} className="td-edit-skill">
                                                <ContentTranslator page="Skills" name={editedSkill.name} contentText={editedSkill.name} />
                                            </td>
                                        </tr>
                                        <tr valign="top">
                                            <td colSpan={editedSkill.levelSelection && editedSkill.levelSelection == "Single" ? 4 : 3} style={{ width: "100%" }} className="td-edit-skill">
                                                <ContentTranslator page="Skills" name={`${editedSkill.name} Description`} contentText={editedSkill.description} />
                                            </td>
                                        </tr>
                                        {editedSkill.levels.map((level, index) => {
                                            let skill = null;

                                            if (!badgeCertification) {
                                                skill = editedSkillProfiles.find((it) => it.skillCode == editedSkill.skillsCode && it.level == level.level);
                                            } else {
                                                skill = badgeRequest && badgeRequest.requestCertificationEntries && badgeRequest.requestCertificationEntries.length > 0 ? badgeRequest.requestCertificationEntries.find((it) => it.skillsCode == editedSkill.skillsCode && it.level == level.level) : null;
                                            }

                                            const singleLevelSelectionChecked = editedSkill.levelSelection && editedSkill.levelSelection == "Single" ? (skill && skill.singleLevelSelectionChecked ? true : false) : true;

                                            return (
                                                <tr key={index} valign="top">
                                                    <td style={{ width: "5%" }} className="td-edit-profile-skill">
                                                        {level.level}
                                                    </td>
                                                    <td style={{ width: editedSkill.levelSelection && editedSkill.levelSelection == "Single" ? "60%" : "65%" }} className="td-edit-profile-skill">
                                                        <ContentTranslator page="SkillLevels" name={`${editedSkill.skillsCode} ${level.level} Description`} contentText={level.description} />
                                                    </td>
                                                    {editedSkill.levelSelection && editedSkill.levelSelection == "Single" && (
                                                        <React.Fragment>
                                                            <td style={{ width: "5%" }} className="td-edit-profile-skill">
                                                                <FormGroup check>
                                                                    <Input type="radio" value={level.level} name={`rad${editedSkill.id}`} checked={singleLevelSelectionChecked} onChange={() => this.handleRadioChange()} onClick={() => this.handleRadioSingleSelectionClick(level.level)} />
                                                                </FormGroup>
                                                            </td>
                                                        </React.Fragment>
                                                    )}
                                                    <td style={{ width: "30%" }} className="td-edit-profile-skill">
                                                        <FormGroup>
                                                            {editExtraFrameworkSkill ? (
                                                                <Select
                                                                    value={capabilityValueOptions.find((it) => it.numericVal == (skill ? (skill.editedNumericVal != undefined ? skill.editedNumericVal : skill.numericVal) : 0))}
                                                                    menuPlacement={index == editedSkill.levels.length - 1 ? "top" : "auto"}
                                                                    onChange={(value) =>
                                                                        this.handleChangeSkillLevel(
                                                                            skill
                                                                                ? {
                                                                                      category: editedCategory.name,
                                                                                      subCategory: editedSubCategory.name,
                                                                                      skill: editedSkill.name,
                                                                                      id: skill.id,
                                                                                      skillsCode: badgeCertification ? skill.skillsCode : skill.skillCode,
                                                                                      skillLevel: skill.level,
                                                                                      isExtraFramework: true,
                                                                                      skillExtraFrameworkLevel: skill.extraFrameworkLevel,
                                                                                      skillExtraFrameworkLevelId: skill.extraFrameworkLevelId,
                                                                                      value: value.value,
                                                                                      labelVal: value.labelVal,
                                                                                      numericVal: value.numericVal,
                                                                                  }
                                                                                : {
                                                                                      category: editedCategory.name,
                                                                                      subCategory: editedSubCategory.name,
                                                                                      skill: editedSkill.name,
                                                                                      skillsCode: editedSkill.skillsCode,
                                                                                      skillLevel: level.level,
                                                                                      isExtraFramework: true,
                                                                                      skillExtraFrameworkLevel: level.extraFrameworkLevel,
                                                                                      skillExtraFrameworkLevelId: level.extraFrameworkLevelId,
                                                                                      value: value.value,
                                                                                      labelVal: value.labelVal,
                                                                                      numericVal: value.numericVal,
                                                                                  }
                                                                        )
                                                                    }
                                                                    options={capabilityValueOptions}
                                                                    isDisabled={!singleLevelSelectionChecked || (maxNumberExceeded && (skill ? (skill.editedNumericVal != undefined ? skill.editedNumericVal : skill.numericVal) : 0) == 0)}
                                                                />
                                                            ) : (
                                                                <Select
                                                                    value={skillValueOptions.find((it) => it.value == (skill ? (skill.storedStringVal ? skill.storedStringVal : skill.editedStringVal ? skill.editedStringVal : skill.stringVal ? skill.stringVal : "N") : "N"))}
                                                                    menuPlacement={index == editedSkill.levels.length - 1 ? "top" : "auto"}
                                                                    onChange={(value) =>
                                                                        this.handleChangeSkillLevel(
                                                                            skill
                                                                                ? {
                                                                                      category: editedCategory.name,
                                                                                      subCategory: editedSubCategory.name,
                                                                                      skill: editedSkill.name,
                                                                                      id: skill.id,
                                                                                      skillsCode: badgeCertification ? skill.skillsCode : skill.skillCode,
                                                                                      skillLevel: skill.level,
                                                                                      value: value.value,
                                                                                      labelVal: value.labelVal,
                                                                                      numericVal: value.numericVal,
                                                                                  }
                                                                                : {
                                                                                      category: editedCategory.name,
                                                                                      subCategory: editedSubCategory.name,
                                                                                      skill: editedSkill.name,
                                                                                      skillsCode: editedSkill.skillsCode,
                                                                                      skillLevel: level.level,
                                                                                      value: value.value,
                                                                                      labelVal: value.labelVal,
                                                                                      numericVal: value.numericVal,
                                                                                  }
                                                                        )
                                                                    }
                                                                    options={badgeCertification ? skillValueOptions.filter((it) => it.value != "NA") : skillValueOptions}
                                                                    isDisabled={!singleLevelSelectionChecked || (maxNumberExceeded && (skill ? (skill.storedStringVal ? skill.storedStringVal : skill.editedStringVal ? skill.editedStringVal : skill.stringVal ? skill.stringVal : "N") : "N") == "N")}
                                                                />
                                                            )}
                                                        </FormGroup>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.togleEditSkillProfile}>
                            <ContentTranslator page="SfiaProfile" name="EditSkillButtonClose" contentText="Close" />
                        </Button>{" "}
                        <Button color="primary" onClick={() => this.handleSaveEditProfileClick()}>
                            <ContentTranslator page="SfiaProfile" name="EditSkillButtonSave" contentText="Save changes" />
                        </Button>
                    </ModalFooter>
                </Modal>
            );
        } else {
            return "";
        }
    };

    handleChangeSkillLevel(skill) {
        const { badgeCertification } = this.props;
        let badgeRequest = this.state.badgeRequest;
        let requestCertificationEntries = badgeRequest && badgeRequest.requestCertificationEntries ? [...badgeRequest.requestCertificationEntries] : [];

        let editedSkillProfiles = this.state.editedSkillProfiles;
        let changedSkill = null;

        if (!badgeCertification) {
            if (skill.id) {
                changedSkill = editedSkillProfiles.find((it) => it.id == skill.id);
                if (changedSkill) {
                    changedSkill.editedStringVal = skill.value;
                    changedSkill.editedNumericVal = skill.numericVal;
                }
            } else {
                changedSkill = editedSkillProfiles.find((it) => it.skillCode == skill.skillsCode && it.level == skill.skillLevel);
                if (changedSkill) {
                    changedSkill.editedStringVal = skill.value;
                    changedSkill.editedNumericVal = skill.numericVal;
                } else {
                    const addedSkill = {
                        category: skill.category,
                        subCategory: skill.subCategory,
                        skill: skill.skill,
                        skillCode: skill.skillsCode,
                        level: skill.skillLevel,
                        editedStringVal: skill.value,
                        editedLabelVal: skill.labelVal,
                        editedNumericVal: skill.numericVal,
                        isExtraFramework: skill.isExtraFramework ? skill.isExtraFramework : false,
                        extraFrameworkLevel: skill.skillExtraFrameworkLevel,
                        extraFrameworkLevelId: skill.skillExtraFrameworkLevelId,
                    };

                    editedSkillProfiles.push(addedSkill);
                }
            }

            this.setState({ editedSkillProfiles });
        } else {
            if (skill.id) {
                changedSkill = requestCertificationEntries.find((it) => it.id == skill.id);
                if (badgeRequest) {
                    changedSkill.storedStringVal = skill.value;
                    changedSkill.storedNumericVal = skill.numericVal;
                }
            } else {
                changedSkill = requestCertificationEntries.find((it) => it.skillsCode == skill.skillsCode && it.level == skill.skillLevel);
                if (changedSkill) {
                    changedSkill.storedStringVal = skill.value;
                    changedSkill.storedNumericVal = skill.numericVal;
                } else {
                    const addedSkill = {
                        category: skill.category,
                        subCategory: skill.subCategory,
                        skill: skill.skill,
                        skillsCode: skill.skillsCode,
                        level: skill.skillLevel,
                        storedStringVal: skill.value,
                        storedLabelVal: skill.labelVal,
                        storedNumericVal: skill.numericVal,
                        isExtraFramework: skill.isExtraFramework ? skill.isExtraFramework : false,
                        extraFrameworkLevel: skill.skillExtraFrameworkLevel,
                        extraFrameworkLevelId: skill.skillExtraFrameworkLevelId,
                    };

                    requestCertificationEntries.push(addedSkill);
                }
            }
            badgeRequest.requestCertificationEntries = [...requestCertificationEntries];
            this.setState({ badgeRequest });
        }
    }

    openEditSkillAttribute(editedCategory, editedSubCategory, editedSkill) {
        this.setState({ editAttribute: true, editedCategory, editedSubCategory, editedSkill });
    }

    togleEditSkillAttribute() {
        const { editAttribute } = this.state;
        this.setState({ editAttribute: !editAttribute });
    }

    updateSkillAttributes = (attributes) => {
        const account = this.state.account;

        if (account) {
            account.attributes = attributes;
            this.setState({ account, editAttribute: false });
        }
    };

    openEditSkillComment = (editedCategory, editedSubCategory, skillLevelDescription, skillProfile, allowEditComment) => {
        this.setState({ editComment: true, editedCategory, editedSubCategory, skillLevelDescription, editedSkill: skillProfile, allowEditComment });
    };

    togleEditSkillComment = () => {
        const { editComment } = this.state;
        this.setState({ editComment: !editComment });
    };

    updateSkillComment = () => {
        this.setState({ editComment: false, editedSkill: null });
    };

    openSfiaGuidance = (skill) => {
        this.setState({ showSfiaGuidance: true, editedSkill: skill });
    };

    togleOpenSfiaGuidance = () => {
        const { showSfiaGuidance } = this.state;
        this.setState({ showSfiaGuidance: !showSfiaGuidance });
    };

    renderModalSfiaGuidance = () => {
        const { language } = this.props;
        const { showSfiaGuidance, editedSkill } = this.state;

        const guidance = showSfiaGuidance && language && editedSkill && editedSkill.guidances && editedSkill.guidances.filter((it) => it.languageId == language.id).length > 0 ? editedSkill.guidances.filter((it) => it.languageId == language.id)[0] : null;

        if (showSfiaGuidance && language && guidance) {
            return (
                <Modal isOpen={showSfiaGuidance} toggle={this.togleOpenSfiaGuidance} size="lg">
                    <ModalHeader toggle={this.togleOpenSfiaGuidance}>{`${this.contentTranslator(this.props.contentTranslations, "Skills", editedSkill.name, editedSkill.name)} ${editedSkill.skillsCode} ${this.contentTranslator(
                        this.props.contentTranslations,
                        "SfiaAll",
                        "Guidance Notes",
                        "Guidance Notes"
                    )}`}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "500px" }}>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr valign="top">
                                            <td style={{ width: "100%", fontSize: "14px" }}>{parse(guidance.guidance)}</td>
                                        </tr>
                                        {editedSkill.levels
                                            .filter((it) => it.guidances && it.guidances.length > 0)
                                            .map((level, index) => {
                                                const levelGuidance = level.guidances && level.guidances.filter((it) => it.languageId == language.id).length > 0 && level.guidances.filter((it) => it.languageId == language.id)[0];
                                                return (
                                                    <tr key={index} valign="top">
                                                        <td style={{ width: "100%", fontSize: "14px" }}>
                                                            <b>
                                                                <ContentTranslator page="SfiaAll" name="Level" contentText="Level" /> {level.level} :
                                                            </b>
                                                            <br />
                                                            {parse(levelGuidance.guidance)}
                                                            <br />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.togleOpenSfiaGuidance}>
                            <ContentTranslator page="SfiaProfile" name="EditSkillButtonClose" contentText="Close" />
                        </Button>
                    </ModalFooter>
                </Modal>
            );
        } else {
            return "";
        }
    };

    handleAddAction = (skill) => {
        this.setState({ openAddActionDialog: true, actionedSkill: skill });
    };

    handleCancelAddAction = () => {
        this.setState({ openAddActionDialog: false, actionedSkill: null });
    };

    handleActionPlanAdded = (addedAction) => {
        this.setState({ openAddActionDialog: false, actionedSkill: null });
        this.props.handleActionPlanAdded(addedAction);
    };

    renderModalAddAction = () => {
        const { selectedJobMatchType, actions, actionSkills } = this.props;
        const { actionedSkill, openAddActionDialog, jobLorProfiles, jobSkillProfiles, lorProfiles, skillProfiles } = this.state;

        let selectedDefaultSkills;

        if (actionedSkill && selectedJobMatchType && selectedJobMatchType.value != "None") {
            if (jobSkillProfiles) {
                const gapJobSkillProfiles = jobSkillProfiles.filter((it) => it.skillCode == actionedSkill.skillsCode && !it.isFully);

                if (gapJobSkillProfiles.length > 0) {
                    selectedDefaultSkills = [
                        ...gapJobSkillProfiles.map((skill) => {
                            return {
                                skillName: skill.skill,
                                skillCode: skill.skillCode,
                                skillLevel: skill.level,
                                skillType: "Skill",
                            };
                        }),
                    ];
                }

                jobSkillProfiles.forEach((jobSkillProfile) => {
                    const skillProfile = skillProfiles && skillProfiles.find((x) => x.skillCode == jobSkillProfile.skillCode && x.level == jobSkillProfile.level && (x.numericVal == 1 || x.stringVal == "M"));
                    const plan = actions ? actions.find((it) => it.skills.some((skill) => skill.code == jobSkillProfile.skillCode && skill.level == jobSkillProfile.level)) : null;
                    const gapJobSkillProfile = gapJobSkillProfiles.find((it) => it.skillCode == jobSkillProfile.skillCode && it.level == jobSkillProfile.level);

                    if (!gapJobSkillProfile && (skillProfile || plan)) {
                        jobSkillProfile.actioned = true;
                    } else {
                        jobSkillProfile.actioned = false;
                    }
                });
            }

            if (jobLorProfiles) {
                jobLorProfiles.forEach((jobLorProfile) => {
                    const lorProfile = lorProfiles.find((it) => it.lorCode == jobLorProfile.lorCode && it.level >= jobLorProfile.level);
                    const plan = actions ? actions.find((it) => it.skills.some((skill) => skill.code == jobLorProfile.lorCode && skill.level >= jobLorProfile.level)) : null;
                    if (lorProfile || plan) {
                        jobLorProfile.actioned = true;
                    } else {
                        jobLorProfile.actioned = false;
                    }
                });
            }
        }

        let optionsJob = [];

        const jobMatches = this.props.jobMatches;
        const currentJob = jobMatches ? jobMatches.find((it) => it.jobType === "Current") : null;
        const futureJob = jobMatches ? jobMatches.find((it) => it.jobType === "Future") : null;

        if (currentJob) {
            optionsJob.push({
                value: currentJob.jobType + " - " + currentJob.jobTitle,
                label: this.contentTranslator(this.props.contentTranslations, "SfiaAll", currentJob.jobType, currentJob.jobType) + " - " + currentJob.jobTitle,
                type: currentJob.jobType,
                id: currentJob.jobId,
            });
        }

        if (futureJob) {
            optionsJob.push({
                value: futureJob.jobType + " - " + futureJob.jobTitle,
                label: this.contentTranslator(this.props.contentTranslations, "SfiaAll", futureJob.jobType, futureJob.jobType) + " - " + futureJob.jobTitle,
                type: futureJob.jobType,
                id: futureJob.jobId,
            });
        }

        return (
            <Modal isOpen={openAddActionDialog} size="lg">
                <ModalBody>
                    <div style={{ fontSize: "11px", fontFamily: "IBMPlexSans" }}>
                        <FormAddAction
                            showHiddenSkills={this.state.showHiddenSkills}
                            optionsActionTypes={this.props.actionTypes}
                            optionsActionSkills={actionSkills}
                            categories={this.props.categories}
                            jobLorProfiles={jobLorProfiles}
                            jobSkillProfiles={jobSkillProfiles}
                            lorProfiles={lorProfiles}
                            skillProfiles={skillProfiles}
                            handleClose={this.handleCancelAddAction}
                            handleActionPlanAdded={this.handleActionPlanAdded}
                            providers={this.props.selectedProviders}
                            optionsJob={optionsJob}
                            selectedDefaultSkills={selectedDefaultSkills}
                            generateAlert={this.props.generateAlert}
                        ></FormAddAction>
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    handleAddEvidence = (skill) => {
        this.setState({ openAddEvidenceDialog: true, actionedSkill: skill });
    };

    handleCancelAddEvidence = () => {
        this.setState({ openAddEvidenceDialog: false, actionedSkill: null });
    };

    handleEvidenceAdded = (addedEvidence) => {
        this.setState({ openAddEvidenceDialog: false, actionedSkill: null });
        this.props.handleEvidenceAdded(addedEvidence);
    };

    renderModalAddEvidence = () => {
        const { selectedJobMatchType, categories } = this.props;
        const { actionedSkill, openAddEvidenceDialog, lorProfiles, skillProfiles, jobSkillProfiles } = this.state;
        let defaultSelectedSkills;

        if (actionedSkill && selectedJobMatchType && selectedJobMatchType.value != "None") {
            console.log(jobSkillProfiles.filter((it) => it.skillCode == actionedSkill.skillsCode));

            const gapJobSkillProfiles = jobSkillProfiles.filter((it) => it.skillCode == actionedSkill.skillsCode && !it.isFully);

            const actionedSkillProfiles = skillProfiles && skillProfiles.filter((x) => gapJobSkillProfiles.find((it) => it.skillCode == x.skillCode && it.level == x.level) && x.skillCode == actionedSkill.skillsCode && x.stringVal != "N");
            if (actionedSkillProfiles.length > 0) {
                defaultSelectedSkills = actionedSkillProfiles.map((skill) => {
                    const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((it) => it.name == skill.skill && it.skillsCode == skill.skillCode)));
                    const subCategory = category && category.subCategories.find((it) => it.skills.some((it) => it.name == skill.skill && it.skillsCode == skill.skillCode));
                    const categorySkill = subCategory && subCategory.skills.find((it) => it.name == skill.skill && it.skillsCode == skill.skillCode);
                    const skillLevel = categorySkill && categorySkill.levels.find((it) => it.level == skill.level);

                    return {
                        skillLevelId: skillLevel ? skillLevel.id : null,
                        skill: skill.skill,
                        skillCode: skill.skillCode,
                        level: skill.level,
                    };
                });
            }
        }

        return (
            <Modal isOpen={openAddEvidenceDialog} size="lg">
                <ModalBody>
                    <div style={{ fontSize: "11px", fontFamily: "IBMPlexSans" }}>
                        <AddNewEvidenceForm
                            categories={this.props.categories}
                            lors={this.props.lors}
                            lorProfiles={lorProfiles}
                            skillProfiles={skillProfiles}
                            defaultSelectedSkills={defaultSelectedSkills}
                            handleClose={this.handleCancelAddEvidence}
                            handleEvidenceAdded={this.handleEvidenceAdded}
                            generateAlert={this.props.generateAlert}
                            showHiddenSkills={this.state.showHiddenSkills}
                        ></AddNewEvidenceForm>
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    render() {
        const { categories, skillProfiles, editedSkill, badgeRequest } = this.state;
        const { language, showAllSkill, showEditSkill, showHiddenSkill, showProposedAction, showSuggestedUpdate, highlightGap, loadingGap, isShowJobProfile, jobSkillAttributes, showExtraFramework, extraFrameworkLevels, extraFrameworkCapabilities, badgeCertification, showEditCertify, account } =
            this.props;

        const proposeSkills = showProposedAction ? (account && account.updateProfileEvidenceProposalSkills ? account.updateProfileEvidenceProposalSkills.filter((it) => it.type == "Skill") : []) : [];
        const suggestedSkills = showSuggestedUpdate ? (account && account.updateProfileSuggestionSkills ? account.updateProfileSuggestionSkills : []) : [];

        return (
            <div>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                <TableSkillProfilesEditAttribute
                    key={this.state.editedSkill ? "editAttribute" + this.state.editedSkill.id : "EmptySkillProfilesEditAttribute"}
                    editAttribute={this.state.editAttribute}
                    editedCategory={this.state.editedCategory}
                    editedSubCategory={this.state.editedSubCategory}
                    editedSkill={this.state.editedSkill}
                    togleEditSkillAttribute={this.togleEditSkillAttribute}
                    account={this.state.account}
                    updateSkillAttributes={this.updateSkillAttributes}
                    generateAlert={this.generateAlert}
                ></TableSkillProfilesEditAttribute>
                <TableSkillProfilesEditComment
                    key={this.state.editedSkill ? "editComment" + this.state.editedSkill.id : "EmptySkillProfilesEditComment"}
                    editComment={this.state.editComment}
                    editedCategory={this.state.editedCategory}
                    editedSubCategory={this.state.editedSubCategory}
                    editedSkillLevelDescription={this.state.skillLevelDescription}
                    editedSkill={this.state.editedSkill}
                    togleEditSkillComment={this.togleEditSkillComment}
                    updateSkillComment={this.updateSkillComment}
                    generateAlert={this.generateAlert}
                    isEndorser={this.props.managedPeople && this.props.selectedprofileType && this.props.selectedprofileType.value == "Endorsed" ? true : false}
                    allowEditComment={this.state.allowEditComment}
                ></TableSkillProfilesEditComment>
                {editedSkill && this.renderModalEditSkill()}
                {this.renderModalSfiaGuidance()}
                {this.renderModalSuggestEditLowerLevelProfileConfirmation()}
                {this.renderModalAddAction()}
                {this.renderModalAddEvidence()}
                {this.state.loading ? (
                    <div style={{ textAlign: "center" }}>
                        <Spinner animation="border" role="status" style={{ marginLeft: "20px" }}></Spinner>
                    </div>
                ) : (
                    <Table>
                        {categories
                            .filter((it) => (showExtraFramework ? it.isExtraFramework && it.isExtraFramework == true : !it.isExtraFramework && it.isExtraFramework != true))
                            .map((category, index) => {
                                var emptyLevels = [];
                                for (let i = extraFrameworkLevels.length + 1; i <= 7; i++) {
                                    emptyLevels.push(
                                        <React.Fragment key={`emptyLevel${category.id}${i}`}>
                                            <th
                                                colSpan={highlightGap ? 2 : 1}
                                                className={"th-fixwidth " + (i == 7 ? (showEditSkill || highlightGap || showEditCertify ? "" : " th-end") : "")}
                                                style={{
                                                    borderBottom: "0",
                                                    borderTop: "0",
                                                    backgroundColor: `${category.colour}`,
                                                    verticalAlign: "middle",
                                                }}
                                            ></th>
                                        </React.Fragment>
                                    );
                                }

                                let categoryFound = false;

                                if (!badgeCertification && !showProposedAction && !showSuggestedUpdate) {
                                    categoryFound = skillProfiles.filter((x) => x.category == category.name).length > 0 ? true : false;
                                } else if (!badgeCertification && showProposedAction && !showSuggestedUpdate) {
                                    categoryFound = proposeSkills.filter((x) => x.type == "Skill" && x.category == category.name).length > 0 ? true : false;
                                } else if (!badgeCertification && !showProposedAction && showSuggestedUpdate) {
                                    categoryFound = suggestedSkills.filter((x) => x.category == category.name).length > 0 ? true : false;
                                } else {
                                    if (badgeRequest && badgeRequest.requestCertificationEntries && badgeRequest.requestCertificationEntries.length > 0 && badgeRequest.requestCertificationEntries.filter((x) => x.category == category.name).length > 0) {
                                        categoryFound = true;
                                    } else if (badgeRequest && badgeRequest.requestEntries && badgeRequest.requestEntries.length > 0 && badgeRequest.requestEntries.filter((x) => x.category == category.name).length > 0) {
                                        categoryFound = true;
                                    }
                                }

                                const categoryJobSkillProfiles = this.props.jobSkillProfiles ? this.props.jobSkillProfiles.filter((it) => it.category == category.name) : [];

                                if (categoryFound || categoryJobSkillProfiles.length > 0 || showAllSkill || showEditSkill) {
                                    const catSkillProfiles = skillProfiles.filter((x) => x.category == category.name && (x.stringVal !== "N" || x.editedStringVal));
                                    let categoryHasValue = false;

                                    let catSkillProfilesCodeWithValue = skillProfiles.filter((it) => it.category == category.name && (it.stringVal !== "N" || it.editedStringVal)).map((skill) => skill.skillCode);
                                    const jobCatSkillProfilesCode = categoryJobSkillProfiles.map((skill) => skill.skillCode);

                                    const categorySkills = category.subCategories.flatMap((subCategory, index) => {
                                        return subCategory.skills.flatMap((skill, index) => {
                                            return skill;
                                        });
                                    });

                                    if (!badgeCertification && !showProposedAction && !showSuggestedUpdate) {
                                        categoryHasValue = skillProfiles.filter((c) => c.category == category.name && (c.stringVal !== "N" || c.editedStringVal)).length > 0 ? true : false;
                                        catSkillProfilesCodeWithValue = skillProfiles.filter((it) => it.category == category.name && (it.stringVal !== "N" || it.editedStringVal)).map((skill) => skill.skillCode);
                                    } else if (!badgeCertification && showProposedAction && !showSuggestedUpdate) {
                                        if (showExtraFramework) {
                                            if (category && category.subCategories && category.subCategories.length > 0) {
                                                category.subCategories.forEach((subCategory) => {
                                                    if (subCategory.skills && subCategory.skills.length > 0) {
                                                        subCategory.skills.forEach((skill) => {
                                                            const extraFrameworkSkillProfiles = skillProfiles.filter((x) => x.category == category.name && x.subCategory == subCategory.name && x.skill == skill.name && x.skillCode == skill.skillsCode && (x.stringVal !== "N" || x.editedStringVal));
                                                            const extraFrameworkProposeSkills = proposeSkills
                                                                .filter((x) => x.type == "Skill" && x.category == category.name && x.subCategory == subCategory.name && x.name == skill.name && x.code == skill.skillsCode)
                                                                .map((it) => {
                                                                    return {
                                                                        capability: it.capability,
                                                                        numericVal: it.capability == "Competent" ? 1 : it.capability == "Proficient" ? 0.5 : it.capability == "Knowledge" ? 0.25 : 0,
                                                                        category: it.category,
                                                                        code: it.code,
                                                                        id: it.id,
                                                                        level: it.level,
                                                                        name: it.name,
                                                                        subCategory: it.subCategory,
                                                                        type: it.type,
                                                                    };
                                                                });

                                                            if (skill.levelSelection == "Single") {
                                                                if (extraFrameworkProposeSkills.length > 0) {
                                                                    catSkillProfilesCodeWithValue.push(...extraFrameworkProposeSkills.map((it) => it.code));
                                                                }

                                                                if (extraFrameworkSkillProfiles.length == 0 && extraFrameworkProposeSkills.length > 0) {
                                                                    // No profile skills but have proposed skills
                                                                    categoryHasValue = true;
                                                                } else if (extraFrameworkSkillProfiles.filter((x) => extraFrameworkProposeSkills.some((y) => x.skillCode == y.code && (y.level > x.level || (x.level == y.level && y.numericVal > x.numericVal)))).length > 0) {
                                                                    // Have proposed skills with better capability
                                                                    categoryHasValue = true;
                                                                } else if (extraFrameworkProposeSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.code == y.skillCode)).length > 0) {
                                                                    // Have proposed skills which not exist in profile skills
                                                                    categoryHasValue = true;
                                                                }
                                                            } else {
                                                                if (extraFrameworkProposeSkills.length > 0) {
                                                                    catSkillProfilesCodeWithValue.push(...extraFrameworkProposeSkills.map((it) => it.code));
                                                                }

                                                                if (extraFrameworkSkillProfiles.length == 0 && extraFrameworkProposeSkills.length > 0) {
                                                                    // No profile skills but have proposed skills
                                                                    categoryHasValue = true;
                                                                } else if (extraFrameworkSkillProfiles.filter((x) => extraFrameworkProposeSkills.some((y) => x.skillCode == y.code && x.level == y.level && y.numericVal > x.numericVal)).length > 0) {
                                                                    // Have proposed skills with better capability
                                                                    categoryHasValue = true;
                                                                } else if (extraFrameworkProposeSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.code == y.skillCode && x.level == y.level)).length > 0) {
                                                                    // Have proposed skills which not exist in profile skills
                                                                    categoryHasValue = true;
                                                                }
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        } else {
                                            const catProposeSkills = proposeSkills
                                                .filter((x) => x.type == "Skill" && x.category == category.name)
                                                .map((it) => {
                                                    return {
                                                        capability: it.capability,
                                                        numericVal: it.capability == "Competent" ? 1 : it.capability == "Proficient" ? 0.5 : it.capability == "Knowledge" ? 0.25 : 0,
                                                        category: it.category,
                                                        code: it.code,
                                                        id: it.id,
                                                        level: it.level,
                                                        name: it.name,
                                                        subCategory: it.subCategory,
                                                        type: it.type,
                                                    };
                                                });

                                            if (catProposeSkills.length > 0) {
                                                catSkillProfilesCodeWithValue.push(...catProposeSkills.map((catProposeSkill) => catProposeSkill.code));
                                            }

                                            if (catSkillProfiles.length == 0 && catProposeSkills.length > 0) {
                                                // No profile skills but have proposed skills
                                                categoryHasValue = true;
                                            } else if (catSkillProfiles.filter((x) => catProposeSkills.some((y) => x.skillCode == y.code && x.level == y.level && y.numericVal > x.numericVal)).length > 0) {
                                                // Have proposed skills with better capability
                                                categoryHasValue = true;
                                            } else if (catProposeSkills.filter((x) => !catSkillProfiles.some((y) => x.code == y.skillCode && x.level == y.level)).length > 0) {
                                                // Have proposed skills which not exist in profile skills
                                                categoryHasValue = true;
                                            }
                                        }
                                    } else if (!badgeCertification && !showProposedAction && showSuggestedUpdate) {
                                        if (showExtraFramework) {
                                            if (category && category.subCategories && category.subCategories.length > 0) {
                                                category.subCategories.forEach((subCategory) => {
                                                    if (subCategory.skills && subCategory.skills.length > 0) {
                                                        subCategory.skills.forEach((skill) => {
                                                            const extraFrameworkSkillProfiles = skillProfiles.filter((x) => x.category == category.name && x.subCategory == subCategory.name && x.skill == skill.name && x.skillCode == skill.skillsCode && (x.stringVal !== "N" || x.editedStringVal));
                                                            const extraFrameworkSuggestedSkills = suggestedSkills
                                                                .filter((x) => x.category == category.name && x.subCategory == subCategory.name && x.skill == skill.name && x.skillCode == skill.skillsCode)
                                                                .map((it) => {
                                                                    return {
                                                                        numericVal: it.numericVal,
                                                                        category: it.category,
                                                                        subCategory: it.subCategory,
                                                                        id: it.id,
                                                                        skill: it.skill,
                                                                        skillCode: it.skillCode,
                                                                        level: it.level,
                                                                    };
                                                                });

                                                            if (skill.levelSelection == "Single") {
                                                                if (extraFrameworkSuggestedSkills.length > 0) {
                                                                    catSkillProfilesCodeWithValue.push(...extraFrameworkSuggestedSkills.map((it) => it.code));
                                                                }
                                                                if (extraFrameworkSkillProfiles.length == 0 && extraFrameworkSuggestedSkills.length > 0) {
                                                                    // No profile skills but have proposed skills
                                                                    categoryHasValue = true;
                                                                } else if (extraFrameworkSkillProfiles.filter((x) => extraFrameworkSuggestedSkills.some((y) => x.skillCode == y.skillCode && (y.level > x.level || (x.level == y.level && y.numericVal > x.numericVal)))).length > 0) {
                                                                    // Have proposed skills with better capability
                                                                    categoryHasValue = true;
                                                                } else if (extraFrameworkSuggestedSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.skillCode == y.skillCode)).length > 0) {
                                                                    // Have proposed skills which not exist in profile skills
                                                                    categoryHasValue = true;
                                                                }
                                                            } else {
                                                                if (extraFrameworkSuggestedSkills.length > 0) {
                                                                    catSkillProfilesCodeWithValue.push(...extraFrameworkSuggestedSkills.map((it) => it.code));
                                                                }
                                                                if (extraFrameworkSkillProfiles.length == 0 && extraFrameworkSuggestedSkills.length > 0) {
                                                                    // No profile skills but have proposed skills
                                                                    categoryHasValue = true;
                                                                } else if (extraFrameworkSkillProfiles.filter((x) => extraFrameworkSuggestedSkills.some((y) => x.skillCode == y.skillCode && x.level == y.level && y.numericVal > x.numericVal)).length > 0) {
                                                                    // Have proposed skills with better capability
                                                                    categoryHasValue = true;
                                                                } else if (extraFrameworkSuggestedSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.skillCode == y.skillCode && x.level == y.level)).length > 0) {
                                                                    // Have proposed skills which not exist in profile skills
                                                                    categoryHasValue = true;
                                                                }
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        } else {
                                            const catSuggestedSkills = suggestedSkills
                                                .filter((x) => x.category == category.name)
                                                .map((it) => {
                                                    return {
                                                        numericVal: it.numericVal,
                                                        category: it.category,
                                                        subCategory: it.subCategory,
                                                        id: it.id,
                                                        skill: it.skill,
                                                        skillCode: it.skillCode,
                                                        level: it.level,
                                                    };
                                                });

                                            if (catSuggestedSkills.length > 0) {
                                                catSkillProfilesCodeWithValue.push(...catSuggestedSkills.map((catSuggestedSkill) => catSuggestedSkill.skillCode));
                                            }

                                            if (catSkillProfiles.length == 0 && catSuggestedSkills.length > 0) {
                                                // No profile skills but have proposed skills
                                                categoryHasValue = true;
                                            } else if (catSkillProfiles.filter((x) => catSuggestedSkills.some((y) => x.skillCode == y.skillCode && x.level == y.level && y.numericVal > x.numericVal)).length > 0) {
                                                // Have proposed skills with better capability
                                                categoryHasValue = true;
                                            } else if (catSuggestedSkills.filter((x) => !catSkillProfiles.some((y) => x.skillCode == y.skillCode && x.level == y.level)).length > 0) {
                                                // Have proposed skills which not exist in profile skills
                                                categoryHasValue = true;
                                            }
                                        }
                                    } else {
                                        if (
                                            badgeRequest &&
                                            badgeRequest.requestCertificationEntries &&
                                            badgeRequest.requestCertificationEntries.length > 0 &&
                                            badgeRequest.requestCertificationEntries.filter((x) => x.category == category.name && (x.stringVal !== "N" || x.editedStringVal)).length > 0
                                        ) {
                                            catSkillProfilesCodeWithValue.push(...badgeRequest.requestCertificationEntries.filter((x) => x.category == category.name && (x.stringVal !== "N" || x.editedStringVal)).map((it) => it.skillsCode));
                                            categoryHasValue = true;
                                        } else if (badgeRequest && badgeRequest.requestEntries && badgeRequest.requestEntries.length > 0 && badgeRequest.requestEntries.filter((x) => x.category == category.name && (x.stringVal !== "N" || x.editedStringVal)).length > 0) {
                                            catSkillProfilesCodeWithValue.push(...badgeRequest.requestEntries.filter((x) => x.category == category.name && (x.stringVal !== "N" || x.editedStringVal)).map((it) => it.skillsCode));
                                            categoryHasValue = true;
                                        }
                                    }

                                    const checkedCatSkills = showAllSkill || showEditSkill ? categorySkills : categorySkills.filter((it) => catSkillProfilesCodeWithValue.includes(it.skillsCode) || jobCatSkillProfilesCode.includes(it.skillsCode));
                                    const categoryHidden = !checkedCatSkills.some((skill) => !skill.isHidden) && !showHiddenSkill;

                                    if (categoryHasValue || categoryJobSkillProfiles.length > 0 || showAllSkill || showEditSkill) {
                                        return (
                                            <React.Fragment key={index}>
                                                <thead className="tableHeader" style={{ display: !categoryHidden ? "" : "none" }}>
                                                    <tr>
                                                        <th
                                                            id={"group" + category.name.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                            colSpan={2}
                                                            className={"th-start"}
                                                            style={{ borderBottom: "0", borderTop: "0", backgroundColor: `${category.colour}` }}
                                                        >
                                                            <ContentTranslator page="Categories" name={category.name} contentText={category.name} />
                                                        </th>
                                                        <UncontrolledTooltip
                                                            target={"group" + category.name.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                            placement="bottom"
                                                            dangerouslySetInnerHTML={{
                                                                __html: this.contentTranslator(this.props.contentTranslations, "Categories", `${category.name} Description`, category.description),
                                                            }}
                                                        ></UncontrolledTooltip>
                                                        {!category.isExtraFramework == true ? (
                                                            <React.Fragment>
                                                                <th colSpan={highlightGap ? 2 : 1} className={"th-fixwidth "} style={{ borderBottom: "0", borderTop: "0", backgroundColor: `${category.colour}`, textAlign: "center" }}>
                                                                    1
                                                                </th>
                                                                <th colSpan={highlightGap ? 2 : 1} className={"th-fixwidth "} style={{ borderBottom: "0", borderTop: "0", backgroundColor: `${category.colour}`, textAlign: "center" }}>
                                                                    2
                                                                </th>
                                                                <th colSpan={highlightGap ? 2 : 1} className={"th-fixwidth "} style={{ borderBottom: "0", borderTop: "0", backgroundColor: `${category.colour}`, textAlign: "center" }}>
                                                                    3
                                                                </th>
                                                                <th colSpan={highlightGap ? 2 : 1} className={"th-fixwidth "} style={{ borderBottom: "0", borderTop: "0", backgroundColor: `${category.colour}`, textAlign: "center" }}>
                                                                    4
                                                                </th>
                                                                <th colSpan={highlightGap ? 2 : 1} className={"th-fixwidth "} style={{ borderBottom: "0", borderTop: "0", backgroundColor: `${category.colour}`, textAlign: "center" }}>
                                                                    5
                                                                </th>
                                                                <th colSpan={highlightGap ? 2 : 1} className={"th-fixwidth "} style={{ borderBottom: "0", borderTop: "0", backgroundColor: `${category.colour}`, textAlign: "center" }}>
                                                                    6
                                                                </th>
                                                                <th
                                                                    colSpan={highlightGap ? 2 : 1}
                                                                    className={"th-fixwidth " + (showEditSkill || highlightGap || showEditCertify ? "" : " th-end")}
                                                                    style={{ borderBottom: "0", borderTop: "0", backgroundColor: `${category.colour}`, textAlign: "center" }}
                                                                >
                                                                    7
                                                                </th>
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                {extraFrameworkLevels.map((extraFrameworkLevel, levelIndex) => {
                                                                    return (
                                                                        <React.Fragment key={levelIndex}>
                                                                            <th
                                                                                colSpan={highlightGap ? 2 : 1}
                                                                                className={"th-fixwidth-extra " + (extraFrameworkLevels.length == 7 && levelIndex == extraFrameworkLevels.length - 1 ? (showEditSkill || highlightGap || showEditCertify ? "" : " th-end") : "")}
                                                                                style={{
                                                                                    borderBottom: "0",
                                                                                    borderTop: "0",
                                                                                    backgroundColor: `${category.colour}`,
                                                                                    verticalAlign: "middle",
                                                                                    textAlign: "center",
                                                                                }}
                                                                            >
                                                                                {extraFrameworkLevel.level}
                                                                            </th>
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                                {emptyLevels}
                                                            </React.Fragment>
                                                        )}

                                                        {showEditSkill || showEditCertify ? (
                                                            highlightGap ? (
                                                                <React.Fragment>
                                                                    <th className={"th-fixwidth "} style={{ borderBottom: "0", borderTop: "0", backgroundColor: `${category.colour}` }}></th>
                                                                    <th className={"th-fixwidth-50 " + " th-end"} style={{ borderBottom: "0", borderTop: "0", backgroundColor: `${category.colour}` }}></th>
                                                                </React.Fragment>
                                                            ) : (
                                                                <th className={"th-fixwidth " + " th-end"} style={{ borderBottom: "0", borderTop: "0", backgroundColor: `${category.colour}` }}></th>
                                                            )
                                                        ) : (
                                                            highlightGap && <th className={"th-fixwidth-50 " + " th-end"} style={{ borderBottom: "0", borderTop: "0", backgroundColor: `${category.colour}` }}></th>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody style={{ display: !categoryHidden ? "" : "none" }}>
                                                    {category.subCategories.map((subCategory, index) => {
                                                        const subCatSkillProfiles = skillProfiles.filter((it) => it.subCategory === subCategory.name);

                                                        let subCatSkillProfilesCodeWithValue = subCatSkillProfiles.filter((it) => it.stringVal !== "N" || it.editedStringVal).map((skill) => skill.skillCode);
                                                        let subCategoryHasValue = false;

                                                        if (!badgeCertification && !showProposedAction && !showSuggestedUpdate) {
                                                            subCatSkillProfilesCodeWithValue = subCatSkillProfiles.filter((it) => it.stringVal !== "N" || it.editedStringVal).map((skill) => skill.skillCode);
                                                            subCategoryHasValue = subCatSkillProfiles.filter((c) => c.stringVal !== "N" || c.editedStringVal).length > 0 ? true : false;
                                                        } else if (!badgeCertification && showProposedAction && !showSuggestedUpdate) {
                                                            if (showExtraFramework) {
                                                                if (subCategory.skills && subCategory.skills.length > 0) {
                                                                    subCategory.skills.forEach((skill) => {
                                                                        const extraFrameworkSkillProfiles = skillProfiles.filter(
                                                                            (x) => x.category == category.name && x.subCategory == subCategory.name && x.skill == skill.name && x.skillCode == skill.skillsCode && (x.stringVal !== "N" || x.editedStringVal)
                                                                        );
                                                                        const extraFrameworkProposeSkills = proposeSkills
                                                                            .filter((x) => x.type == "Skill" && x.category == category.name && x.subCategory == subCategory.name && x.name == skill.name && x.code == skill.skillsCode)
                                                                            .map((it) => {
                                                                                return {
                                                                                    capability: it.capability,
                                                                                    numericVal: it.capability == "Competent" ? 1 : it.capability == "Proficient" ? 0.5 : it.capability == "Knowledge" ? 0.25 : 0,
                                                                                    category: it.category,
                                                                                    code: it.code,
                                                                                    id: it.id,
                                                                                    level: it.level,
                                                                                    name: it.name,
                                                                                    subCategory: it.subCategory,
                                                                                    type: it.type,
                                                                                };
                                                                            });

                                                                        if (skill.levelSelection == "Single") {
                                                                            if (extraFrameworkSkillProfiles.length == 0 && extraFrameworkProposeSkills.length > 0) {
                                                                                // No profile skills but have proposed skills
                                                                                subCategoryHasValue = true;
                                                                            } else if (extraFrameworkSkillProfiles.filter((x) => extraFrameworkProposeSkills.some((y) => x.skillCode == y.code && (y.level > x.level || (x.level == y.level && y.numericVal > x.numericVal)))).length > 0) {
                                                                                // Have proposed skills with better capability
                                                                                subCategoryHasValue = true;
                                                                            } else if (extraFrameworkProposeSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.code == y.skillCode)).length > 0) {
                                                                                // Have proposed skills which not exist in profile skills
                                                                                subCategoryHasValue = true;
                                                                            }
                                                                        } else {
                                                                            if (extraFrameworkSkillProfiles.length == 0 && extraFrameworkProposeSkills.length > 0) {
                                                                                // No profile skills but have proposed skills
                                                                                subCategoryHasValue = true;
                                                                            } else if (extraFrameworkSkillProfiles.filter((x) => extraFrameworkProposeSkills.some((y) => x.skillCode == y.code && x.level == y.level && y.numericVal > x.numericVal)).length > 0) {
                                                                                // Have proposed skills with better capability
                                                                                subCategoryHasValue = true;
                                                                            } else if (extraFrameworkProposeSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.code == y.skillCode && x.level == y.level)).length > 0) {
                                                                                // Have proposed skills which not exist in profile skills
                                                                                subCategoryHasValue = true;
                                                                            }
                                                                        }
                                                                    });
                                                                }
                                                            } else {
                                                                const subCatProposeSkills = proposeSkills
                                                                    .filter((x) => x.type == "Skill" && x.subCategory == subCategory.name)
                                                                    .map((it) => {
                                                                        return {
                                                                            capability: it.capability,
                                                                            numericVal: it.capability == "Competent" ? 1 : it.capability == "Proficient" ? 0.5 : it.capability == "Knowledge" ? 0.25 : 0,
                                                                            category: it.category,
                                                                            code: it.code,
                                                                            id: it.id,
                                                                            level: it.level,
                                                                            name: it.name,
                                                                            subCategory: it.subCategory,
                                                                            type: it.type,
                                                                        };
                                                                    });

                                                                if (subCatSkillProfiles.length == 0 && subCatProposeSkills.length > 0) {
                                                                    // No profile skills but have proposed skills
                                                                    subCategoryHasValue = true;
                                                                } else if (subCatSkillProfiles.filter((x) => subCatProposeSkills.some((y) => x.skillCode == y.code && x.level == y.level && y.numericVal > x.numericVal)).length > 0) {
                                                                    // Have proposed skills with better capability
                                                                    subCategoryHasValue = true;
                                                                } else if (subCatProposeSkills.filter((x) => !subCatSkillProfiles.some((y) => x.code == y.skillCode && x.level == y.level)).length > 0) {
                                                                    // Have proposed skills which not exist in profile skills
                                                                    subCategoryHasValue = true;
                                                                }
                                                            }
                                                        } else if (!badgeCertification && !showProposedAction && showSuggestedUpdate) {
                                                            if (showExtraFramework) {
                                                                if (subCategory.skills && subCategory.skills.length > 0) {
                                                                    subCategory.skills.forEach((skill) => {
                                                                        const extraFrameworkSkillProfiles = skillProfiles.filter(
                                                                            (x) => x.category == category.name && x.subCategory == subCategory.name && x.skill == skill.name && x.skillCode == skill.skillsCode && (x.stringVal !== "N" || x.editedStringVal)
                                                                        );
                                                                        const extraFrameworkSuggestedSkills = suggestedSkills
                                                                            .filter((x) => x.category == category.name && x.subCategory == subCategory.name && x.skill == skill.name && x.skillCode == skill.skillsCode)
                                                                            .map((it) => {
                                                                                return {
                                                                                    numericVal: it.numericVal,
                                                                                    category: it.category,
                                                                                    subCategory: it.subCategory,
                                                                                    id: it.id,
                                                                                    skill: it.skill,
                                                                                    skillCode: it.skillCode,
                                                                                    level: it.level,
                                                                                };
                                                                            });

                                                                        if (skill.levelSelection == "Single") {
                                                                            if (extraFrameworkSkillProfiles.length == 0 && extraFrameworkSuggestedSkills.length > 0) {
                                                                                // No profile skills but have proposed skills
                                                                                subCategoryHasValue = true;
                                                                            } else if (extraFrameworkSkillProfiles.filter((x) => extraFrameworkSuggestedSkills.some((y) => x.skillCode == y.skillCode && (y.level > x.level || (x.level == y.level && y.numericVal > x.numericVal)))).length > 0) {
                                                                                // Have proposed skills with better capability
                                                                                subCategoryHasValue = true;
                                                                            } else if (extraFrameworkSuggestedSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.skillCode == y.skillCode)).length > 0) {
                                                                                // Have proposed skills which not exist in profile skills
                                                                                subCategoryHasValue = true;
                                                                            }
                                                                        } else {
                                                                            if (extraFrameworkSkillProfiles.length == 0 && extraFrameworkSuggestedSkills.length > 0) {
                                                                                // No profile skills but have proposed skills
                                                                                subCategoryHasValue = true;
                                                                            } else if (extraFrameworkSkillProfiles.filter((x) => extraFrameworkSuggestedSkills.some((y) => x.skillCode == y.skillCode && x.level == y.level && y.numericVal > x.numericVal)).length > 0) {
                                                                                // Have proposed skills with better capability
                                                                                subCategoryHasValue = true;
                                                                            } else if (extraFrameworkSuggestedSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.skillCode == y.skillCode && x.level == y.level)).length > 0) {
                                                                                // Have proposed skills which not exist in profile skills
                                                                                subCategoryHasValue = true;
                                                                            }
                                                                        }
                                                                    });
                                                                }
                                                            } else {
                                                                const subCatSuggestedSkills = suggestedSkills
                                                                    .filter((x) => x.subCategory == subCategory.name)
                                                                    .map((it) => {
                                                                        return {
                                                                            numericVal: it.numericVal,
                                                                            category: it.category,
                                                                            subCategory: it.subCategory,
                                                                            id: it.id,
                                                                            skill: it.skill,
                                                                            skillCode: it.skillCode,
                                                                            level: it.level,
                                                                        };
                                                                    });

                                                                if (subCatSkillProfiles.length == 0 && subCatSuggestedSkills.length > 0) {
                                                                    // No profile skills but have proposed skills
                                                                    subCategoryHasValue = true;
                                                                } else if (subCatSkillProfiles.filter((x) => subCatSuggestedSkills.some((y) => x.skillCode == y.skillCode && x.level == y.level && y.numericVal > x.numericVal)).length > 0) {
                                                                    // Have proposed skills with better capability
                                                                    subCategoryHasValue = true;
                                                                } else if (subCatSuggestedSkills.filter((x) => !subCatSkillProfiles.some((y) => x.skillCode == y.skillCode && x.level == y.level)).length > 0) {
                                                                    // Have proposed skills which not exist in profile skills
                                                                    subCategoryHasValue = true;
                                                                }
                                                            }
                                                        } else {
                                                            if (badgeRequest && badgeRequest.requestCertificationEntries && badgeRequest.requestCertificationEntries.length > 0 && badgeRequest.requestCertificationEntries.filter((x) => x.subCategory == subCategory.name).length > 0) {
                                                                subCategoryHasValue = true;
                                                            } else if (badgeRequest && badgeRequest.requestEntries && badgeRequest.requestEntries.length > 0 && badgeRequest.requestEntries.filter((x) => x.subCategory == subCategory.name).length > 0) {
                                                                subCategoryHasValue = true;
                                                            }
                                                        }

                                                        const subCatSkillsCodes = subCategory.skills.map((it) => {
                                                            return it.skillsCode;
                                                        });

                                                        const subCatJobSkillProfiles = this.props.jobSkillProfiles ? this.props.jobSkillProfiles.filter((it) => subCatSkillsCodes.some((skill) => skill == it.skillCode)) : [];
                                                        const jobSubCatSkillProfilesCode = subCatJobSkillProfiles.map((skill) => skill.skillCode);

                                                        const checkedSubCatSkills = showAllSkill || showEditSkill ? subCategory.skills : subCategory.skills.filter((it) => subCatSkillProfilesCodeWithValue.includes(it.skillsCode) || jobSubCatSkillProfilesCode.includes(it.skillsCode));
                                                        const subCategoryHidden = !checkedSubCatSkills.some((skill) => !skill.isHidden) && !showHiddenSkill;

                                                        if (subCategoryHasValue || subCatJobSkillProfiles.length > 0 || showAllSkill || showEditSkill) {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <tr style={{ display: !subCategoryHidden ? "" : "none" }}>
                                                                        <td
                                                                            id={"skill" + subCategory.name.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                            colSpan={showEditSkill || showEditCertify ? (highlightGap ? "18" : "10") : highlightGap ? "17" : "9"}
                                                                            className={"pt-1 pb-1 td-item "}
                                                                            style={{
                                                                                fontFamily: "IBMPlexSans-Bold",
                                                                                verticalAlign: "middle",
                                                                            }}
                                                                        >
                                                                            <ContentTranslator page="SubCategories" name={subCategory.name} contentText={subCategory.name} />
                                                                        </td>
                                                                        <UncontrolledTooltip
                                                                            target={"skill" + subCategory.name.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                            placement="bottom"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: this.contentTranslator(this.props.contentTranslations, "SubCategories", `${subCategory.name} Description`, subCategory.description),
                                                                            }}
                                                                        ></UncontrolledTooltip>
                                                                    </tr>
                                                                    {subCategory.skills.map((skill, elIndex) => {
                                                                        const skillHidden = skill.isHidden && !showHiddenSkill;
                                                                        const showGuidance = language && skill.guidances && skill.guidances.length > 0 ? true : false;
                                                                        const subCatDetailSkillProfiles = skillProfiles.filter((x) => x.skillCode === skill.skillsCode && (x.stringVal !== "N" || x.editedStringVal));

                                                                        let hasValue = false;

                                                                        if (!badgeCertification && !showProposedAction && !showSuggestedUpdate) {
                                                                            hasValue = subCatDetailSkillProfiles.length > 0 ? true : false;
                                                                        } else if (!badgeCertification && showProposedAction && !showSuggestedUpdate) {
                                                                            if (showExtraFramework) {
                                                                                const extraFrameworkSkillProfiles = skillProfiles.filter(
                                                                                    (x) => x.category == category.name && x.subCategory == subCategory.name && x.skill == skill.name && x.skillCode == skill.skillsCode && (x.stringVal !== "N" || x.editedStringVal)
                                                                                );
                                                                                const extraFrameworkProposeSkills = proposeSkills
                                                                                    .filter((x) => x.type == "Skill" && x.category == category.name && x.subCategory == subCategory.name && x.name == skill.name && x.code == skill.skillsCode)
                                                                                    .map((it) => {
                                                                                        return {
                                                                                            capability: it.capability,
                                                                                            numericVal: it.capability == "Competent" ? 1 : it.capability == "Proficient" ? 0.5 : it.capability == "Knowledge" ? 0.25 : 0,
                                                                                            category: it.category,
                                                                                            code: it.code,
                                                                                            id: it.id,
                                                                                            level: it.level,
                                                                                            name: it.name,
                                                                                            subCategory: it.subCategory,
                                                                                            type: it.type,
                                                                                        };
                                                                                    });

                                                                                if (skill.levelSelection == "Single") {
                                                                                    if (extraFrameworkSkillProfiles.length == 0 && extraFrameworkProposeSkills.length > 0) {
                                                                                        // No profile skills but have proposed skills
                                                                                        hasValue = true;
                                                                                    } else if (extraFrameworkSkillProfiles.filter((x) => extraFrameworkProposeSkills.some((y) => x.skillCode == y.code && (y.level > x.level || (x.level == y.level && y.numericVal > x.numericVal)))).length > 0) {
                                                                                        // Have proposed skills with better capability
                                                                                        hasValue = true;
                                                                                    } else if (extraFrameworkProposeSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.code == y.skillCode)).length > 0) {
                                                                                        // Have proposed skills which not exist in profile skills
                                                                                        hasValue = true;
                                                                                    }
                                                                                } else {
                                                                                    if (extraFrameworkSkillProfiles.length == 0 && extraFrameworkProposeSkills.length > 0) {
                                                                                        // No profile skills but have proposed skills
                                                                                        hasValue = true;
                                                                                    } else if (extraFrameworkSkillProfiles.filter((x) => extraFrameworkProposeSkills.some((y) => x.skillCode == y.code && x.level == y.level && y.numericVal > x.numericVal)).length > 0) {
                                                                                        // Have proposed skills with better capability
                                                                                        hasValue = true;
                                                                                    } else if (extraFrameworkProposeSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.code == y.skillCode && x.level == y.level)).length > 0) {
                                                                                        // Have proposed skills which not exist in profile skills
                                                                                        hasValue = true;
                                                                                    }
                                                                                }
                                                                            } else {
                                                                                if (proposeSkills.filter((x) => x.type == "Skill" && x.code == skill.skillsCode && x.name == skill.name).length > 0) {
                                                                                    const subCatDetailProposeSkills = proposeSkills
                                                                                        .filter((x) => x.type == "Skill" && x.code == skill.skillsCode && x.name == skill.name)
                                                                                        .map((it) => {
                                                                                            return {
                                                                                                capability: it.capability,
                                                                                                numericVal: it.capability == "Competent" ? 1 : it.capability == "Proficient" ? 0.5 : it.capability == "Knowledge" ? 0.25 : 0,
                                                                                                category: it.category,
                                                                                                code: it.code,
                                                                                                id: it.id,
                                                                                                level: it.level,
                                                                                                name: it.name,
                                                                                                subCategory: it.subCategory,
                                                                                                type: it.type,
                                                                                            };
                                                                                        });

                                                                                    if (subCatDetailSkillProfiles.length == 0 && subCatDetailProposeSkills.length > 0) {
                                                                                        // No profile skills but have proposed skills
                                                                                        hasValue = true;
                                                                                    } else if (subCatDetailSkillProfiles.filter((x) => subCatDetailProposeSkills.some((y) => x.skillCode == y.code && x.level == y.level && y.numericVal > x.numericVal)).length > 0) {
                                                                                        // Have proposed skills with better capability
                                                                                        hasValue = true;
                                                                                    } else if (subCatDetailProposeSkills.filter((x) => !subCatDetailSkillProfiles.some((y) => x.code == y.skillCode && x.level == y.level)).length > 0) {
                                                                                        // Have proposed skills which not exist in profile skills
                                                                                        hasValue = true;
                                                                                    }
                                                                                }
                                                                            }
                                                                        } else if (!badgeCertification && !showProposedAction && showSuggestedUpdate) {
                                                                            if (showExtraFramework) {
                                                                                const extraFrameworkSkillProfiles = skillProfiles.filter(
                                                                                    (x) => x.category == category.name && x.subCategory == subCategory.name && x.skill == skill.name && x.skillCode == skill.skillsCode && (x.stringVal !== "N" || x.editedStringVal)
                                                                                );
                                                                                const extraFrameworkSuggestedSkills = suggestedSkills
                                                                                    .filter((x) => x.category == category.name && x.subCategory == subCategory.name && x.skill == skill.name && x.skillCode == skill.skillsCode)
                                                                                    .map((it) => {
                                                                                        return {
                                                                                            numericVal: it.numericVal,
                                                                                            category: it.category,
                                                                                            subCategory: it.subCategory,
                                                                                            id: it.id,
                                                                                            skill: it.skill,
                                                                                            skillCode: it.skillCode,
                                                                                            level: it.level,
                                                                                        };
                                                                                    });

                                                                                if (skill.levelSelection == "Single") {
                                                                                    if (extraFrameworkSkillProfiles.length == 0 && extraFrameworkSuggestedSkills.length > 0) {
                                                                                        // No profile skills but have proposed skills
                                                                                        hasValue = true;
                                                                                    } else if (extraFrameworkSkillProfiles.filter((x) => extraFrameworkSuggestedSkills.some((y) => x.skillCode == y.skillCode && (y.level > x.level || (x.level == y.level && y.numericVal > x.numericVal)))).length > 0) {
                                                                                        // Have proposed skills with better capability
                                                                                        hasValue = true;
                                                                                    } else if (extraFrameworkSuggestedSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.skillCode == y.skillCode)).length > 0) {
                                                                                        // Have proposed skills which not exist in profile skills
                                                                                        hasValue = true;
                                                                                    }
                                                                                } else {
                                                                                    if (extraFrameworkSkillProfiles.length == 0 && extraFrameworkSuggestedSkills.length > 0) {
                                                                                        // No profile skills but have proposed skills
                                                                                        hasValue = true;
                                                                                    } else if (extraFrameworkSkillProfiles.filter((x) => extraFrameworkSuggestedSkills.some((y) => x.skillCode == y.skillCode && x.level == y.level && y.numericVal > x.numericVal)).length > 0) {
                                                                                        // Have proposed skills with better capability
                                                                                        hasValue = true;
                                                                                    } else if (extraFrameworkSuggestedSkills.filter((x) => !extraFrameworkSkillProfiles.some((y) => x.skillCode == y.skillCode && x.level == y.level)).length > 0) {
                                                                                        // Have proposed skills which not exist in profile skills
                                                                                        hasValue = true;
                                                                                    }
                                                                                }
                                                                            } else {
                                                                                if (suggestedSkills.filter((x) => x.skillCode == skill.skillsCode && x.skill == skill.name).length > 0) {
                                                                                    const subCatDetailSuggestedSkills = suggestedSkills
                                                                                        .filter((x) => x.skillCode == skill.skillsCode && x.skill == skill.name)
                                                                                        .map((it) => {
                                                                                            return {
                                                                                                numericVal: it.numericVal,
                                                                                                category: it.category,
                                                                                                subCategory: it.subCategory,
                                                                                                id: it.id,
                                                                                                skill: it.skill,
                                                                                                skillCode: it.skillCode,
                                                                                                level: it.level,
                                                                                            };
                                                                                        });

                                                                                    if (subCatDetailSkillProfiles.length == 0 && subCatDetailSuggestedSkills.length > 0) {
                                                                                        // No profile skills but have proposed skills
                                                                                        hasValue = true;
                                                                                    } else if (subCatDetailSkillProfiles.filter((x) => subCatDetailSuggestedSkills.some((y) => x.skillCode == y.skillCode && x.level == y.level && y.numericVal > x.numericVal)).length > 0) {
                                                                                        // Have proposed skills with better capability
                                                                                        hasValue = true;
                                                                                    } else if (subCatDetailSuggestedSkills.filter((x) => !subCatDetailSkillProfiles.some((y) => x.skillCode == y.skillCode && x.level == y.level)).length > 0) {
                                                                                        // Have proposed skills which not exist in profile skills
                                                                                        hasValue = true;
                                                                                    }
                                                                                }
                                                                            }
                                                                        } else {
                                                                            if (badgeRequest && badgeRequest.requestCertificationEntries && badgeRequest.requestCertificationEntries.length > 0 && badgeRequest.requestCertificationEntries.filter((x) => x.skillsCode == skill.skillsCode).length > 0) {
                                                                                hasValue = true;
                                                                            } else if (badgeRequest && badgeRequest.requestEntries && badgeRequest.requestEntries.length > 0 && badgeRequest.requestEntries.filter((x) => x.skillsCode == skill.skillsCode).length > 0) {
                                                                                hasValue = true;
                                                                            }
                                                                        }

                                                                        let jobSkillProfiles = this.props.jobSkillProfiles ? this.props.jobSkillProfiles.filter((it) => it.skillCode == skill.skillsCode) : [];

                                                                        jobSkillProfiles.forEach((jobSkillProfile) => {
                                                                            jobSkillProfile.isFully = false;
                                                                            jobSkillProfile.isPartially = false;
                                                                            jobSkillProfile.isProvidedUpper = false;
                                                                        });

                                                                        jobSkillProfiles.forEach((jobSkillProfile) => {
                                                                            if (!category.isExtraFramework == true) {
                                                                                if (subCatDetailSkillProfiles.filter((it) => !it.editedStringVal).filter((it) => it.level == jobSkillProfile.level && (it.numericVal == 1 || it.stringVal == "M")).length > 0) {
                                                                                    jobSkillProfile.isFully = true;
                                                                                } else if (subCatDetailSkillProfiles.filter((it) => !it.editedStringVal).filter((it) => it.level == jobSkillProfile.level && jobSkillProfile.stringVal == "P").length > 0) {
                                                                                    jobSkillProfile.isPartially = true;
                                                                                }

                                                                                if (
                                                                                    subCatDetailSkillProfiles.filter((it) => ((it.stringVal != null && it.stringVal != "N") || it.editedStringVal != "N") && it.level == jobSkillProfile.level).length == 0 &&
                                                                                    subCatDetailSkillProfiles.filter((it) => ((it.stringVal != null && it.stringVal != "N") || it.editedStringVal != "N") && it.level > jobSkillProfile.level).length > 0
                                                                                ) {
                                                                                    jobSkillProfile.isProvidedUpper = true;
                                                                                }
                                                                            } else {
                                                                                const topExtraFrameworkCapability = [...extraFrameworkCapabilities].sort((a, b) => b.weight - a.weight)[0];
                                                                                if (subCatDetailSkillProfiles.filter((it) => !it.editedStringVal).filter((it) => it.level == jobSkillProfile.level && it.numericVal == topExtraFrameworkCapability.weight).length > 0) {
                                                                                    jobSkillProfile.isFully = true;
                                                                                } else if (subCatDetailSkillProfiles.filter((it) => !it.editedStringVal).filter((it) => it.level == jobSkillProfile.level && it.numericVal != 0).length > 0) {
                                                                                    jobSkillProfile.isPartially = true;
                                                                                }

                                                                                if (
                                                                                    skill.levelSelection != "Single" &&
                                                                                    subCatDetailSkillProfiles.filter((it) => it.level == jobSkillProfile.level && it.numericVal != 0).length == 0 &&
                                                                                    subCatDetailSkillProfiles.filter((it) => it.level > jobSkillProfile.level && it.numericVal != 0).length > 0
                                                                                ) {
                                                                                    jobSkillProfile.isProvidedUpper = true;
                                                                                }
                                                                            }
                                                                        });

                                                                        let gapStatus = "";
                                                                        let showWandSuggestion = false;
                                                                        let showAddEvidence = false;

                                                                        // empty is show red crossed circle
                                                                        // partial is show yellow circle
                                                                        // full is show green check circle

                                                                        if (jobSkillProfiles.length > 0) {
                                                                            if (jobSkillProfiles.filter((it) => it.stringVal == "M" && !it.isFully).length > 0) {
                                                                                gapStatus = "empty";
                                                                            } else {
                                                                                if (jobSkillProfiles.filter((it) => it.isFully || it.isPartially || (!it.isFully && !it.isPartially)).length > 0) {
                                                                                    if (jobSkillProfiles.filter((it) => !it.isFully).length > 0) {
                                                                                        gapStatus = "partial";
                                                                                    } else {
                                                                                        gapStatus = "full";
                                                                                    }
                                                                                } else {
                                                                                    gapStatus = "empty";
                                                                                }
                                                                            }

                                                                            if (jobSkillProfiles.filter((it) => it.isPartially && skillProfiles.find((x) => x.stringVal != "N" && it.skillCode == x.skillCode && it.level == x.level)).length > 0) {
                                                                                showAddEvidence = true;
                                                                            } else {
                                                                                if (jobSkillProfiles.filter((it) => (it.isFully || it.isPartially || (!it.isFully && !it.isPartially)) && skillProfiles.find((x) => x.stringVal != "N" && it.skillCode == x.skillCode && it.level == x.level)).length > 0) {
                                                                                    if (jobSkillProfiles.filter((it) => !it.isFully && skillProfiles.find((x) => x.stringVal != "N" && it.skillCode == x.skillCode && it.level == x.level)).length > 0) {
                                                                                        showAddEvidence = true;
                                                                                    }
                                                                                } else {
                                                                                    showAddEvidence = false;
                                                                                }
                                                                            }

                                                                            if (account && !account.updateProfileSuggested && this.props.selectedprofileType.value == "Self Assessed" && jobSkillProfiles.filter((it) => it.isProvidedUpper).length > 0) {
                                                                                showWandSuggestion = true;
                                                                            }
                                                                        }

                                                                        let jobSkillAttributeColor = "#002060";
                                                                        const showJobSkillAttribute = this.props.selectedJobMatchType.id ? true : false;
                                                                        if (showJobSkillAttribute) {
                                                                            if (this.props.selectedJobMatchType.value == "Current") {
                                                                                jobSkillAttributeColor = "#002060";
                                                                            } else {
                                                                                jobSkillAttributeColor = "#4dcaa4";
                                                                            }
                                                                        }

                                                                        let selectedSkillAttributes = [];
                                                                        let selectedJobSkillAttributes = [];

                                                                        if (isShowJobProfile) {
                                                                            if (skill.attributes && skill.attributes.length > 0 && jobSkillAttributes && jobSkillAttributes.length > 0) {
                                                                                selectedSkillAttributes = skill.attributes.filter((it) => !it.isHidden && jobSkillAttributes.find((attribute) => it.id == attribute.skillAttributeId));
                                                                            }
                                                                        } else {
                                                                            if (skill.attributes && skill.attributes.length > 0 && this.state.account && this.state.account.attributes && this.state.account.attributes.length > 0) {
                                                                                selectedSkillAttributes = skill.attributes.filter((it) => !it.isHidden && this.state.account.attributes.find((attribute) => it.id == attribute.skillAttributeId));
                                                                            }

                                                                            selectedJobSkillAttributes = showJobSkillAttribute && jobSkillAttributes ? jobSkillAttributes.filter((it) => !it.isHidden && it.skillName == skill.name && it.skillCode == skill.skillsCode) : [];
                                                                        }

                                                                        var emptySkillLevels = [];
                                                                        for (let i = extraFrameworkLevels.length + 1; i <= 7; i++) {
                                                                            emptySkillLevels.push(
                                                                                <React.Fragment key={`emptyLevel${subCategory.id}${skill.id}${i}`}>
                                                                                    {this.elementSkillExtraFramework(
                                                                                        skill.skillsCode,
                                                                                        skill.levelSelection,
                                                                                        `emptyLevel${subCategory.id}${skill.id}${i}`,
                                                                                        skill.levels,
                                                                                        subCatDetailSkillProfiles,
                                                                                        category.colour,
                                                                                        subCategory.colour,
                                                                                        subCategory.skillColour,
                                                                                        category,
                                                                                        subCategory,
                                                                                        showProposedAction,
                                                                                        proposeSkills
                                                                                            .filter((x) => x.type == "Skill" && x.category == category.name && x.subCategory == subCategory.name && x.name == skill.name && x.code == skill.skillsCode)
                                                                                            .map((it) => {
                                                                                                return {
                                                                                                    capability: it.capability,
                                                                                                    numericVal: it.capability == "Competent" ? 1 : it.capability == "Proficient" ? 0.5 : it.capability == "Knowledge" ? 0.25 : 0,
                                                                                                    category: it.category,
                                                                                                    code: it.code,
                                                                                                    id: it.id,
                                                                                                    level: it.level,
                                                                                                    name: it.name,
                                                                                                    subCategory: it.subCategory,
                                                                                                    type: it.type,
                                                                                                };
                                                                                            }),
                                                                                        showSuggestedUpdate,
                                                                                        suggestedSkills
                                                                                            .filter((x) => x.category == category.name && x.subCategory == subCategory.name && x.skill == skill.name && x.skillCode == skill.skillsCode)
                                                                                            .map((it) => {
                                                                                                return {
                                                                                                    numericVal: it.numericVal,
                                                                                                    category: it.category,
                                                                                                    subCategory: it.subCategory,
                                                                                                    id: it.id,
                                                                                                    skill: it.skill,
                                                                                                    skillCode: it.skillCode,
                                                                                                    level: it.level,
                                                                                                };
                                                                                            })
                                                                                    )}
                                                                                </React.Fragment>
                                                                            );
                                                                        }

                                                                        const levelIterator = [1, 2, 3, 4, 5, 6, 7];

                                                                        return (
                                                                            <tr key={elIndex} style={{ display: !skillHidden && (hasValue || jobSkillProfiles.length > 0 || showAllSkill || showEditSkill) ? "" : "none" }}>
                                                                                <td className={"pt-1 pb-1 td-item"} style={{ verticalAlign: "middle", minWidth: "180px" }}>
                                                                                    <Row>
                                                                                        {hasValue && skill.attributes && skill.attributes.filter((it) => !it.isHidden).length > 0 ? (
                                                                                            <React.Fragment>
                                                                                                <Col
                                                                                                    id={"category" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                                    xs={!isShowJobProfile && !badgeCertification && (showAllSkill || showEditSkill || showEditCertify) ? (showGuidance ? "9" : "10") : showGuidance ? "10" : "11"}
                                                                                                    style={{ paddingRight: "3px" }}
                                                                                                >
                                                                                                    <ContentTranslator page="Skills" name={skill.name} contentText={skill.name} />
                                                                                                    {!badgeCertification && selectedSkillAttributes.length > 0 && (
                                                                                                        <React.Fragment>
                                                                                                            <br />
                                                                                                            {selectedSkillAttributes
                                                                                                                .sort((a, b) => a.attribute > b.attribute)
                                                                                                                .map((attribute, index) => {
                                                                                                                    return (
                                                                                                                        <span key={index}>
                                                                                                                            <i>
                                                                                                                                {attribute.link && !this.props.loginWithTeams ? (
                                                                                                                                    <Link to={{ pathname: attribute.link }} target="_blank">
                                                                                                                                        {attribute.attribute}
                                                                                                                                    </Link>
                                                                                                                                ) : (
                                                                                                                                    attribute.attribute
                                                                                                                                )}
                                                                                                                                {index + 1 < selectedSkillAttributes.length && ", "}
                                                                                                                            </i>
                                                                                                                        </span>
                                                                                                                    );
                                                                                                                })}
                                                                                                        </React.Fragment>
                                                                                                    )}
                                                                                                    {!badgeCertification && selectedJobSkillAttributes.length > 0 && (
                                                                                                        <React.Fragment>
                                                                                                            <br />
                                                                                                            <div style={{ marginTop: "5px" }}>
                                                                                                                {selectedJobSkillAttributes
                                                                                                                    .sort((a, b) => a.attribute > b.attribute)
                                                                                                                    .map((attribute, index) => {
                                                                                                                        return (
                                                                                                                            <React.Fragment key={index}>
                                                                                                                                {attribute.link && !this.props.loginWithTeams ? (
                                                                                                                                    <React.Fragment>
                                                                                                                                        <div
                                                                                                                                            style={{
                                                                                                                                                background: jobSkillAttributeColor,
                                                                                                                                                color: "white",
                                                                                                                                                padding: "3px 5px",
                                                                                                                                                marginRight: "3px",
                                                                                                                                                marginBottom: "3px",
                                                                                                                                                borderRadius: "5px",
                                                                                                                                                whiteSpace: "nowrap",
                                                                                                                                                float: "left",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <Link to={{ pathname: attribute.link }} style={{ color: "white" }} target="_blank">
                                                                                                                                                {attribute.attribute}
                                                                                                                                            </Link>
                                                                                                                                        </div>
                                                                                                                                    </React.Fragment>
                                                                                                                                ) : (
                                                                                                                                    <React.Fragment>
                                                                                                                                        <div
                                                                                                                                            style={{
                                                                                                                                                background: jobSkillAttributeColor,
                                                                                                                                                color: "white",
                                                                                                                                                padding: "3px 5px",
                                                                                                                                                marginRight: "3px",
                                                                                                                                                marginBottom: "3px",
                                                                                                                                                borderRadius: "5px",
                                                                                                                                                whiteSpace: "nowrap",
                                                                                                                                                float: "left",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            {attribute.attribute}
                                                                                                                                        </div>
                                                                                                                                    </React.Fragment>
                                                                                                                                )}
                                                                                                                            </React.Fragment>
                                                                                                                        );
                                                                                                                    })}
                                                                                                            </div>
                                                                                                        </React.Fragment>
                                                                                                    )}
                                                                                                </Col>
                                                                                                {showGuidance ? (
                                                                                                    <Col
                                                                                                        id={"guidance" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                                        xs={!isShowJobProfile && !badgeCertification ? "1" : "2"}
                                                                                                        style={{ paddingLeft: "0px", textAlign: "right" }}
                                                                                                    >
                                                                                                        <FontAwesomeIcon icon={faCircleQuestion} style={{ cursor: "pointer" }} onClick={() => this.openSfiaGuidance(skill)} />
                                                                                                    </Col>
                                                                                                ) : (
                                                                                                    <div id={"guidance" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}></div>
                                                                                                )}
                                                                                                {!isShowJobProfile && !badgeCertification && (
                                                                                                    <Col xs={showAllSkill || showEditSkill || showEditCertify ? "2" : "1"} style={{ paddingLeft: "0px", textAlign: "right" }}>
                                                                                                        <FontAwesomeIcon
                                                                                                            icon={faList}
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            onClick={() =>
                                                                                                                this.openEditSkillAttribute(
                                                                                                                    {
                                                                                                                        id: category.id,
                                                                                                                        name: category.name,
                                                                                                                        colour: category.colour,
                                                                                                                    },
                                                                                                                    {
                                                                                                                        id: subCategory.id,
                                                                                                                        name: subCategory.name,
                                                                                                                        colour: subCategory.colour,
                                                                                                                        skillColour: subCategory.skillColour,
                                                                                                                    },
                                                                                                                    skill
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                    </Col>
                                                                                                )}
                                                                                            </React.Fragment>
                                                                                        ) : (
                                                                                            <React.Fragment>
                                                                                                <Col
                                                                                                    id={"category" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                                    xs={showGuidance ? "10" : "12"}
                                                                                                    style={{ paddingRight: showGuidance ? "3px" : "0px" }}
                                                                                                >
                                                                                                    <ContentTranslator page="Skills" name={skill.name} contentText={skill.name} />
                                                                                                </Col>
                                                                                                {showGuidance ? (
                                                                                                    <Col id={"guidance" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} xs={"2"} style={{ paddingLeft: "0px", textAlign: "right" }}>
                                                                                                        <FontAwesomeIcon icon={faCircleQuestion} style={{ cursor: "pointer" }} onClick={() => this.openSfiaGuidance(skill)} />
                                                                                                    </Col>
                                                                                                ) : (
                                                                                                    <div id={"guidance" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}></div>
                                                                                                )}
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                    </Row>
                                                                                </td>
                                                                                <UncontrolledTooltip
                                                                                    target={"category" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                    placement="bottom"
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: this.contentTranslator(this.props.contentTranslations, "Skills", `${skill.name} Description`, skill.description),
                                                                                    }}
                                                                                ></UncontrolledTooltip>
                                                                                <UncontrolledTooltip
                                                                                    target={"guidance" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                    placement="bottom"
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: `${skill.skillsCode} ${this.contentTranslator(this.props.contentTranslations, "SfiaAll", "Guidance Notes", "Guidance Notes")}`,
                                                                                    }}
                                                                                ></UncontrolledTooltip>
                                                                                <td id={"skill" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} className={"pt-1 pb-1 td-item"} style={{ verticalAlign: "middle" }}>
                                                                                    {skill.skillsCode}
                                                                                </td>
                                                                                <UncontrolledTooltip
                                                                                    target={"skill" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                    placement="bottom"
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: this.contentTranslator(this.props.contentTranslations, "Skills", `${skill.name} Description`, skill.description),
                                                                                    }}
                                                                                ></UncontrolledTooltip>
                                                                                {!category.isExtraFramework == true ? (
                                                                                    <React.Fragment>
                                                                                        {levelIterator.map((level) => {
                                                                                            return this.elementSkill(
                                                                                                skill.skillsCode,
                                                                                                level,
                                                                                                skill.levels,
                                                                                                subCatDetailSkillProfiles,
                                                                                                category.colour,
                                                                                                subCategory.colour,
                                                                                                subCategory.skillColour,
                                                                                                category,
                                                                                                subCategory,
                                                                                                showProposedAction,
                                                                                                proposeSkills,
                                                                                                showSuggestedUpdate,
                                                                                                suggestedSkills
                                                                                            );
                                                                                        })}
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        {extraFrameworkLevels.map((extraFrameworkLevel, levelIndex) => {
                                                                                            return this.elementSkillExtraFramework(
                                                                                                skill.skillsCode,
                                                                                                skill.levelSelection,
                                                                                                extraFrameworkLevel.id,
                                                                                                skill.levels,
                                                                                                subCatDetailSkillProfiles,
                                                                                                category.colour,
                                                                                                subCategory.colour,
                                                                                                subCategory.skillColour,
                                                                                                category,
                                                                                                subCategory,
                                                                                                showProposedAction,
                                                                                                proposeSkills
                                                                                                    .filter((x) => x.type == "Skill" && x.category == category.name && x.subCategory == subCategory.name && x.name == skill.name && x.code == skill.skillsCode)
                                                                                                    .map((it) => {
                                                                                                        return {
                                                                                                            capability: it.capability,
                                                                                                            numericVal: it.capability == "Competent" ? 1 : it.capability == "Proficient" ? 0.5 : it.capability == "Knowledge" ? 0.25 : 0,
                                                                                                            category: it.category,
                                                                                                            code: it.code,
                                                                                                            id: it.id,
                                                                                                            level: it.level,
                                                                                                            name: it.name,
                                                                                                            subCategory: it.subCategory,
                                                                                                            type: it.type,
                                                                                                        };
                                                                                                    }),
                                                                                                showSuggestedUpdate,
                                                                                                suggestedSkills
                                                                                                    .filter((x) => x.category == category.name && x.subCategory == subCategory.name && x.skill == skill.name && x.skillCode == skill.skillsCode)
                                                                                                    .map((it) => {
                                                                                                        return {
                                                                                                            numericVal: it.numericVal,
                                                                                                            category: it.category,
                                                                                                            subCategory: it.subCategory,
                                                                                                            id: it.id,
                                                                                                            skill: it.skill,
                                                                                                            skillCode: it.skillCode,
                                                                                                            level: it.level,
                                                                                                        };
                                                                                                    })
                                                                                            );
                                                                                        })}
                                                                                        {emptySkillLevels}
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {(showEditSkill || showEditCertify) && (
                                                                                    <td align="center" id={"tdedit" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} className={"pt-1 pb-1 td-value td-fixwidth-50"}>
                                                                                        <button
                                                                                            className={`btn btn-light ${subCatDetailSkillProfiles.filter((it) => it.edited).length > 0 ? " btn-warning-edit" : " btnEdit-Style"}`}
                                                                                            onClick={() =>
                                                                                                this.openEditSkillProfile(
                                                                                                    {
                                                                                                        id: category.id,
                                                                                                        name: category.name,
                                                                                                        colour: category.colour,
                                                                                                    },
                                                                                                    {
                                                                                                        id: subCategory.id,
                                                                                                        name: subCategory.name,
                                                                                                        colour: subCategory.colour,
                                                                                                        skillColour: subCategory.skillColour,
                                                                                                    },
                                                                                                    skill,
                                                                                                    skillProfiles ? [...skillProfiles.filter((x) => x.skillCode === skill.skillsCode)] : [],
                                                                                                    category.isExtraFramework && category.isExtraFramework == true ? true : false
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                        </button>
                                                                                    </td>
                                                                                )}
                                                                                {highlightGap ? (
                                                                                    <td
                                                                                        id={"tdGap" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                        className={`pt-1 pb-1 td-value ${showWandSuggestion ? "td-fixwidth-80" : "td-fixwidth-50"}`}
                                                                                        style={{ textAlign: "center", verticalAlign: "middle" }}
                                                                                    >
                                                                                        {loadingGap && <Spinner size="sm" animation="border" role="status"></Spinner>}
                                                                                        {!loadingGap ? (
                                                                                            gapStatus == "empty" ? (
                                                                                                <FontAwesomeIcon
                                                                                                    id={"iconGap" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                                    style={{ fontSize: "16px", color: "#df5640" }}
                                                                                                    icon={faTimesCircle}
                                                                                                />
                                                                                            ) : gapStatus == "partial" ? (
                                                                                                <FontAwesomeIcon
                                                                                                    id={"iconGap" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                                    className="text-warning"
                                                                                                    style={{ fontSize: "16px" }}
                                                                                                    icon={faCircle}
                                                                                                />
                                                                                            ) : gapStatus == "full" ? (
                                                                                                <FontAwesomeIcon
                                                                                                    id={"iconGap" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                                    className="text-success"
                                                                                                    style={{ fontSize: "16px" }}
                                                                                                    icon={faCheckCircle}
                                                                                                />
                                                                                            ) : (
                                                                                                <FontAwesomeIcon
                                                                                                    id={"iconGap" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                                    style={{ fontSize: "16px", display: "none" }}
                                                                                                    icon={faCheckCircle}
                                                                                                />
                                                                                            )
                                                                                        ) : (
                                                                                            <FontAwesomeIcon
                                                                                                id={"iconGap" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                                style={{ fontSize: "16px", display: "none" }}
                                                                                                icon={faCheckCircle}
                                                                                            />
                                                                                        )}
                                                                                        <UncontrolledTooltip autohide={false} target={"iconGap" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} placement="bottom">
                                                                                            {!loadingGap ? (
                                                                                                gapStatus == "empty" ? (
                                                                                                    <ContentTranslator page="SfiaProfile" name="SfiaProfileGapStatusGapHover" contentText="You do NOT have a Required skill as Competent at the Level(s) needed" />
                                                                                                ) : gapStatus == "partial" ? (
                                                                                                    <ContentTranslator page="SfiaProfile" name="SfiaProfileGapStatusPartialHover" contentText="You do NOT have a Desirable skill as Competent skill at the Level(s) needed" />
                                                                                                ) : gapStatus == "full" ? (
                                                                                                    <ContentTranslator page="SfiaProfile" name="SfiaProfileGapStatusMatchHover" contentText="You DO have the Required skill as Competent at the Level(s) needed" />
                                                                                                ) : (
                                                                                                    <ContentTranslator page="SfiaProfile" name="SfiaProfileGapStatusGapHover" contentText="You do NOT have a Required skill as Competent at the Level(s) needed" />
                                                                                                )
                                                                                            ) : (
                                                                                                <ContentTranslator page="SfiaProfile" name="SfiaProfileGapStatusGapHover" contentText="You do NOT have a Required skill as Competent at the Level(s) needed" />
                                                                                            )}
                                                                                            {!loadingGap && (gapStatus == "empty" || gapStatus == "partial") && (
                                                                                                <React.Fragment>
                                                                                                    <br />
                                                                                                    <br />
                                                                                                    <button className={`btn btn-primary`} onClick={() => this.handleAddAction(skill)}>
                                                                                                        <FontAwesomeIcon icon={faPlusCircle} /> Add Action
                                                                                                    </button>
                                                                                                    {showAddEvidence && (
                                                                                                        <React.Fragment>
                                                                                                            {"   "}
                                                                                                            <button className={`btn btn-primary`} onClick={() => this.handleAddEvidence(skill)}>
                                                                                                                <FontAwesomeIcon icon={faPlusCircle} /> Add Evidence
                                                                                                            </button>
                                                                                                        </React.Fragment>
                                                                                                    )}
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </UncontrolledTooltip>
                                                                                        {!loadingGap && showWandSuggestion ? (
                                                                                            <FontAwesomeIcon
                                                                                                id={"iconWand" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                                style={{ fontSize: "16px", paddingLeft: "5px", color: "#a7197f", cursor: "pointer" }}
                                                                                                icon={faWandMagicSparkles}
                                                                                                onClick={() =>
                                                                                                    this.handleSuggestUpdateLowerLevelProfile(
                                                                                                        {
                                                                                                            id: category.id,
                                                                                                            name: category.name,
                                                                                                            isExtraFramework: category.isExtraFramework && category.isExtraFramework == true ? true : false,
                                                                                                        },
                                                                                                        {
                                                                                                            id: subCategory.id,
                                                                                                            name: subCategory.name,
                                                                                                        },
                                                                                                        skill,
                                                                                                        skillProfiles ? [...skillProfiles.filter((x) => x.skillCode === skill.skillsCode)] : []
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        ) : (
                                                                                            <FontAwesomeIcon
                                                                                                id={"iconWand" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                                style={{ fontSize: "16px", display: "none" }}
                                                                                                icon={faWandMagicSparkles}
                                                                                            />
                                                                                        )}
                                                                                        <UncontrolledTooltip target={"iconWand" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} placement="bottom">
                                                                                            <ContentTranslator page="SfiaProfile" name="SfiaProfileSuggestProfileUpdateHover" contentText="Click to suggest profile updates for this skill" />
                                                                                        </UncontrolledTooltip>
                                                                                    </td>
                                                                                ) : (
                                                                                    <td style={{ display: "none" }}>
                                                                                        <FontAwesomeIcon id={"iconGap" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} style={{ fontSize: "16px", display: "none" }} icon={faCheckCircle} />
                                                                                        <UncontrolledTooltip target={"iconGap" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} placement="bottom">
                                                                                            <ContentTranslator page="SfiaProfile" name="SfiaProfileGapStatusGapHover" contentText="You do NOT have a Required skill as Competent at the Level(s) needed" />
                                                                                        </UncontrolledTooltip>
                                                                                        <FontAwesomeIcon
                                                                                            id={"iconWand" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                            style={{ fontSize: "16px", display: "none" }}
                                                                                            icon={faWandMagicSparkles}
                                                                                        />
                                                                                        <UncontrolledTooltip target={"iconWand" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} placement="bottom">
                                                                                            <ContentTranslator page="SfiaProfile" name="SfiaProfileSuggestProfileUpdateHover" contentText="Click to suggest profile updates for this skill" />
                                                                                        </UncontrolledTooltip>
                                                                                    </td>
                                                                                )}
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    })}
                                                    <tr>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </React.Fragment>
                                        );
                                    } else {
                                        return <React.Fragment key={index}></React.Fragment>;
                                    }
                                } else {
                                    return <React.Fragment key={index}></React.Fragment>;
                                }
                            })}
                    </Table>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language.language,
        personLoading: state.person.loading,
        personError: state.person.error,
        account: state.person.account,
        contentTranslations: state.content.contentTranslations,
        managedPeople: state.auth.managedPeople,
        loginWithTeams: state.auth.loginWithTeams,
        actionTypes: state.actionPlan.actionTypes,
        actionSkills: state.actionPlan.actionSkills,
        actions: state.actionPlan.actions,
        selectedProviders: state.company.selectedProviders,
        jobMatches: state.job.jobMatches,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPerson: bindActionCreators(personActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableSkillProfiles);
