import * as actionTypes from "./actionTypes";

import LorAPI from "./../../common/api/service/LorService";

export const getLorsStart = () => {
    return {
        type: actionTypes.GET_LORS_START,
    };
};

export const getLorsSuccess = (lors) => {
    return {
        type: actionTypes.GET_LORS_SUCCESS,
        lors
    };
};

export const getLorsFail = error => {
    return {
        type: actionTypes.GET_LORS_FAIL,
        error: error,
    };
};

export const getLors = () => {
    return dispatch => {
        dispatch(getLorsStart());

        return LorAPI.getLors()
            .then(res => {
                if (res) {
                    dispatch(getLorsSuccess(res));
                } else {
                    dispatch(getLorsFail(res));
                }
            })
            .catch(err => {
                dispatch(getLorsFail(err));
            });
    };
};

export const updateLorStart = () => {
    return {
        type: actionTypes.UPDATE_LOR_START,
    };
};

export const updateLorSuccess = (lor) => {
    return {
        type: actionTypes.UPDATE_LOR_SUCCESS,
        lor
    };
};

export const updateLorFail = error => {
    return {
        type: actionTypes.UPDATE_LOR_FAIL,
        error: error,
    };
};

export const updateLor = (id, lor) => {
    return dispatch => {
        dispatch(updateLorStart());

        return LorAPI.updateLor(id, lor)
            .then(res => {
                if (res) {
                    dispatch(updateLorSuccess(res));
                } else {
                    dispatch(updateLorFail(res));
                }
            })
            .catch(err => {
                dispatch(updateLorFail(err));
            });
    };
};