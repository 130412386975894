import * as badgeRequestActions from "../../store/actions/badgeRequestAction";
import * as lorActions from "../../store/actions/lorAction";
import { parameterizedString } from "../../utils/stringUtils";
import { contentTranslator } from "../../utils/translatorUtils";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import ContentTranslator from "../translator/ContentTranslator";
import AddNewEvidenceForm from "./AddNewEvidenceForm";
import EvidenceResponsibilityBody from "./EvidenceResponsibilityBody";
import EvidenceSkillBadgeBody from "./EvidenceSkillBadgeBody";
import EvidenceSkillBody from "./EvidenceSkillBody";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Button, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "32px",
    fontFamily: "IBMPlexSans-Bold",
};

const ddHeaderStyle = {
    fontsize: "14px",
    fontFamily: "IBMPlexSans-Bold",
};

const lorOrders = [
    { lor: "Autonomy", order: 1 },
    { lor: "Influence", order: 2 },
    { lor: "Complexity", order: 3 },
    { lor: "Business Skills", order: 4 },
    { lor: "Knowledge", order: 5 },
    { lor: "Responsibility and Autonomy", order: 1 },
    { lor: "Complexity and Scope", order: 2 },
    { lor: "Skills", order: 3 },
];

class ManageEvidence extends React.Component {
    static displayName = ManageEvidence.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            skillLevelDescriptionShow: false,
            poppedUpLevel: null,
            skillDescriptionShow: false,
            poppedUpSkill: null,
            showAddEvidenceForm: false,
            submitBadgeRequestMode: false,
            submitBadgeRequestSkills: [],
            maxNumberOfBadgeRequestSkill: 6,
            submitBadgeRequestAccept: false,
            showSubmitBadgeRequestConfirmationDialog: false,
            showLoadingPanel: false,
            myBadgeRequest: this.props.myBadgeRequest,
            showHiddenSkills: false,
        };

        this.contentTranslator = contentTranslator.bind(this);
        this.parameterizedString = parameterizedString.bind(this);
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.myBadgeRequest !== this.props.myBadgeRequest) {
            this.setState({ myBadgeRequest: this.props.myBadgeRequest });
        }
    }

    handleChangeProfile = (event) => {
        this.props.handleChangeProfileType(event.value);
    };

    renderLorProfiles = () => {
        const { lors, lorProfiles } = this.props;
        const lorCategories = [];

        if (lors) {
            lors.forEach((lor) => {
                const lorOrder = lorOrders.find((it) => it.lor == lor.name);
                if (lorOrder) {
                    lor.order = lorOrder.order;
                } else {
                    lor.order = 999;
                }
            });

            if (lors.find((it) => !it.category)) {
                lorCategories.push({
                    category: "Generic Attributes",
                    categoryColor: "#69849c",
                    lorColor: "#ecf0f5",
                });
            }

            lors.filter((it) => it.category).forEach((lor) => {
                if (!lorCategories.find((it) => it.category == lor.category)) {
                    lorCategories.push({
                        category: lor.category,
                        categoryColor: lor.categoryColor,
                        lorColor: lor.lorColor,
                    });
                }
            });
        }

        return (
            <React.Fragment>
                {lorCategories.map((lorCategory, index) => {
                    return (
                        <Table key={index}>
                            <thead className="tableHeader">
                                <tr>
                                    <th className={"th-start"} style={{ backgroundColor: lorCategory.categoryColor }}>
                                        {lorCategory.category == "Generic Attributes" ? <ContentTranslator page="SfiaEvidence" name="LorProfileTitle" contentText="Generic Attributes" /> : lorCategory.category}
                                    </th>
                                    <th className={"th-fixwidth text-center"} style={{ width: "400px", backgroundColor: lorCategory.categoryColor }}>
                                        <ContentTranslator page="SfiaAll" name={this.props.selectedOptionProfile.replace(" ", "")} contentText={this.props.selectedOptionProfile} />
                                    </th>
                                    <th className={"th-fixwidth text-center th-end"} style={{ backgroundColor: lorCategory.categoryColor }}>
                                        <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceAddEvidence" contentText="Add Evidence" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {lors &&
                                    lors
                                        .filter((it) => (it.category ? it.category : "Generic Attributes") == (lorCategory.category ? lorCategory.category : "Generic Attributes"))
                                        //.sort((a, b) => (`${a.sequence}${a.order}${a.name}` > `${b.sequence}${b.order}${b.name}` ? 1 : -1))
                                        .sort((a, b) => {
                                            if (a.sequence > b.sequence) {
                                                return 1;
                                            }
                                            if (a.sequence < b.sequence) {
                                                return -1;
                                            }
                                            if (a.order > b.order) {
                                                return 1;
                                            }
                                            if (a.order < b.order) {
                                                return -1;
                                            }
                                            if (a.name > b.name) {
                                                return 1;
                                            }
                                            if (a.name < b.name) {
                                                return -1;
                                            }
                                            return 0;
                                        })
                                        .map((lor, index) => {
                                            const lorProfile = lorProfiles ? lorProfiles.find((x) => x.lor === lor.name) : null;

                                            return (
                                                <EvidenceResponsibilityBody
                                                    key={index}
                                                    lor={lor}
                                                    lorProfile={lorProfile}
                                                    lors={this.props.lors}
                                                    categories={this.props.categories}
                                                    evidences={this.props.evidences}
                                                    lorProfiles={this.props.lorProfiles}
                                                    skillProfiles={this.props.skillProfiles}
                                                    handleEvidenceAdded={this.handleEvidenceAdded}
                                                    generateAlert={this.props.generateAlert}
                                                    showHiddenSkills={this.state.showHiddenSkills}
                                                ></EvidenceResponsibilityBody>
                                            );
                                        })}
                            </tbody>
                            <tbody className="border-0">
                                <tr>
                                    <td className="border-0"></td>
                                </tr>
                            </tbody>
                        </Table>
                    );
                })}
            </React.Fragment>
        );
    };

    renderSkillProfiles = () => {
        const { categories } = this.props;

        return (
            categories &&
            categories.map((category, index) => {
                return <React.Fragment key={index}>{this.renderSkillSubCategories(category)}</React.Fragment>;
            })
        );
    };

    renderSkillSubCategories = (category) => {
        const evidences = this.props.evidences;
        const evidencesSkills = evidences.map((evidence) => {
            return {
                id: evidence.id,
                skills: [
                    ...evidence.lorLevels.map((lor) => {
                        return lor.lorCode + lor.level;
                    }),
                    ...evidence.skillLevels.map((lor) => {
                        return lor.skillCode + lor.level;
                    }),
                ],
            };
        });
        const submitBadgeRequestMode = this.state.submitBadgeRequestMode;

        const content = category.subCategories.map((subCategory, subIndex) => {
            const shownLevel = subCategory.skills.filter((skill) => skill.levels.some((level) => level.show));
            let subCatSkillProfilesCodeShown = subCategory.skills.filter((skill) => skill.levels.some((level) => level.show)).map((skill) => skill.skillsCode);
            const checkedSubCatSkills = subCategory.skills.filter((it) => subCatSkillProfilesCodeShown.includes(it.skillsCode));
            const subCategoryHidden = !checkedSubCatSkills.some((skill) => !skill.isHidden) && (!this.state.showHiddenSkills || submitBadgeRequestMode);

            return (
                (shownLevel.length > 0 || this.props.showAll) && (
                    <React.Fragment key={subIndex}>
                        <thead className="tableHeader" style={{ display: !subCategoryHidden ? "" : "none" }}>
                            <tr>
                                <th className={"th-start"} style={{ backgroundColor: `${category.colour}` }}>
                                    <ContentTranslator page="Categories" name={category.name} contentText={category.name} />
                                </th>
                                <th style={{ width: "300px", backgroundColor: `${category.colour}` }}>
                                    <ContentTranslator page="SubCategories" name={subCategory.name} contentText={subCategory.name} />
                                </th>
                                <th colSpan="2" style={{ backgroundColor: `${category.colour}`, width: "100px" }}></th>
                                <th className={"th-fixwidth th-end"} style={{ backgroundColor: `${category.colour}` }}></th>
                            </tr>
                            <tr style={{ color: "#2B2B2B" }}>
                                <th className="border-0"></th>
                                <th className="border-0">
                                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceSkill" contentText="Skill" />
                                </th>
                                <th colSpan="2">{!submitBadgeRequestMode ? <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceSkillLevel" contentText="Skill Level" /> : <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceDesription" contentText="Description" />}</th>
                                <th className="text-center">{!submitBadgeRequestMode ? <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceAddEvidence" contentText="Add Evidence" /> : <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceCertify" contentText="Certify" />}</th>
                            </tr>
                        </thead>
                        {this.renderSkills(category, subCategory)}
                        <tbody className="border-0" style={{ display: !subCategoryHidden ? "" : "none" }}>
                            <tr>
                                <td className="border-0"></td>
                            </tr>
                        </tbody>
                    </React.Fragment>
                )
            );
        });

        return content;
    };

    renderSkills = (category, subCategory) => {
        const submitBadgeRequestMode = this.state.submitBadgeRequestMode;
        const evidences = this.props.evidences;
        const evidencesSkills = evidences.map((evidence) => {
            return {
                id: evidence.id,
                skills: [
                    ...evidence.lorLevels.map((lor) => {
                        return lor.lorCode + lor.level;
                    }),
                    ...evidence.skillLevels.map((lor) => {
                        return lor.skillCode + lor.level;
                    }),
                ],
            };
        });

        return (
            <tbody
                style={{
                    fontSize: 11,
                    fontFamily: "IBMPlexSans",
                }}
            >
                {subCategory.skills.map((skill, skillIndex) => {
                    const skillHidden = skill.isHidden && (!this.state.showHiddenSkills || submitBadgeRequestMode);

                    if (submitBadgeRequestMode) {
                        const show = skill.levels.filter((it) => it.show).length > 0 ? true : false;

                        return (
                            <React.Fragment key={skillIndex}>
                                {show && (
                                    <EvidenceSkillBadgeBody
                                        key={skillIndex}
                                        skill={{
                                            id: skill.id,
                                            category: category.name,
                                            subCategory: subCategory.name,
                                            skill: skill.name,
                                            skillsCode: skill.skillsCode,
                                            description: skill.description,
                                        }}
                                        skillHidden={skillHidden}
                                        openSkillDescriptionModal={this.openSkillDescriptionModal}
                                        submitBadgeRequestSkills={this.state.submitBadgeRequestSkills}
                                        handleEvidenceBadgeRequestSelect={this.handleEvidenceBadgeRequestSelect}
                                        handleEvidenceBadgeRequestUnSelect={this.handleEvidenceBadgeRequestUnSelect}
                                        maxNumberOfBadgeRequestSkill={this.state.maxNumberOfBadgeRequestSkill}
                                        generateAlert={this.props.generateAlert}
                                    ></EvidenceSkillBadgeBody>
                                )}
                            </React.Fragment>
                        );
                    } else {
                        return (
                            <React.Fragment key={skillIndex}>
                                {skill.levels.map((level, levelIndex) => {
                                    return (
                                        (level.show || this.props.showAll) && (
                                            <EvidenceSkillBody
                                                key={levelIndex}
                                                skillHidden={skillHidden}
                                                showHiddenSkills={this.state.showHiddenSkills}
                                                levelIndex={levelIndex}
                                                skill={{
                                                    id: skill.id,
                                                    skillsCode: skill.skillsCode,
                                                    name: skill.name,
                                                }}
                                                level={level}
                                                openSkillLevelDescriptionModal={this.openSkillLevelDescriptionModal}
                                                lors={this.props.lors}
                                                categories={this.props.categories}
                                                evidences={this.props.evidences}
                                                lorProfiles={this.props.lorProfiles}
                                                skillProfiles={this.props.skillProfiles}
                                                handleEvidenceAdded={this.handleEvidenceAdded}
                                                generateAlert={this.props.generateAlert}
                                            ></EvidenceSkillBody>
                                        )
                                    );
                                })}
                            </React.Fragment>
                        );
                    }
                })}
            </tbody>
        );
    };

    openSkillLevelDescriptionModal = (level) => {
        this.setState({ poppedUpLevel: level, skillLevelDescriptionShow: true });
    };

    togleSkillLevelDescriptionModal = () => {
        const skillLevelDescriptionShow = this.state.skillLevelDescriptionShow;
        this.setState({ skillLevelDescriptionShow: !skillLevelDescriptionShow });
    };

    openSkillDescriptionModal = (skill) => {
        this.setState({ poppedUpSkill: skill, skillDescriptionShow: true });
    };

    togleSkillDescriptionModal = () => {
        const skillDescriptionShow = this.state.skillDescriptionShow;
        this.setState({ skillDescriptionShow: !skillDescriptionShow });
    };

    handleAddEvidenceClick = () => {
        this.setState({
            showAddEvidenceForm: !this.state.showAddEvidenceForm,
        });
    };

    handleCloseAddEvidence = () => {
        this.setState({
            showAddEvidenceForm: false,
        });
    };

    handleEvidenceAdded = (addedEvidence) => {
        this.props.handleEvidenceAdded(addedEvidence);
        this.handleCloseAddEvidence();
        this.props.generateAlert("success", "New Evidence Added");
    };

    handleCertifySkills = () => {
        this.setState({ submitBadgeRequestMode: true, showHiddenSkills: false });
    };

    handleCancelCertifySkills = () => {
        this.setState({ submitBadgeRequestMode: false, submitBadgeRequestSkills: [] });
    };

    handleEvidenceBadgeRequestSelect = (selectedSkill) => {
        let submitBadgeRequestSkills = this.state.submitBadgeRequestSkills;
        submitBadgeRequestSkills.push(selectedSkill);
        this.setState({ submitBadgeRequestSkills: [...submitBadgeRequestSkills] });
    };

    handleEvidenceBadgeRequestUnSelect = (selectedSkill) => {
        let submitBadgeRequestSkills = this.state.submitBadgeRequestSkills;
        if (submitBadgeRequestSkills.find((it) => it.skillsCode == selectedSkill.skillsCode)) {
            const index = submitBadgeRequestSkills.indexOf(submitBadgeRequestSkills.find((it) => it.skillsCode == selectedSkill.skillsCode));
            if (index !== -1) {
                submitBadgeRequestSkills.splice(index, 1);
            }
            this.setState({ submitBadgeRequestSkills: [...submitBadgeRequestSkills] });
        }
    };

    handleChangeAcceptCheckBox = () => {
        const submitBadgeRequestAccept = this.state.submitBadgeRequestAccept;
        this.setState({ submitBadgeRequestAccept: !submitBadgeRequestAccept });
    };

    handleSubmitBadgeRequest = () => {
        const submitBadgeRequestSkills = this.state.submitBadgeRequestSkills;

        const badgeRequest = {
            submitDate: new Date(),
            status: "Assessor not assigned",
            requestEntries: submitBadgeRequestSkills.map((skill) => {
                return {
                    category: skill.category,
                    subCategory: skill.subCategory,
                    skill: skill.skill,
                    skillsCode: skill.skillsCode,
                };
            }),
        };

        this.setState({ showSubmitBadgeRequestConfirmationDialog: false, showLoadingPanel: true, loadingPanelHeader: "Submit badge request", loadingPanelBody: "Please wait..." });
        this.props.onBadgeRequest.submitBadgeRequest(badgeRequest).then(() => {
            if (!this.props.badgeRequestUpdating) {
                if (this.props.error) {
                    this.props.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    this.props.generateAlert("success", "Badge request submitted");
                    this.handleCancelCertifySkills();
                }
            }
            this.setState({ showLoadingPanel: this.props.badgeRequestUpdating });
        });
    };

    togleSubmitBadgeRequestConfirmationModal = () => {
        const showSubmitBadgeRequestConfirmationDialog = this.state.showSubmitBadgeRequestConfirmationDialog;
        this.setState({ showSubmitBadgeRequestConfirmationDialog: !showSubmitBadgeRequestConfirmationDialog });
    };

    renderSubmitBadgeRequestConfirmation = () => {
        return (
            <Modal isOpen={this.state.showSubmitBadgeRequestConfirmationDialog} toggle={this.togleSubmitBadgeRequestConfirmationModal}>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>Please confirm you are ready to continue.</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleSubmitBadgeRequest();
                        }}
                    >
                        OK
                    </Button>
                    <Button color="secondary" onClick={this.togleSubmitBadgeRequestConfirmationModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    renderLoadingPanel() {
        if (this.state.showLoadingPanel) {
            return (
                <Modal isOpen={this.state.showLoadingPanel}>
                    <ModalHeader>{this.state.loadingPanelHeader}</ModalHeader>
                    <ModalBody>
                        <Spinner size="md" color="primary" /> {this.state.loadingPanelBody}
                    </ModalBody>
                </Modal>
            );
        } else {
            return "";
        }
    }

    handleChageShowHiddenSkills = () => {
        const showHiddenSkills = this.state.showHiddenSkills;
        this.setState({ showHiddenSkills: !showHiddenSkills, showAddEvidenceForm: false });
        this.props.handleChageShowHiddenSkills(!showHiddenSkills);
    };

    render() {
        let isAdmin = false;

        const authMode = this.props.authMode;
        let authModes = authMode.split(",").map((item) => item.trim());
        if (authModes.filter((it) => it != "Admin").length == 0) {
            isAdmin = true;
        }

        const { poppedUpLevel, poppedUpSkill, submitBadgeRequestMode, maxNumberOfBadgeRequestSkill, submitBadgeRequestAccept, submitBadgeRequestSkills, showHiddenSkills } = this.state;
        const { optionsProfile, selectedOptionProfile } = this.props;

        return (
            <React.Fragment>
                {this.renderLoadingPanel()}
                {this.renderSubmitBadgeRequestConfirmation()}
                <Modal isOpen={this.state.skillLevelDescriptionShow} toggle={this.togleSkillLevelDescriptionModal}>
                    <ModalHeader toggle={this.togleSkillLevelDescriptionModal}>{poppedUpLevel && `${poppedUpLevel.skillsCode}-${poppedUpLevel.level}`}</ModalHeader>
                    <ModalBody>{poppedUpLevel && poppedUpLevel.description}</ModalBody>
                </Modal>
                <Modal isOpen={this.state.skillDescriptionShow} toggle={this.togleSkillDescriptionModal}>
                    <ModalHeader toggle={this.togleSkillDescriptionModal}>{poppedUpSkill && `${poppedUpSkill.skillsCode}`}</ModalHeader>
                    <ModalBody>{poppedUpSkill && poppedUpSkill.description}</ModalBody>
                </Modal>
                <ContentWrapperLg>
                    <p className="mb-0" style={headerStyle}>
                        {this.props.managedPeople
                            ? this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceManageEvidenceFor", "Manage Evidence for {s1}"), `${this.props.managedPeople.personB.firstName} ${this.props.managedPeople.personB.lastName}`)
                            : this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceManageEvidence", "Manage Your Evidence")}
                    </p>
                    <Container className="p-0">
                        <Row className="justify-content-between">
                            <Col xs="6" className="p-0">
                                <Container>
                                    <Row>
                                        <Col xs="12" md="6" className="mt-2">
                                            <p className="mb-1" style={ddHeaderStyle}>
                                                <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceChooseProfileType" contentText="Choose Profile Type" />
                                            </p>
                                            <Select value={optionsProfile.filter((option) => option.value === selectedOptionProfile)} onChange={this.handleChangeProfile} options={optionsProfile} isDisabled={this.state.submitBadgeRequestMode} />
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            {/* {!this.props.managedPeople && } */}
                            <Col xs="auto" className="align-self-end mt-2">
                                {this.props.containerConfig && this.props.containerConfig.allowShowHiddenSkills && (
                                    <button id="btnShowHiddenSkills" className="btn btn-light btnSecondary-Style" style={{ marginLeft: "10px", width: "120px" }} onClick={this.handleChageShowHiddenSkills} disabled={this.state.submitBadgeRequestMode}>
                                        {showHiddenSkills
                                            ? this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceButtonHideHiddenSkills", "Hide Hidden Skills")
                                            : this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceButtonShowHiddenSkills", "Show Hidden Skills")}
                                    </button>
                                )}
                                {(!this.state.myBadgeRequest || (this.state.myBadgeRequest && this.state.myBadgeRequest.status == "Assessed" && this.state.myBadgeRequest.certificationSubmitted)) &&
                                this.props.containerConfig &&
                                this.props.containerConfig.showSfiaCertificationAdmin &&
                                this.props.containerConfig.allowCertification &&
                                this.props.containerConfig.certificationCredit &&
                                this.props.containerConfig.certificationCredit > 0 &&
                                !this.props.managedPeople &&
                                this.props.selectedOptionProfile == "Self Assessed" ? (
                                    <React.Fragment>
                                        <button id="btnCertifySkills" className="btn btn-light btnSecondary-Style" style={{ marginLeft: "10px", width: "120px" }} onClick={this.handleCertifySkills} disabled={this.props.loadingEvidence || this.state.submitBadgeRequestMode}>
                                            <FontAwesomeIcon icon={faPlus} style={{ fontSize: "13px" }} />
                                            <span className="ml-2">
                                                <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceButtonCertify" contentText="Certify Skills" />
                                            </span>
                                        </button>
                                        <UncontrolledTooltip target={`btnCertifySkills`} placement="bottom" autohide={false}>
                                            <ContentTranslator
                                                page="SfiaEvidence"
                                                name="SfiaEvidenceButtonCertifyTooltip"
                                                contentText={`Request to have skills independently certified by an accredited assessor. <a href="https://skillstx.com/certify/" target="_blank" rel="noreferrer">Learn More.</a>`}
                                            />
                                        </UncontrolledTooltip>
                                    </React.Fragment>
                                ) : (
                                    ""
                                )}
                                {!this.state.showAddEvidenceForm ? (
                                    <React.Fragment>
                                        <button id="btnAddNewEvidence" className="btn btn-light btnSecondary-Style" style={{ marginLeft: "10px", width: "120px" }} onClick={this.handleAddEvidenceClick} disabled={this.props.loadingAdd || this.state.submitBadgeRequestMode}>
                                            <FontAwesomeIcon icon={faPlus} style={{ fontSize: "13px" }} />
                                            <span className="ml-2">
                                                <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceButtonNewEvidence" contentText="New Evidence" />
                                            </span>
                                        </button>
                                        <UncontrolledTooltip target={`btnAddNewEvidence`} placement="bottom">
                                            <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceButtonNewEvidenceTooltip" contentText="Open a New Evidence pop-up" />
                                        </UncontrolledTooltip>
                                    </React.Fragment>
                                ) : (
                                    <button className="btn btn-light btnSecondary-Style" style={{ marginLeft: "10px", width: "120px" }} onClick={this.handleAddEvidenceClick}>
                                        <FontAwesomeIcon icon={faMinus} style={{ fontSize: "13px" }} />
                                    </button>
                                )}
                            </Col>
                        </Row>
                        {this.state.submitBadgeRequestMode && (
                            <Row className="justify-content-between">
                                <Col xs="12" className="p-0">
                                    <Container>
                                        <Row md="12">
                                            <Col md="12" className="mt-2">
                                                <br />
                                                <b>{this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceCertifyTitle", "From the list below please select up to {s1} skills to be assessed for certification."), maxNumberOfBadgeRequestSkill)}</b>
                                                <br />
                                                {this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceCertifyNotes", "Note: A maximum of {s1} badges can be issued."), maxNumberOfBadgeRequestSkill)}
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        )}
                    </Container>
                    {this.state.showAddEvidenceForm && (
                        <div style={{ float: "right" }}>
                            <br />
                            <table
                                style={{
                                    fontSize: 11,
                                    fontFamily: "IBMPlexSans",
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <td className="border-0">
                                            <AddNewEvidenceForm
                                                categories={this.props.categories}
                                                lors={this.props.lors}
                                                lorProfiles={this.props.lorProfiles}
                                                skillProfiles={this.props.skillProfiles}
                                                handleClose={this.handleCloseAddEvidence}
                                                handleEvidenceAdded={this.handleEvidenceAdded}
                                                generateAlert={this.props.generateAlert}
                                                showHiddenSkills={this.state.showHiddenSkills}
                                            ></AddNewEvidenceForm>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                        </div>
                    )}
                    <Container className="p-0 mt-4" style={{ fontSize: "11px" }}>
                        {this.props.loadingAdd ? (
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <Spinner size="lg" animation="border" role="status"></Spinner>
                            </div>
                        ) : (
                            <React.Fragment>
                                {!submitBadgeRequestMode && this.renderLorProfiles()}
                                <Table>{this.renderSkillProfiles()}</Table>
                            </React.Fragment>
                        )}
                    </Container>
                    <Container className="p-0">
                        {this.state.submitBadgeRequestMode && (
                            <Row className="justify-content-between">
                                <Col xs="12" className="p-0">
                                    <Container>
                                        <Row md="12">
                                            <Col md="12" className="mt-2" style={{ textAlign: "center" }}>
                                                <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceCertifyPrivacyStatement" contentText={`Check out our privacy statement <a href="https://skillstx.com/skillstx_privacypolicy" rel="noreferrer" target="_blank">HERE</a> and confirm acceptance`} />{" "}
                                                <Input type="checkbox" name="submitBadgeRequestAccept" id="submitBadgeRequestAccept" checked={submitBadgeRequestAccept} onChange={this.handleChangeAcceptCheckBox} style={{ width: "30px" }} />
                                                <br />
                                                <br />
                                                <button className={`btn btn-primary`} disabled={!submitBadgeRequestAccept || submitBadgeRequestSkills.length == 0} onClick={this.togleSubmitBadgeRequestConfirmationModal}>
                                                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceCertifyButtonSubmit" contentText="Submit" />
                                                </button>
                                                &nbsp;&nbsp;&nbsp;
                                                <button className={`btn btn-secondary`} onClick={this.handleCancelCertifySkills}>
                                                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceCertifyButtonCancel" contentText="Cancel" />
                                                </button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        )}
                    </Container>
                </ContentWrapperLg>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lors: state.lor.lors,
        managedPeople: state.auth.managedPeople,
        authMode: state.auth.authMode,
        contentTranslations: state.content.contentTranslations,
        containerConfig: state.config.containerConfig,

        myBadgeRequest: state.badgeRequest.myBadgeRequest,
        badgeRequestUpdating: state.badgeRequest.isUpdating,
        error: state.badgeRequest.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLor: bindActionCreators(lorActions, dispatch),
        onBadgeRequest: bindActionCreators(badgeRequestActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageEvidence);
