import * as authActions from "../../../store/actions/authAction";
import * as personActions from "../../../store/actions/personAction";
import * as skillProfileActions from "../../../store/actions/skillProfileAction";
import * as adminPersonActions from "../../../store/admin/actions/adminPersonActions";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { filterArray } from "../../../utils/filterUtils";
import { contentTranslator } from "../../../utils/translatorUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSync, faSquareCheck, faSquareMinus, faFileExcel, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { wrap } from "module";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

class ManageSelfAssessed extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            persons: [],
            isUpdating: false,
            searchProperties: ["email", "firstName", "lastName", "selfAssessedStatus"],
            modeApprove: false,
            personsSkillProfileSelected: [],
            loadingPrint: false,
            showApproveDialog: false,
            comment: "",
        };

        this.filterArray = filterArray.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        const routeGoBack = localStorage.getItem("routeGoBack");
        localStorage.setItem("routeAdminFrom", "/admin/selfassessed");
        localStorage.setItem("manageProfileByAdminPopup", "FALSE");

        let repopulate = true;
        if (routeGoBack == "TRUE") {
            repopulate = false;
        }

        this.props.onAuth.authCheckState().then(() => {
            if (repopulate || !this.props.persons || (this.props.persons && this.props.persons.length == 0)) {
                this.initData();
            } else {
                let persons = this.reformPersons(this.props.persons.filter((it) => !it.inactive));

                if (this.state.modeApprove) {
                    persons.forEach((it) => {
                        it.toggleSkillProfileSelected = !it.selfAssessedReportGenerated && it.selfAssessedStatus != "Approved" && it.selfAssessedStatus != "Postponed pending evidence" && it.selfAssessedStatus != "Postponed pending changes";
                    });
                }

                this.setState({
                    persons: persons.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1)),
                    personsSkillProfileSelected: persons.filter((it) => it.toggleSkillProfileSelected).map((row) => row.id),
                    loading: false,
                });
            }
        });
    }

    componentWillUnmount() {
        localStorage.setItem("routeGoBack", "FALSE");
    }

    componentDidUpdate(prevProps) {
        if (prevProps.persons !== this.props.persons && this.props.persons) {
            this.setState({ persons: this.reformPersons(this.props.persons.filter((it) => !it.inactive).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))) });
        }

        if (prevProps.mode !== this.props.mode && prevProps.mode) {
            this.handleRefresh();
        }
    }

    reformPersons = (persons) => {
        return persons.map((it) => {
            const account = it.accounts && it.accounts.length > 0 ? it.accounts.sort((a, b) => (b.createdOn > a.createdOn ? 1 : -1))[0] : null;

            return {
                id: it.id,
                idSkillProfile: it.id,
                companyId: it.companyId,
                firstName: it.firstName,
                lastName: it.lastName,
                email: it.email,
                completeName: `${it.firstName}${it.lastName ? ` ${it.lastName}` : ""}${it.email ? ` ${it.email}` : ""}`,
                salutation: it.salutation,
                position: it.position,
                inactive: it.inactive,
                personType: it.personType,
                accessGroup: it.accessGroup,
                allowNotifications: it.allowNotifications,
                lastActivityOn: it.lastActivityOn,
                lastActivitySystem: it.lastActivitySystem,
                createdOn: it.createdOn,
                countryCode: it.countryCode,
                country: it.country,
                location: it.location,
                selfAssessmentReason: it.selfAssessmentReason,
                encryptedId: it.encryptedId,
                team: it.team,
                usePreferredName: it.usePreferredName,
                other: it.other,
                department: it.department,
                businessUnit: it.businessUnit,
                division: it.division,
                territory: it.territory,
                costCentre: it.costCentre,
                supervisor: it.supervisor,
                validatedDate: it.validatedDate,
                isCandidate: it.isCandidate,
                isAnalyticsUser: it.isAnalyticsUser,
                leaveOn: it.leaveOn,
                isAssigned: it.isAssigned,
                isInvited: it.isInvited,
                invitedOn: it.invitedOn,
                accounts: it.accounts ? [...it.accounts] : null,
                teams: it.teams ? [...it.teams] : null,
                profilePictureUrl: it.profilePictureUrl,
                activityStatementInterval: it.activityStatementInterval,
                lastUpdatedOn: it.lastUpdatedOn,
                lastUpdatedVersion: it.lastUpdatedVersion,
                latestAssessmentId: it.latestAssessmentId,
                cartridgeId: it.cartridgeId,

                selfAssessedReportGenerated: it.selfAssessedReportGenerated,
                lastSelfAssessedReportGeneratedVersion: it.lastSelfAssessedReportGeneratedVersion,
                lastSelfAssessedReportGeneratedOn: it.lastSelfAssessedReportGeneratedOn,

                selfAssessedLastProfileVersion: it.selfAssessedLastProfileVersion,
                selfAssessedLastProfileUpdatedOn: it.selfAssessedLastProfileUpdatedOn,
                selfAssessedLastProfileApproved: it.selfAssessedLastProfileApproved,
                selfAssessedLastProfileUpdatedById: it.selfAssessedLastProfileUpdatedById ? it.selfAssessedLastProfileUpdatedById : it.id,
                selfAssessedLastProfileUpdatedBy: it.selfAssessedLastProfileUpdatedBy ? it.selfAssessedLastProfileUpdatedBy : `${it.firstName} ${it.lastName}`,

                selfAssessedLastApprovedById: it.selfAssessedLastProfileVersion ? it.selfAssessedLastApprovedById : null,
                selfAssessedLastApprovedBy: it.selfAssessedLastProfileVersion ? it.selfAssessedLastApprovedBy : null,
                selfAssessedLastApprovedOn: it.selfAssessedLastProfileVersion ? it.selfAssessedLastApprovedOn : null,
                selfAssessedLastApprovedVersion: it.selfAssessedLastProfileVersion ? it.selfAssessedLastApprovedVersion : null,

                selfAssessedStatus: it.selfAssessedLastProfileApproved
                    ? "Approved"
                    : account
                    ? account.isApprovalRequested || account.isApprovalPostponed
                        ? account.isApprovalPostponed
                            ? account.approvalPostponedType == "Missing Evidence"
                                ? "Postponed pending evidence"
                                : "Postponed pending changes"
                            : "Requested"
                        : "Not Requested"
                    : "Not Requested",
                selfAssessedLastProfileNumberOfChange: it.selfAssessedLastProfileNumberOfChange,
                isApprovalRequested: !it.selfAssessedLastProfileApproved && account ? account.isApprovalRequested : false,
                approvalRequestSentOn: account ? account.approvalRequestSentOn : null,

                isApprovalPostponed: !it.selfAssessedLastProfileApproved && account ? account.isApprovalPostponed : false,
                approvalPostponedOn: account && account.isApprovalPostponed ? account.approvalPostponedOn : null,
                approvalPostponedById: account && account.isApprovalPostponed ? account.approvalPostponedById : null,
                approvalPostponedBy: account && account.isApprovalPostponed ? account.approvalPostponedBy : null,
                approvalPostponedType: account && account.isApprovalPostponed ? account.approvalPostponedType : null,
                approvalPostponedComment: account && account.isApprovalPostponed ? account.approvalPostponedComment : null,
            };
        });
    };

    initData = () => {
        this.props.onAdminPerson.getPersonsIncSelfAssessed().then(() => {
            let persons = this.reformPersons(this.props.persons.filter((it) => !it.inactive));
            if (this.state.modeApprove) {
                persons.forEach((it) => {
                    it.toggleSkillProfileSelected = !it.selfAssessedReportGenerated && it.selfAssessedStatus != "Approved" && it.selfAssessedStatus != "Postponed pending evidence" && it.selfAssessedStatus != "Postponed pending changes";
                });
            }

            this.setState({
                persons: persons.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1)),
                personsSkillProfileSelected: persons.filter((it) => it.toggleSkillProfileSelected).map((row) => row.id),
            });
        });

        this.setState({ loading: this.props.loading });
    };

    handleRefresh = () => {
        this.initData();
    };

    handleRowClickDummy = () => {};

    handleRowSkillProfileClick = (row) => {
        if (!this.state.loadingPrint) {
            const persons = this.state.persons;
            const updatedData = persons.find((it) => it.id == row.id);
            updatedData.toggleSkillProfileSelected = !updatedData.toggleSkillProfileSelected;

            this.setState({ persons: [...persons], personsSkillProfileSelected: persons.filter((it) => it.toggleSkillProfileSelected).map((row) => row.id) });
        }
    };

    handleSelectSkillProfileAll = () => {
        const { persons, searchKeyword, searchProperties } = this.state;
        let selectAll = false;

        const filtered = this.filterArray(persons, searchKeyword, searchProperties);

        if (filtered.filter((it) => it.toggleSkillProfileSelected && it.selfAssessedStatus != "Approved" && it.selfAssessedStatus != "Postponed pending evidence" && it.selfAssessedStatus != "Postponed pending changes").length == 0) {
            selectAll = true;
        } else if (filtered.filter((it) => !it.toggleSkillProfileSelected && it.selfAssessedStatus != "Approved" && it.selfAssessedStatus != "Postponed pending evidence" && it.selfAssessedStatus != "Postponed pending changes").length == 0) {
            selectAll = false;
        } else {
            selectAll = true;
        }

        persons.forEach((it) => {
            it.toggleSkillProfileSelected = false;
        });

        if (selectAll) {
            persons
                .filter((it) => it.selfAssessedStatus != "Approved" && it.selfAssessedStatus != "Postponed pending evidence" && it.selfAssessedStatus != "Postponed pending changes")
                .forEach((it) => {
                    if (filtered.find((f) => f.id == it.id)) {
                        it.toggleSkillProfileSelected = true;
                    }
                });
        }

        this.setState({ persons: [...persons], personsSkillProfileSelected: persons.filter((it) => it.toggleSkillProfileSelected).map((row) => row.id) });
    };

    handleClearSelection = () => {
        const persons = this.state.persons;

        persons.forEach((it) => {
            it.toggleSkillProfileSelected = false;
        });

        this.setState({ persons: [...persons], personsSkillProfileSelected: [] });
    };

    handleApproveProfiles = () => {
        const persons = this.state.persons;

        persons.forEach((it) => {
            it.toggleSkillProfileSelected = false;
        });

        this.setState({
            comment: "",
            modeApprove: true,
            persons: [...persons],
            personsSkillProfileSelected: persons.filter((it) => it.toggleSkillProfileSelected).map((row) => row.id),
        });
    };

    handleConfirmApprove = () => {
        const { personsSkillProfileSelected, persons } = this.state;

        const approvedPersons = persons.filter((it) => personsSkillProfileSelected.includes(it.id));
        const approvalRequests = approvedPersons.map((approvedPerson) => {
            return {
                personId: approvedPerson.id,
                accountId: approvedPerson.accounts && approvedPerson.accounts.length > 0 ? approvedPerson.accounts.sort((a, b) => (b.createdOn > a.createdOn ? 1 : -1))[0].id : null,
                email: approvedPerson.email,
                approvedVersion: approvedPerson.selfAssessedLastProfileVersion,
                comment: this.state.comment,
                approvedBy: localStorage.getItem("firstName") + " " + localStorage.getItem("lastName"),
                approvedOn: new Date(),
            };
        });

        this.setState({ isUpdating: true });
        this.setState({ showLoadingPanel: true, loadingPanelHeader: "Approve skill profiles", loadingPanelBody: "Please wait ..." });
        this.props.onAdminPerson.approveProfileRequests({ requests: approvalRequests }).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.personsError) {
                    this.generateAlert("success", `Skill profiles approved`);
                    this.setState({ modeApprove: false });
                } else {
                    this.generateAlert("danger", this.props.personsError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating, showLoadingPanel: this.props.isUpdating, showApproveDialog: this.props.isUpdating });
        });
    };

    handleCancelApprove = () => {
        this.handleClearSelection();
        this.setState({ modeApprove: false });
    };

    pad2 = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    handleExportSelfAssessedProfiles = () => {
        const timezoneOffset = (new Date().getTimezoneOffset() * -1) / 60;

        this.props.onAdminPerson.exportLatestSelfAssessedProfilesDataExcel(timezoneOffset).then(() => {
            if (!this.props.loadingPrint && !this.props.personsError) {
                if (this.props.printData) {
                    var today = new Date();
                    var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                    FileDownload(this.props.printData, `ExportLatestSelfAssessedProfiles_${strToday}.xlsx`);
                }
            }
        });
    };

    onTableFilterChange = (e, searchType) => {
        let columnSearchKeywords = this.state.columnSearchKeywords;
        if (!columnSearchKeywords) {
            columnSearchKeywords = [];
        }

        if (columnSearchKeywords.find((it) => it.name == e.target.name)) {
            let columnSearchKeyword = columnSearchKeywords.find((it) => it.name == e.target.name);
            columnSearchKeyword.value = e.target.value;
        } else {
            columnSearchKeywords.push({
                name: e.target.name,
                value: e.target.value,
                searchType,
            });
        }

        this.setState({ columnSearchKeywords });
    };

    togleShowApprovalModal = () => {
        const { showApproveDialog } = this.state;
        this.setState({ showApproveDialog: !showApproveDialog });
    };

    handleCommentChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            comment: value,
        });
    };

    renderApprovalDialog = () => {
        return (
            <Modal isOpen={this.state.showApproveDialog} toggle={!this.state.isUpdating && this.togleShowApprovalModal}>
                <ModalHeader toggle={!this.state.isUpdating && this.togleShowApprovalModal}>Approve Profile(s)</ModalHeader>
                <ModalBody>
                    {this.state.approveInProgress ? (
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <Spinner size="lg" animation="border" role="status"></Spinner>
                        </div>
                    ) : (
                        <Form>
                            <FormGroup row>
                                <Label sm={12}>
                                    You are about to approve one or more profiles. Once approved they cannot be unapproved.
                                    <br />
                                    NOTE: The comment you enter below will be sent to ALL requesters.
                                </Label>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={12}>
                                    <Input type="textarea" name="comment" placeholder="Please enter comment (optional)" value={this.state.comment ? this.state.comment : ""} onChange={this.handleCommentChange} disabled={this.state.isUpdating} />
                                </Col>
                            </FormGroup>
                        </Form>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.handleConfirmApprove} disabled={this.state.isUpdating}>
                        Approve
                    </Button>
                    <Button color="secondary" onClick={this.togleShowApprovalModal} disabled={this.state.isUpdating}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    renderLoadingPanel() {
        if (this.state.showLoadingPanel) {
            return (
                <Modal isOpen={this.state.showLoadingPanel}>
                    <ModalHeader>{this.state.loadingPanelHeader}</ModalHeader>
                    <ModalBody>
                        <Spinner size="md" color="primary" /> {this.state.loadingPanelBody}
                    </ModalBody>
                </Modal>
            );
        } else {
            return "";
        }
    }

    openProfile = (person) => {
        localStorage.setItem("showApprovedProfileOnly", "FALSE");
        localStorage.setItem("profileType", "Self Assessed");
        localStorage.setItem("manageProfileByAdminPopup", "TRUE");
        this.props.history.push(`/SfiaProfile/Manage?email=${person.email}`);
    };

    render() {
        const { persons, searchKeyword, searchProperties, modeApprove, columnSearchKeywords } = this.state;
        const pageAssignments = this.props.pageAssignments;
        let pageAssignment = null;

        if (pageAssignments.find((it) => it.pageName == "Approve SelfAssessed")) {
            pageAssignment = pageAssignments.find((it) => it.pageName == "Approve SelfAssessed");
        }

        const handleSelectSkillProfileAll = this.handleSelectSkillProfileAll;
        const handleRowSkillProfileClick = this.handleRowSkillProfileClick;
        const openProfile = this.openProfile;

        const onTableFilterChange = this.onTableFilterChange;

        const loadingPrint = this.state.loadingPrint;

        const filtered = this.filterArray(persons, searchKeyword, searchProperties);

        const allowApprove = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Approve" && op.allow == true) ? true : false;
        const allowExport = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Export" && op.allow == true) ? true : false;

        const lastUpdatedSort = (rowA, rowB) => {
            let lastUpdatedA =
                (rowA.selfAssessedLastProfileUpdatedOn ? rowA.selfAssessedLastProfileUpdatedOn.toString().replaceAll("-", "").replaceAll(":", "").replace("T", "") + `V${rowA.endorsementLastUpdatedVersion}` : "") + (rowA.selfAssessedLastProfileUpdatedBy ? rowA.selfAssessedLastProfileUpdatedBy : "");
            let lastUpdatedB =
                (rowB.selfAssessedLastProfileUpdatedOn ? rowB.selfAssessedLastProfileUpdatedOn.toString().replaceAll("-", "").replaceAll(":", "").replace("T", "") + `V${rowB.endorsementLastUpdatedVersion}` : "") + (rowB.selfAssessedLastProfileUpdatedBy ? rowB.selfAssessedLastProfileUpdatedBy : "");

            if (lastUpdatedA > lastUpdatedB) {
                return 1;
            }

            if (lastUpdatedB > lastUpdatedA) {
                return -1;
            }

            return 0;
        };

        const lastApprovedSort = (rowA, rowB) => {
            let lastApprovedA = (rowA.selfAssessedLastApprovedOn ? rowA.selfAssessedLastApprovedOn.toString().replaceAll("-", "").replaceAll(":", "").replace("T", "") + `V${rowA.selfAssessedLastApprovedVersion}` : "") + (rowA.selfAssessedLastApprovedBy ? rowA.selfAssessedLastApprovedBy : "");
            let lastApprovedB = (rowB.selfAssessedLastApprovedOn ? rowB.selfAssessedLastApprovedOn.toString().replaceAll("-", "").replaceAll(":", "").replace("T", "") + `V${rowB.selfAssessedLastApprovedVersion}` : "") + (rowB.selfAssessedLastApprovedBy ? rowB.selfAssessedLastApprovedBy : "");

            if (lastApprovedA > lastApprovedB) {
                return 1;
            }

            if (lastApprovedB > lastApprovedA) {
                return -1;
            }

            return 0;
        };

        const postponedOnSort = (rowA, rowB) => {
            let lastApprovedA = (rowA.approvalPostponedOn ? rowA.approvalPostponedOn.toString().replaceAll("-", "").replaceAll(":", "").replace("T", "") : "") + (rowA.approvalPostponedBy ? rowA.approvalPostponedBy : "");
            let lastApprovedB = (rowB.approvalPostponedOn ? rowB.approvalPostponedOn.toString().replaceAll("-", "").replaceAll(":", "").replace("T", "") : "") + (rowB.approvalPostponedBy ? rowB.approvalPostponedBy : "");

            if (lastApprovedA > lastApprovedB) {
                return 1;
            }

            if (lastApprovedB > lastApprovedA) {
                return -1;
            }

            return 0;
        };

        const columns = [
            {
                id: "id",
                name: (
                    <div style={{ textAlign: "center" }}>
                        <span id="headerSkillProfiles" style={{ fontWeight: "bold" }}>
                            Select
                        </span>
                        <br />
                        <FontAwesomeIcon
                            id={`headerChkSelectAllSkillProfile`}
                            icon={
                                filtered.filter((it) => it.toggleSkillProfileSelected && it.selfAssessedStatus != "Approved" && it.selfAssessedStatus != "Postponed pending evidence" && it.selfAssessedStatus != "Postponed pending changes").length == 0
                                    ? faSquare
                                    : filtered.filter((it) => !it.toggleSkillProfileSelected && it.selfAssessedStatus != "Approved" && it.selfAssessedStatus != "Postponed pending evidence" && it.selfAssessedStatus != "Postponed pending changes").length == 0
                                    ? faSquareCheck
                                    : faSquareMinus
                            }
                            style={{
                                cursor: "pointer",
                                color: filtered.filter((it) => it.toggleSkillProfileSelected && it.selfAssessedStatus != "Approved" && it.selfAssessedStatus != "Postponed pending evidence" && it.selfAssessedStatus != "Postponed pending changes").length == 0 ? "#8f8f9d" : "#007bff",
                            }}
                            size="lg"
                            onClick={() => !loadingPrint && handleSelectSkillProfileAll()}
                        />
                    </div>
                ),
                selector: (row) => row.id,
                sortable: false,
                width: "6%",
                center: true,
                format: function (row, index) {
                    return (
                        <div>
                            {row.selfAssessedStatus != "Approved" && row.selfAssessedStatus != "Postponed pending evidence" && row.selfAssessedStatus != "Postponed pending changes" && (
                                <Input type="checkbox" id={`chkRowSkillProfile${row.id}`} checked={row.toggleSkillProfileSelected} disabled={loadingPrint} onClick={(e) => handleRowSkillProfileClick(row)} />
                            )}
                        </div>
                    );
                },
                omit: !modeApprove,
            },
            {
                id: "email",
                name: (
                    <div style={{ width: "90%" }}>
                        Person Name (email)
                        <br />
                        <input type="text" name="completeName" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "completeName") ? columnSearchKeywords.find((it) => it.name == "completeName").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.email,
                sortable: true,
                width: modeApprove ? "29%" : "35%",
                center: false,
                format: function (row, index) {
                    return (
                        <span>
                            {allowApprove ? (
                                <div style={{ color: "#007bff", cursor: "pointer" }} onClick={() => openProfile(row)}>
                                    {`${row.firstName} ${row.lastName} (${row.email})`}
                                </div>
                            ) : (
                                `${row.firstName} ${row.lastName} (${row.email})`
                            )}
                        </span>
                    );
                },
            },
            {
                id: "selfAssessedLastProfileUpdatedOn",
                name: (
                    <div style={{ width: "90%" }}>
                        Last Updated by and date
                        <br />
                        <input
                            type="text"
                            name="selfAssessedLastProfileUpdatedBy"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "selfAssessedLastProfileUpdatedBy") ? columnSearchKeywords.find((it) => it.name == "selfAssessedLastProfileUpdatedBy").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.selfAssessedLastProfileUpdatedOn,
                sortable: true,
                sortFunction: lastUpdatedSort,
                width: "13%",
                center: false,
                format: function (row, index) {
                    if (row.selfAssessedLastProfileUpdatedOn) {
                        return (
                            <span>
                                {row.selfAssessedLastProfileUpdatedBy && (
                                    <React.Fragment>
                                        {row.selfAssessedLastProfileUpdatedBy}
                                        <br />
                                    </React.Fragment>
                                )}
                                {`${new Intl.DateTimeFormat("en-GB", {
                                    year: "2-digit",
                                    month: "short",
                                    day: "numeric",
                                }).format(new Date(row.selfAssessedLastProfileUpdatedOn))} ${row.selfAssessedLastProfileVersion ? `(v${row.selfAssessedLastProfileVersion})` : ""}`}
                            </span>
                        );
                    } else {
                        return "";
                    }
                },
            },
            {
                id: "selfAssessedLastApprovedOn",
                name: (
                    <div style={{ width: "90%" }}>
                        Last approved by and date
                        <br />
                        <input
                            type="text"
                            name="selfAssessedLastApprovedBy"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "selfAssessedLastApprovedBy") ? columnSearchKeywords.find((it) => it.name == "selfAssessedLastApprovedBy").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.selfAssessedLastApprovedOn,
                sortable: true,
                sortFunction: lastApprovedSort,
                width: "13%",
                center: false,
                format: function (row, index) {
                    if (row.selfAssessedLastApprovedOn) {
                        return (
                            <span>
                                {row.selfAssessedLastApprovedBy && (
                                    <React.Fragment>
                                        {row.selfAssessedLastApprovedBy}
                                        <br />
                                    </React.Fragment>
                                )}
                                {`${new Intl.DateTimeFormat("en-GB", {
                                    year: "2-digit",
                                    month: "short",
                                    day: "numeric",
                                }).format(new Date(row.selfAssessedLastApprovedOn))} ${row.selfAssessedLastApprovedVersion ? `(v${row.selfAssessedLastApprovedVersion})` : ""}`}
                            </span>
                        );
                    } else {
                        return "";
                    }
                },
            },
            {
                id: "selfAssessedStatus",
                name: (
                    <div style={{ width: "90%" }}>
                        SelfAssessed Status
                        <br />
                        <input
                            type="text"
                            name="selfAssessedStatus"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "selfAssessedStatus") ? columnSearchKeywords.find((it) => it.name == "selfAssessedStatus").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.selfAssessedStatus,
                sortable: true,
                width: "13%",
                center: false,
                wrap: true,
                format: function (row, index) {
                    return (
                        <React.Fragment>
                            <span id={`spanStatus${row.id}`}>{row.selfAssessedStatus}</span>
                            {row.selfAssessedStatus == "Requested" && row.approvalRequestSentOn && (
                                <React.Fragment>
                                    <br />
                                    {new Intl.DateTimeFormat("en-GB", {
                                        year: "2-digit",
                                        month: "short",
                                        day: "numeric",
                                    }).format(new Date(row.approvalRequestSentOn))}
                                </React.Fragment>
                            )}
                            {row.isApprovalPostponed && (row.selfAssessedStatus == "Postponed pending evidence" || row.selfAssessedStatus == "Postponed pending changes") && (
                                <UncontrolledTooltip target={`spanStatus${row.id}`} placement="bottom">
                                    {`${row.approvalPostponedBy} has postponed your approval on ${
                                        row.approvalPostponedOn
                                            ? new Intl.DateTimeFormat("en-GB", {
                                                  year: "2-digit",
                                                  month: "short",
                                                  day: "numeric",
                                              }).format(new Date(row.approvalPostponedOn))
                                            : ""
                                    } requesting ${row.approvalPostponedType} with the following comment`}
                                    <br />
                                    {row.approvalPostponedComment}
                                </UncontrolledTooltip>
                            )}
                        </React.Fragment>
                    );
                },
            },
            {
                id: "selfAssessedLastProfileNumberOfChange",
                name: (
                    <div style={{ width: "90%" }}>
                        Number of Skill Changes
                        <br />
                        <input
                            type="text"
                            name="selfAssessedLastProfileNumberOfChange"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "selfAssessedLastProfileNumberOfChange") ? columnSearchKeywords.find((it) => it.name == "selfAssessedLastProfileNumberOfChange").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.selfAssessedLastProfileNumberOfChange,
                sortable: true,
                width: "13%",
                center: false,
                format: function (row, index) {
                    return row.selfAssessedStatus != "Approved" && row.selfAssessedLastProfileNumberOfChange;
                },
            },
            {
                id: "approvalPostponedOn",
                name: (
                    <div style={{ width: "90%" }}>
                        Postponed by and date
                        <br />
                        <input
                            type="text"
                            name="approvalPostponedBy"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "approvalPostponedBy") ? columnSearchKeywords.find((it) => it.name == "approvalPostponedBy").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.approvalPostponedOn,
                sortable: true,
                sortFunction: postponedOnSort,
                width: "13%",
                center: false,
                format: function (row, index) {
                    if (row.approvalPostponedOn) {
                        return (
                            <span>
                                {row.approvalPostponedBy && (
                                    <React.Fragment>
                                        {row.approvalPostponedBy}
                                        <br />
                                    </React.Fragment>
                                )}
                                {`${new Intl.DateTimeFormat("en-GB", {
                                    year: "2-digit",
                                    month: "short",
                                    day: "numeric",
                                }).format(new Date(row.approvalPostponedOn))}`}
                            </span>
                        );
                    } else {
                        return "";
                    }
                },
            },
        ];

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.renderLoadingPanel()}
                {this.renderApprovalDialog()}
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <div style={{ marginBottom: "10px" }}>
                            <Row>
                                <Col md="5" sm="6">
                                    <SkillsTxTableSearchBar
                                        searchKeyword={this.state.searchKeyword}
                                        onChange={(searchKeyword) => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword, personsSkillProfileSelected: [] });
                                        }}
                                    ></SkillsTxTableSearchBar>
                                </Col>
                                <Col md="7" sm="6">
                                    <SkillsTxTableClearSearch
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        color="primary"
                                        disabled={this.props.personsLoading || this.state.isUpdating}
                                        onClear={() => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword: "", personsSkillProfileSelected: [] });
                                        }}
                                    ></SkillsTxTableClearSearch>
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        onClick={this.handleRefresh}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        disabled={this.props.personsLoading || this.state.isUpdating}
                                    >
                                        <FontAwesomeIcon icon={faSync} /> Refresh
                                    </Button>
                                    {allowExport && (
                                        <React.Fragment>
                                            &nbsp;&nbsp;
                                            <Button
                                                color="primary"
                                                onClick={this.handleExportSelfAssessedProfiles}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.personsLoading || this.props.loadingPrint}
                                            >
                                                <FontAwesomeIcon icon={faFileExcel} /> Export
                                            </Button>
                                        </React.Fragment>
                                    )}
                                    {allowApprove && !modeApprove && (
                                        <React.Fragment>
                                            &nbsp;&nbsp;
                                            <Button
                                                color="primary"
                                                onClick={this.handleApproveProfiles}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.personsLoading || this.state.isUpdating || modeApprove || this.state.loadingPrint}
                                            >
                                                <FontAwesomeIcon icon={faCheck} /> Approve Profiles
                                            </Button>
                                        </React.Fragment>
                                    )}
                                    {modeApprove && (
                                        <React.Fragment>
                                            &nbsp;&nbsp;
                                            <Button
                                                id="btnOK"
                                                color="primary"
                                                onClick={this.togleShowApprovalModal}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.state.loadingPrint || this.state.personsSkillProfileSelected.length == 0}
                                            >
                                                {!this.state.loadingPrint ? "OK" : <Spinner type="grow" size="md" color="light" />}
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                id="btnCancel"
                                                color="primary"
                                                onClick={this.handleCancelApprove}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.state.loadingPrint}
                                            >
                                                Cancel
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </div>
                        <SkillsTxTable
                            columns={columns}
                            data={this.state.persons}
                            pagination={true}
                            searchKeyword={searchKeyword}
                            searchProperties={searchProperties}
                            columnSearchKeywords={columnSearchKeywords}
                            selectableRows
                            selectableRowsHighlight={true}
                            handleRowClick={this.handleRowClickDummy}
                            progressPending={this.props.personsLoading}
                            headCellsStyle={{
                                style: {
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    border: "1px solid #dee2e6",
                                    whiteSpace: "normal",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                    paddingRight: "8px",
                                    paddingLeft: "8px",
                                },
                            }}
                            cellsStyle={{
                                style: {
                                    padding: "5px 10px",
                                    fontSize: "11px",
                                    border: "1px solid #dee2e6",
                                },
                            }}
                        ></SkillsTxTable>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        personId: state.auth.personId,
        email: state.auth.email,
        loginWithTeams: state.auth.loginWithTeams,

        personsLoading: state.adminPerson.loading,
        personsError: state.adminPerson.error,
        persons: state.adminPerson.persons,
        personsAdmin: state.adminPerson.personsAdmin,
        isUpdating: state.adminPerson.isUpdating,

        loadingPrint: state.adminPerson.loadingPrint,
        printData: state.adminPerson.printData,

        pageAssignments: state.adminPerson.pageAssignments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onAdminPerson: bindActionCreators(adminPersonActions, dispatch),
        onPerson: bindActionCreators(personActions, dispatch),
        onSkillProfile: bindActionCreators(skillProfileActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageSelfAssessed));
